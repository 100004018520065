import { AsyncSelect, Box, Inline, Stack, Text } from "@sembark-travel/ui/base"
import { useEffect, useRef, useState } from "react"
import { TTravelActivity } from "./store"
import { AddActivityInDialog } from "./NewItem"
import {
	ICursorListResponse,
	ISimpleListResponse,
	XHRInstance,
	useXHR,
} from "@sembark-travel/xhr"
import { ButtonLink, Link, useLocationQuery } from "@sembark-travel/ui/router"
import {
	CursorListView,
	Search,
	TSearchParams,
	areAdvancedFiltersAppliedDefault,
	useSearch,
} from "@sembark-travel/ui/list"
import { TTripDestination } from "../TripDestinations"
import { SwitchInputField, isTruthy } from "@sembark-travel/ui/form"
import { generatePath } from "../router-utils"

function XHR(xhr: XHRInstance) {
	return {
		async get(params?: unknown) {
			return xhr
				.get<ISimpleListResponse<TTravelActivity>>("/travel-activities", {
					params,
				})
				.then((resp) => resp.data)
		},
		async getWithPagination(params?: Record<string, unknown>) {
			return xhr
				.get<ICursorListResponse<TTravelActivity>>("/travel-activities", {
					params: {
						...params,
						pagination: "cursor",
					},
				})
				.then((resp) => resp.data)
		},
	}
}

type TFilters = TSearchParams & {
	disabled_only?: 1 | 0
}

export function TravelActivitiesList() {
	const [query, setQuery] = useLocationQuery()
	const [params, setParams] = useSearch<TFilters>(query)
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	return (
		<Search
			initialParams={params}
			resetParams={{ q: "" }}
			onSearch={(params) => {
				setParams({ ...params, cursor: null })
			}}
			title="Travel Activities"
			Filters={Filters}
			areAdvancedFiltersApplied={(params) => {
				const { disabled_only, ...otherParams } = params
				return (
					isTruthy(disabled_only) ||
					areAdvancedFiltersAppliedDefault(otherParams)
				)
			}}
			actions={
				<Inline gap="4">
					<ButtonLink to={generatePath("/travel-activity-prices")}>
						Prices
					</ButtonLink>
					<ButtonLink
						to={generatePath("/travel-activities/new")}
						level="secondary"
					>
						Add New
					</ButtonLink>
				</Inline>
			}
		>
			<CursorListView<TTravelActivity, TFilters>
				pageKey="/travel-activities-list-view"
				params={params}
				fetch={(xhr, params) =>
					XHR(xhr).getWithPagination({
						...params,
						disabled_only: isTruthy(params.disabled_only) ? 1 : null,
						limit: 15,
						include: "thumbs",
					})
				}
				onCursorChange={(cursor) => setParams({ ...params, cursor })}
			>
				{({ items: travelActivity }) => (
					<Box as="ol">
						{travelActivity.map(
							({ id, name, ticket_types, thumb_image_url }) => (
								<Box
									as="li"
									key={id}
									padding="4"
									borderWidth="1"
									rounded="lg"
									marginBottom="4"
									bgColor="default"
								>
									<Inline gap="4" collapseBelow="sm">
										{thumb_image_url ? (
											<Box>
												<Box
													as="img"
													src={thumb_image_url}
													alt="Display"
													borderWidth="1"
													rounded="md"
													style={{ width: "100px", minWidth: "100px" }}
												/>
											</Box>
										) : null}
										<Stack gap="2">
											<Box>
												<Link
													fontSize="md"
													to={id.toString()}
													color="accent"
													anchored
													fontWeight="semibold"
												>
													<Box>{name}</Box>
												</Link>
											</Box>
											{ticket_types?.length ? (
												<Inline gap="2" flexWrap="wrap">
													{ticket_types?.map((s) => (
														<Box
															key={s.id}
															display="inlineBlock"
															paddingX="2"
															borderWidth="1"
															rounded="lg"
															bgColor="subtle"
														>
															{s.display_name}
															{s.short_name ? (
																<Box
																	as="span"
																	marginLeft="1"
																	color="muted"
																	fontSize="sm"
																>
																	[[{s.short_name}]]
																</Box>
															) : null}
														</Box>
													))}
												</Inline>
											) : null}
										</Stack>
									</Inline>
								</Box>
							)
						)}
					</Box>
				)}
			</CursorListView>
		</Search>
	)
}

function Filters() {
	return (
		<>
			<SwitchInputField name="disabled_only" label="Disabled Only" />
		</>
	)
}

export function SelectActivity({
	tripDestinations,
	...props
}: Omit<React.ComponentProps<typeof AsyncSelect>, "fetch"> & {
	tripDestinations?: Array<TTripDestination>
}) {
	const xhr = useXHR()
	const promiseOfCreation = useRef<
		((activity: TTravelActivity) => void) | undefined
	>(undefined)
	const [initialName, setInitialName] = useState<string>("")
	return (
		<>
			<AsyncSelect
				{...props}
				creatable
				perFetchLimit={15}
				fetch={async (q, { page }) => {
					return XHR(xhr)
						.get({
							limit: 15,
							page,
							q,
							trip_destinations: tripDestinations?.length
								? tripDestinations.map((t) => t.id)
								: null,
						})
						.then((resp) => resp.data)
				}}
				onCreateNew={(q) => {
					setInitialName(q)
					return new Promise((resolve) => {
						promiseOfCreation.current = resolve
					})
				}}
			/>
			<AddActivityInDialog
				open={Boolean(initialName)}
				onClose={() => setInitialName("")}
				initialValues={{
					name: initialName,
					trip_destinations: tripDestinations,
				}}
				onSuccess={(activity) => {
					promiseOfCreation.current?.(activity)
				}}
			/>
		</>
	)
}
