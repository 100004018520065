import {
	Box,
	Table,
	Inline,
	Dropdown,
	Icons,
	Money,
	Stack,
	Time,
	Text,
} from "@sembark-travel/ui/base"
import {
	NavLink,
	Link,
	ButtonLink,
	useLocationQuery,
} from "@sembark-travel/ui/router"
import { CursorListView, Search, useSearch } from "@sembark-travel/ui/list"
import {
	formatDate,
	localOrUtcTimestampToLocalDate,
} from "@sembark-travel/datetime-utils"
import { ICursorListResponse, XHRInstance } from "@sembark-travel/xhr"
import { Fragment, useEffect } from "react"
import { PERMISSIONS, useCheckPermissions } from "../Auth"
import { IHotelPrice } from "./store"
import { generatePath } from "../router-utils"

function XHR(xhr: XHRInstance) {
	return {
		async getPrices(
			params?: unknown
		): Promise<ICursorListResponse<IHotelPrice>> {
			return xhr.get(`/hotel-prices`, { params }).then((resp) => resp.data)
		},
	}
}

type PricesProps = {
	hotelId?: string | number
}

export default function Prices({ hotelId }: PricesProps) {
	const { hasPermission } = useCheckPermissions()
	const [query, setQuery] = useLocationQuery()
	const [params, setParams] = useSearch(query)
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	return (
		<Fragment>
			<Search
				initialParams={params}
				onSearch={(params) => {
					setParams({ ...params, cursor: null })
				}}
				title="Hotel Prices"
				actions={
					<Inline gap="2">
						{hasPermission(PERMISSIONS.UPLOAD_BULK_HOTELS) ? (
							<ButtonLink
								to={generatePath("/hotel-prices/upload-prices")}
								level="primary"
							>
								Upload Prices
							</ButtonLink>
						) : null}
						<Dropdown alignRight="sm">
							<Dropdown.ToggleButton level="tertiary">
								<Icons.DotsVertical />
							</Dropdown.ToggleButton>
							<Dropdown.Menu>
								<NavLink to={generatePath("/hotel-prices/calculate-price")}>
									Calculate Price
								</NavLink>
							</Dropdown.Menu>
						</Dropdown>
					</Inline>
				}
			/>
			<CursorListView<IHotelPrice>
				pageKey={`hotel-prices/${hotelId ? hotelId : ""}`}
				fetch={(xhr, params) =>
					XHR(xhr).getPrices({
						...params,
						...(hotelId ? { hotels: [hotelId] } : {}),
					})
				}
				onCursorChange={(cursor) => setParams({ ...params, cursor })}
				params={params}
			>
				{({ items: prices }) => (
					<Table
						responsive
						bordered
						striped
						headers={["Start Date", "End Date"]
							.concat(!hotelId ? ["Hotel"] : [])
							.concat([
								"Meal Plan",
								"Room Type",
								"Base Price",
								"Persons",
								"A.W.E.B.",
								"C.W.E.B.",
								"C.Wo.E.B",
							])}
						alignCols={{
							5: "right",
							6: "right",
							7: "right",
							8: "right",
							9: "right",
						}}
						rows={prices.map(
							({
								base_price,
								persons,
								start_date,
								start_date_local,
								end_date,
								end_date_local,
								hotel,
								adult_with_extra_bed_price,
								child_with_extra_bed_price,
								child_without_extra_bed_price,
								meal_plan,
								room_type,
								currency,
								created_at,
								created_by,
								is_booking_price,
							}) =>
								(
									[
										formatDate(
											localOrUtcTimestampToLocalDate(
												start_date_local,
												start_date
											),
											"DD MMM, YYYY"
										),
										formatDate(
											localOrUtcTimestampToLocalDate(end_date_local, end_date),
											"DD MMM, YYYY"
										),
									] as Array<React.ReactNode>
								)
									.concat(
										!hotelId
											? [
													<Stack>
														<Text fontWeight="semibold">
															<Link
																to={generatePath("/hotels/:hotelId", {
																	hotelId: hotel.id.toString(),
																})}
															>
																{hotel.name}
															</Link>
														</Text>
														<Box display="inline" fontSize="sm">
															({hotel.location.short_name}
															{hotel.stars_string
																? ` - ${hotel.stars_string}`
																: null}
															)
														</Box>
													</Stack>,
												]
											: []
									)
									.concat([
										meal_plan.name,
										<Stack style={{ maxWidth: "170px" }}>
											<Text>{room_type.display_name || room_type.name}</Text>
											{room_type.short_name ? (
												<Text fontSize="sm" color="muted">
													{room_type.short_name}
												</Text>
											) : null}
										</Stack>,
										<Stack>
											<Money currency={currency} amount={base_price} />
											<Box fontSize="sm" color="muted">
												{is_booking_price ? <>(Ops) </> : null}
												<Time timestamp={created_at} format="D MMM" />
											</Box>
										</Stack>,
										<Stack>
											<Text>{persons}</Text>
											{created_by ? (
												<Text fontSize="sm" color="muted">
													{created_by.name}
												</Text>
											) : null}
										</Stack>,
										<Stack>
											<Money
												currency={currency}
												amount={adult_with_extra_bed_price}
											/>
											<Text fontSize="sm" color="muted">
												{currency}
											</Text>
										</Stack>,
										<Money
											currency={currency}
											amount={child_with_extra_bed_price}
										/>,
										<Money
											currency={currency}
											amount={child_without_extra_bed_price}
										/>,
									])
						)}
					/>
				)}
			</CursorListView>
		</Fragment>
	)
}
