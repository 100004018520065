import { Helmet } from "react-helmet-async"
import { CabTypesList } from "../../../../CabTypes"

export default function OrganizationCabTypesPage() {
	return (
		<>
			<Helmet>
				<title>Cab Types | Org</title>
			</Helmet>
			<CabTypesList />
		</>
	)
}
