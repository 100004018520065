import { IListResponse, XHRInstance } from "@sembark-travel/xhr"
import { Required } from "utility-types"
import { IUser } from "../Auth"
import { isTruthy } from "@sembark-travel/ui/form"
import { isSame } from "@sembark-travel/datetime-utils"

export type TCabPriceCalculationMetric = {
	id: number
	name: string
	system_quantity_metric:
		| "kms"
		| "days"
		| "nights"
		| "extra_kms"
		| "total"
		| undefined
	min_quantity_per_day: number | undefined
	total_using_per_day_minimum: boolean | 0 | 1 | "0" | "1" | undefined
	created_at: string
	created_by?: IUser
	updated_at?: string
	updated_by: IUser
	deleted_at?: string
	deleted_by?: IUser
}

export type TCabPriceCalculationMetricPreset = {
	id: number
	name: string
	metrics: Array<TCabPriceCalculationMetric>
	created_at: string
	created_by?: IUser
	updated_at?: string
	updated_by: IUser
}

export const LABEL_FOR_CAB_PRICE_CALCULATION_SYSTEM_QUANTITY_METRIC: Record<
	Exclude<
		Required<TCabPriceCalculationMetric>["system_quantity_metric"],
		undefined | null
	>,
	string
> = {
	kms: "Kms",
	days: "Days",
	nights: "Nights",
	extra_kms: "Extra Kms",
	total: "Total",
}

export function cabPriceCalculationMetricsXHR(xhr: XHRInstance) {
	return {
		async get(params: unknown) {
			return xhr
				.get<
					IListResponse<TCabPriceCalculationMetric>
				>("/cab-price-calculation-metrics", { params })
				.then((resp) => resp.data)
		},
		async store(data: unknown) {
			return xhr
				.post<{
					data: TCabPriceCalculationMetric
				}>("/cab-price-calculation-metrics", data)
				.then((resp) => resp.data.data)
		},
		async show(id: number | string, params?: unknown) {
			return xhr
				.get<{
					data: TCabPriceCalculationMetric
				}>(`/cab-price-calculation-metrics/${id}`, { params })
				.then((resp) => resp.data)
		},
		async destroy(id: number | string) {
			return xhr
				.delete<{ message: string }>(`/cab-price-calculation-metrics/${id}`)
				.then((resp) => resp.data)
		},
		async restore(id: number | string) {
			return xhr
				.post<{
					data: TCabPriceCalculationMetric
				}>(`/cab-price-calculation-metrics/${id}/restore`)
				.then((resp) => resp.data.data)
		},
	}
}

export function cabPriceCalculationMetricPresetsXHR(xhr: XHRInstance) {
	return {
		async get(params: unknown) {
			return xhr
				.get<
					IListResponse<TCabPriceCalculationMetricPreset>
				>("/cab-price-calculation-metric-presets", { params })
				.then((resp) => resp.data)
		},
		async store(data: unknown) {
			return xhr
				.post<{
					data: TCabPriceCalculationMetricPreset
				}>("/cab-price-calculation-metric-presets", data)
				.then((resp) => resp.data.data)
		},
		async show(id: number | string, params?: unknown) {
			return xhr
				.get<{
					data: TCabPriceCalculationMetricPreset
				}>(`/cab-price-calculation-metric-presets/${id}`, { params })
				.then((resp) => resp.data)
		},
		async update(id: number | string, data: unknown) {
			return xhr
				.patch<{
					data: TCabPriceCalculationMetricPreset
				}>(`/cab-price-calculation-metric-presets/${id}`, data)
				.then((resp) => resp.data.data)
		},
	}
}

export type TSystemMetricQuantities = {
	total_kms: number | undefined
	datewise_total_kms:
		| Array<{ date?: Date | string | undefined; kms: number }>
		| undefined
	total_days: number
}

export function getSystemQuantityForMetric(
	metric: Pick<
		TCabPriceCalculationMetric,
		| "system_quantity_metric"
		| "min_quantity_per_day"
		| "total_using_per_day_minimum"
	>,
	system_metric_quantities: TSystemMetricQuantities
): number | undefined {
	const {
		system_quantity_metric,
		min_quantity_per_day,
		total_using_per_day_minimum,
	} = metric || {}
	const { total_kms, total_days, datewise_total_kms } =
		system_metric_quantities || {}
	if (!system_quantity_metric) return
	let quantity: number | undefined = undefined
	switch (system_quantity_metric) {
		case "kms":
			if (isTruthy(total_using_per_day_minimum)) {
				quantity = datewise_total_kms
					? datewise_total_kms
							.map(({ kms }) => Math.max(kms, min_quantity_per_day || 0))
							.reduce<number>((total, kms) => total + kms, 0)
					: undefined
			} else {
				quantity = total_kms
					? Math.max(
							Number(total_kms),
							Number(total_days || 0) * Number(min_quantity_per_day || 0)
						)
					: undefined
			}
			break
		case "extra_kms":
			if (isTruthy(total_using_per_day_minimum)) {
				quantity = datewise_total_kms
					? datewise_total_kms
							.map(({ kms, date }) =>
								!date
									? kms
									: Math.max(0, Number(kms) - Number(min_quantity_per_day || 0))
							)
							.reduce<number>((total, kms) => total + kms, 0)
					: undefined
			} else {
				quantity = total_kms
					? Math.max(
							0,
							Number(total_kms) -
								Number(total_days || 0) * Number(min_quantity_per_day || 0)
						)
					: undefined
			}
			break
		case "days":
			quantity = total_days
			break
		case "nights":
			quantity = Math.max(0, (total_days || 0) - 1)
			break
		case "total":
			quantity = 1
			break
	}
	return quantity
}

export function calculateSystemMetricQuantitiesFromServices(
	services: Array<{
		date?: Date | undefined
		transport_service: { distance?: number | undefined }
	}>
): TSystemMetricQuantities {
	const total_kms = services.reduce<number | undefined>(
		(kms, { transport_service }) =>
			transport_service.distance && kms !== undefined
				? kms + Number(transport_service.distance)
				: undefined,
		0
	)
	const datewise_total_kms = services.reduce<
		Array<{ date?: Date; kms: number }> | undefined
	>((datewise_total_kms, { date, transport_service }) => {
		if (!datewise_total_kms || !transport_service.distance) {
			return undefined
		}
		const distance = transport_service.distance
		const existing = datewise_total_kms.find((d) =>
			!date ? d.date === undefined : d.date && isSame(d.date, date, "day")
		)
		if (!existing) {
			return datewise_total_kms.concat([
				{
					date,
					kms: distance,
				},
			])
		}
		existing.kms += distance
		return datewise_total_kms
	}, [])
	const total_days = services.reduce<Array<Date>>(
		(days, { date }) =>
			!date
				? days
				: days.concat(days.find((d) => isSame(d, date, "day")) ? [] : [date]),
		[]
	).length
	return { total_kms, total_days, datewise_total_kms }
}
