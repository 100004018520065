import {
	formatDate,
	localOrUtcTimestampToLocalDate,
} from "@sembark-travel/datetime-utils"
import {
	Inline,
	Box,
	Table,
	Dropdown,
	Icons,
	Money,
	Stack,
	Time,
	Text,
} from "@sembark-travel/ui/base"
import {
	useLocationQuery,
	ButtonLink,
	NavLink,
} from "@sembark-travel/ui/router"
import { CursorListView, Search, useSearch } from "@sembark-travel/ui/list"
import { ICursorListResponse, XHRInstance } from "@sembark-travel/xhr"
import { Fragment, useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { PERMISSIONS, useCheckPermissions, useHasFeatureFlag } from "../Auth"
import { ITransportServicePrice } from "./store"
import { generatePath } from "../router-utils"

function XHR(xhr: XHRInstance) {
	return {
		async getTransportServicePrices(
			params?: unknown
		): Promise<ICursorListResponse<ITransportServicePrice>> {
			return xhr.get("/cab-prices", { params }).then((resp) => resp.data)
		},
	}
}

export default function List() {
	const { hasPermission } = useCheckPermissions()
	const [query, setQuery] = useLocationQuery()
	const [params, setParams] = useSearch(query)
	const canAddQuotePricesAtOnce = useHasFeatureFlag(
		"add_quote_cab_prices_at_once"
	)
	useEffect(() => {
		setQuery(params)
	}, [setQuery, params])
	return (
		<Fragment>
			<Helmet>
				<title>Transport Service Prices Listing</title>
			</Helmet>
			<Search
				initialParams={params}
				onSearch={(params) => {
					setParams({ ...params, cursor: null })
				}}
				title="Transport Service Prices"
				actions={
					<Inline gap="4">
						{hasPermission(PERMISSIONS.UPLOAD_BULK_TRANSPORT_SERVICES) ? (
							<ButtonLink
								to={generatePath("/transport-service-prices/upload-prices")}
								level="primary"
							>
								Upload Prices
							</ButtonLink>
						) : null}
						<Dropdown alignRight="sm">
							<Dropdown.ToggleButton level="tertiary">
								<Icons.DotsVertical />
							</Dropdown.ToggleButton>
							<Dropdown.Menu>
								<NavLink
									to={generatePath("/transport-service-prices/calculate-price")}
								>
									Calculate Price
								</NavLink>
								{canAddQuotePricesAtOnce ? (
									<NavLink to={generatePath("/cab-price-calculation-metrics")}>
										Calculator Metrics
									</NavLink>
								) : null}
								{hasPermission(PERMISSIONS.UPLOAD_BULK_TRANSPORT_SERVICES) &&
								canAddQuotePricesAtOnce ? (
									<NavLink to={generatePath("/transport-service-prices/new")}>
										Add Prices
									</NavLink>
								) : null}
							</Dropdown.Menu>
						</Dropdown>
					</Inline>
				}
			/>
			<CursorListView<ITransportServicePrice>
				pageKey="transport-service-prices"
				fetch={(xhr, params) => XHR(xhr).getTransportServicePrices(params)}
				params={params}
				onCursorChange={(cursor) => {
					setParams({ ...params, cursor })
				}}
			>
				{({ items: transportServicePrices }) => (
					<Table
						bordered
						responsive
						hover
						headers={[
							"Start Date",
							"End Date",
							"Cab Type",
							"Service",
							"Price",
							"Added On",
						]}
						alignCols={{
							4: "right",
						}}
						rows={transportServicePrices.map(
							({
								start_date,
								start_date_local,
								end_date,
								end_date_local,
								cab_type,
								transport_service,
								calculation_metric,
								price,
								currency,
								created_at,
								created_by,
								is_booking_price,
							}) => [
								formatDate(
									localOrUtcTimestampToLocalDate(start_date_local, start_date),
									"DD MMM, YYYY"
								),
								formatDate(
									localOrUtcTimestampToLocalDate(end_date_local, end_date),
									"DD MMM, YYYY"
								),
								<Stack>
									<Text>{cab_type.display_name}</Text>
									{cab_type.short_name ? (
										<Text fontSize="sm" color="muted">
											{cab_type.short_name}
										</Text>
									) : null}
								</Stack>,
								transport_service ? (
									<Stack>
										<Text>{transport_service.from_to}</Text>
										<Text fontSize="sm" color="muted">
											{transport_service.service}
										</Text>
									</Stack>
								) : calculation_metric ? (
									<Box>{calculation_metric.name}</Box>
								) : null,
								price ? (
									<Stack justifyContent="end">
										<Money amount={price} currency={currency} />
										<Text fontSize="sm" color="muted">
											{is_booking_price ? <>(Ops) </> : null}
											{currency}
										</Text>
									</Stack>
								) : null,
								<Stack>
									<Box>
										<Time timestamp={created_at} />
									</Box>
									{created_by ? (
										<Text fontSize="sm" color="muted">
											by {created_by.name}
										</Text>
									) : null}
								</Stack>,
							]
						)}
					/>
				)}
			</CursorListView>
		</Fragment>
	)
}
