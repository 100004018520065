import { Helmet } from "react-helmet-async"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../../Auth"
import { HotelPaymentPreferencesList } from "../../../../HotelPaymentPreferences"

export default function OrganizationHotelPaymentPreferencesPage() {
	return (
		<>
			<Helmet>
				<title>Hotel Payment Preferences | Org</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.VIEW_HOTEL_BOOKINGS}>
				<HotelPaymentPreferencesList />
			</ForbidUnlessAuthorized>
		</>
	)
}
