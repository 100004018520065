import {
	AsyncSelect,
	Button,
	Icons,
	Stack,
	Table,
	Text,
} from "@sembark-travel/ui/base"
import { CursorListView, Search, TSearchParams } from "@sembark-travel/ui/list"
import {
	ISimpleListResponse,
	ICursorListResponse,
	useXHR,
	XHRInstance,
} from "@sembark-travel/xhr"
import React, { Fragment } from "react"
import { Omit } from "utility-types"
import { TTripDestination } from "../TripDestinations"
import { IRoomType } from "./store"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { Link } from "@sembark-travel/ui/router"
import { generatePath } from "../router-utils"

function XHR(xhr: XHRInstance) {
	return {
		async getRoomTypes(
			params?: unknown
		): Promise<ISimpleListResponse<IRoomType>> {
			return xhr.get("/room-types", { params }).then((resp) => resp.data)
		},
		async destroy(roomTypeId: number | string) {
			return xhr
				.delete<{ message: string }>(`/room-types/${roomTypeId}`)
				.then((resp) => resp.data)
		},
		async getRoomTypesWithTotal(
			params?: Record<string, unknown>
		): Promise<ICursorListResponse<IRoomType>> {
			return xhr
				.get("/room-types", { params: { ...params, pagination: "cursor" } })
				.then((resp) => resp.data)
		},
	}
}

export function List<TParams extends TSearchParams = TSearchParams>({
	params = {} as TParams,
	setParams,
	actions,
}: {
	params?: TParams
	setParams: (params: TParams) => void
	actions?: React.ReactNode
}) {
	return (
		<Fragment>
			<Search
				initialParams={params}
				onSearch={(params) => {
					setParams({ ...params, cursor: null })
				}}
				title="Room Types"
				actions={actions}
			/>
			<CursorListView<IRoomType>
				pageKey="room-types"
				fetch={(xhr, params) =>
					XHR(xhr).getRoomTypesWithTotal({ ...params, include: "hotels_count" })
				}
				onCursorChange={(cursor) => setParams({ ...params, cursor })}
				params={params}
			>
				{({ items: roomTypes, refresh, xhr }) => (
					<Table
						striped
						bordered
						headers={["Name", "Description", "Hotels", ""]}
						alignCols={{ 2: "center", 3: "right" }}
						rows={roomTypes.map((roomType) => [
							<Stack>
								<Text>{roomType.display_name}</Text>
								{roomType.short_name ? (
									<Text color="muted" fontSize="sm" title="Short Name">
										{roomType.short_name}
									</Text>
								) : null}
							</Stack>,
							roomType.description,
							roomType.hotels_count ? (
								<Link
									to={generatePath("/hotels")}
									query={{
										room_types: [roomType.id + "_" + roomType.name],
									}}
								>
									{roomType.hotels_count}
								</Link>
							) : (
								0
							),
							<Button
								status="warning"
								size="sm"
								level="tertiary"
								onClick={() => {
									if (
										window.confirm(
											"Are you sure you want to delete this room type?\n\nThis will be deleted and removed from all hotels. Existing quotation and bookings will not be affected.\n\nThis action CAN NOT be reverted."
										)
									) {
										XHR(xhr)
											.destroy(roomType.id)
											.then((resp) => {
												showSnackbar(
													resp.message || "Room Type deleted successfully."
												)
												refresh()
											})
											.catch((e) => {
												const error: Error = e
												window.alert(error.message || "Something went wrong")
											})
									}
								}}
							>
								<Icons.Trash />
							</Button>,
						])}
					/>
				)}
			</CursorListView>
		</Fragment>
	)
}

export function SelectRoomTypes({
	tripDestinations,
	...props
}: Omit<React.ComponentProps<typeof AsyncSelect>, "fetch"> & {
	tripDestinations?: Array<TTripDestination>
}) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			multiple
			cacheKey={`room-types-${tripDestinations?.map((t) => t.id).join("-")}`}
			{...props}
			optionRenderer={({ option, created }) => {
				if (created) return <Text>Add "{option.name}"</Text>
				const roomType: IRoomType = option
				return (
					<Stack>
						<Text>{roomType.display_name || roomType.name}</Text>
						{roomType.short_name ? (
							<Text fontSize="sm" color="muted">
								{roomType.short_name}
							</Text>
						) : null}
					</Stack>
				)
			}}
			fetch={(q) =>
				XHR(xhr)
					.getRoomTypes({
						q,
						trip_destinations: tripDestinations?.length
							? tripDestinations.map((t) => t.id)
							: null,
					})

					.then((resp) => resp.data)
			}
		/>
	)
}
