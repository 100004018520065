import {
	Alert,
	Box,
	Button,
	Icons,
	Tooltip,
	Stack,
	Text,
	Inline,
	joinAttributes,
	Spinner,
	useBreakpoints,
	Dropdown,
	CheckboxInput,
	SelectInput,
	Select,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import {
	Search,
	areAdvancedFiltersAppliedDefault,
	useSearch,
	useSearchContext,
} from "@sembark-travel/ui/list"
import {
	utcTimestampToLocalDate,
	dateToQuery,
	subtractUnit,
	addUnit,
	startOf,
	formatDate,
	getUnit,
	getDiff,
	isSame,
	localOrUtcTimestampToLocalDate,
	dateToUTCString,
	endOf,
} from "@sembark-travel/datetime-utils"
import { useSwipe } from "@sembark-travel/ui/slider"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import { getColorForString } from "generate-colors"
import pluralize from "pluralize"
import { Fragment, useCallback, useEffect, useMemo, useState } from "react"
import useSWR from "swr"
import { generatePath } from "../router-utils"
import {
	ITransportServiceProvider,
	SelectTransporServiceProviders,
} from "../TransportServiceProviders"
import { collect } from "./../utils"
import { TripOperationalBookingsItemForDate } from "./ForDateItem"
import { TTripOperationalBooking } from "./store"
import { FeatureFlag, PERMISSIONS, useCheckPermissions } from "../Auth"
import {
	navigateBackQuery,
	Link,
	queryToSearch,
	useLocationQuery,
	ButtonLink,
} from "@sembark-travel/ui/router"
import {
	IOperationalBookingFiltersInLocationQuery,
	arrangeOperatinalBookingsInCalendar,
	IOperationalBookingFilters,
	useCalendarUIPreferences,
	TransitionWindowOptions,
	operationBookingLocationQueryToParams,
	operationalBookingFilterParamsToLocationQuery,
	BOOKING_STATUS,
	filtersToRequestParams,
} from "./utils"
import { Required } from "utility-types"
import Storage from "../storage"
import { useTripQuoteBookingsDiffCount } from "../TripQuoteBookingsDiff"
import {
	DatePickerField,
	GetFieldValue,
	SelectField,
	SwitchInputField,
} from "@sembark-travel/ui/form"
import { SelectTripDestination } from "../TripDestinations"
import { SelectTransportServiceLocationPoints } from "../TransportServices"
import { SelectCabTypes } from "../CabTypes"
import { SelectUsers } from "../Users"
import { SelectActivity } from "../TravelActivities"
import { TemporaryStorage } from "../TemporaryStorage"
import config from "../config"
import { SelectDriver } from "../Drivers"
import { getStartDateTimeOfCabSchedule } from "../CabScheduling"

function XHR(xhr: XHRInstance) {
	return {
		async getSchedules(
			params: Record<string, unknown>
		): Promise<Array<TTripOperationalBooking>> {
			return xhr
				.get("/trip-operational-bookings", {
					params: {
						...params,
						pagination: "all",
					},
				})
				.then((resp) => resp.data.data)
		},
	}
}

const CAB_SCHEDULE_HIDE_OLD_TRIPS_KEY = "cs_hot"
function storeHideOldTripsToStorage(value: boolean) {
	Storage.put(CAB_SCHEDULE_HIDE_OLD_TRIPS_KEY, Number(value))
}

function getHideOldTripsFromStorage(): boolean {
	return Boolean(Number(Storage.get(CAB_SCHEDULE_HIDE_OLD_TRIPS_KEY) || 0))
}

function getDaysForIntervalWindow(
	intervalWindow: number,
	base: Date = new Date(),
	alignDateToStart = false
) {
	const min = alignDateToStart
		? 0
		: intervalWindow % 2 === 0
			? intervalWindow / 2 - 1
			: Math.floor(intervalWindow / 2)
	const max = alignDateToStart ? intervalWindow : Math.floor(intervalWindow / 2)
	const interval: Date[] = []
	for (let i = min; i >= 0; i--) {
		interval.push(subtractUnit(base, i, "days"))
	}
	for (let i = 1; i <= max; i++) {
		interval.push(addUnit(base, i, "days"))
	}
	return interval.slice(0, intervalWindow)
}

type IOperationalBookingCalendarFilters = Required<
	IOperationalBookingFilters,
	"date"
>

export function OperationalBookingsCalendarWithFilters() {
	const [queryFilters, setQueryFilters] = useLocationQuery<
		IOperationalBookingCalendarFilters,
		IOperationalBookingFiltersInLocationQuery
	>({
		toQuery: operationalBookingFilterParamsToLocationQuery,
		fromQuery: operationBookingLocationQueryToParams as never,
	})
	const [params, setParams] = useSearch<IOperationalBookingCalendarFilters>({
		q: "",
		...queryFilters,
		date: queryFilters.date || new Date(),
		hide_past_trips: getHideOldTripsFromStorage(),
	})
	useEffect(() => {
		storeHideOldTripsToStorage(params.hide_past_trips || false)
		setQueryFilters(params)
	}, [params, setQueryFilters])

	const {
		calendarUIPreferences,
		toggleCompactFit,
		changeTransitionWindowSize,
		toggleOperationsTeamVisibility,
	} = useCalendarUIPreferences()

	const { hasPermission } = useCheckPermissions()
	const canViewOpsTeam = hasPermission(PERMISSIONS.MANAGE_TRIP_OWNERS)
	const tripQuoteBookingsDiffCount = useTripQuoteBookingsDiffCount({
		cabs_changed: true,
	})
	return (
		<Search<IOperationalBookingCalendarFilters>
			initialParams={params}
			title="Operational Bookings"
			onSearch={(params) => setParams(params)}
			Filters={OperationalBookingFilters}
			areAdvancedFiltersApplied={(filters) => {
				const { date, hide_past_trips, ...otherFilters } = filters
				return areAdvancedFiltersAppliedDefault(otherFilters)
			}}
			resetParams={(params) => ({
				q: "",
				date: params.date,
			})}
			actions={({ setSearchParamValue, searchParams: params }) => (
				<Box>
					<Inline gap="1">
						<NavigationButton
							days={-calendarUIPreferences.transitionWindowSize}
							onClick={(days) => {
								setSearchParamValue("date", addUnit(params.date, days, "day"))
							}}
						/>
						<NavigationButton
							days={calendarUIPreferences.transitionWindowSize}
							onClick={(days) => {
								setSearchParamValue("date", addUnit(params.date, days, "day"))
							}}
						/>
						<ButtonLink
							to={generatePath("/operational-bookings")}
							level="primary"
						>
							<Icons.ViewList /> List
						</ButtonLink>
						{tripQuoteBookingsDiffCount ? (
							<ButtonLink
								to={generatePath("/trip-quote-bookings-diff")}
								status="warning"
								title="Trip Quote & Cab Bookings Diff"
							>
								<Inline alignItems="center" as="span" gap="1">
									<Box as="span">
										<Icons.Attention />
									</Box>
									<Text as="span">{tripQuoteBookingsDiffCount}</Text>
								</Inline>
							</ButtonLink>
						) : null}
						<Dropdown alignRight="sm" display="inlineBlock">
							<Dropdown.ToggleButton>
								<Icons.DotsVertical />
							</Dropdown.ToggleButton>
							<Dropdown.Menu>
								<TemporaryStorage
									initUrl={"/trip-operational-bookings/download"}
									initParams={{
										...filtersToRequestParams(params),
										start_date: dateToUTCString(startOf(params.date, "month")),
										end_date: dateToUTCString(endOf(params.date, "month")),
										start_date_local: formatDate(
											startOf(params.date, "month"),
											"YYYY-MM-DD"
										),
										end_date_local: formatDate(
											endOf(params.date, "month"),
											"YYYY-MM-DD"
										),
										timezone_offset: config.timezoneOffset,
									}}
								>
									{({ generate, url, message, reset }) => (
										<Dropdown.MenuItem
											onClick={!url ? generate : () => reset()}
											href={url || "#"}
											target={url ? "_blank" : undefined}
										>
											{message || (
												<>
													<Icons.DocumentDownload /> Monthly Download (
													{formatDate(params.date, "MMM'YY")})
												</>
											)}
										</Dropdown.MenuItem>
									)}
								</TemporaryStorage>
								<TemporaryStorage
									initUrl={"/trip-operational-bookings/download"}
									initParams={{
										...filtersToRequestParams(params),
										start_date: null,
										end_date: null,
										start_date_local: null,
										end_date_local: null,
										trips_start_date_after: dateToUTCString(
											startOf(params.date, "month")
										),
										trips_start_date_after_local: formatDate(
											startOf(params.date, "month"),
											"YYYY-MM-DD"
										),
										trips_start_date_before: dateToUTCString(
											endOf(params.date, "month")
										),
										trips_start_date_before_local: formatDate(
											endOf(params.date, "month"),
											"YYYY-MM-DD"
										),
										timezone_offset: config.timezoneOffset,
										overview: 1,
									}}
								>
									{({ generate, url, message, reset }) => (
										<Dropdown.MenuItem
											onClick={!url ? generate : () => reset()}
											href={url || "#"}
											target={url ? "_blank" : undefined}
										>
											{message || (
												<>
													<Icons.DocumentDownload /> Monthly Overview Download (
													{formatDate(params.date, "MMM'YY")})
												</>
											)}
										</Dropdown.MenuItem>
									)}
								</TemporaryStorage>
								<Dropdown.MenuItemDivider />
								<Dropdown.MenuSectionHeader>
									<Icons.Cog /> UI Preferences
								</Dropdown.MenuSectionHeader>
								<li>
									<Box
										as="label"
										cursor="pointer"
										paddingX="3"
										paddingY="2"
										display="block"
										fontWeight="normal"
									>
										<Box display="flex" alignItems="center">
											<CheckboxInput
												id="toggle_compact_fit"
												checked={calendarUIPreferences.hasCompactFit}
												onChange={() => {
													toggleCompactFit()
												}}
											/>
											<Box paddingLeft="2">
												<Box>Use Compact Fit</Box>
												<Box fontSize="sm" color="muted">
													Move items upwards when space is available.
												</Box>
											</Box>
										</Box>
									</Box>
								</li>
								{canViewOpsTeam ? (
									<li>
										<Box
											as="label"
											cursor="pointer"
											paddingX="3"
											paddingY="2"
											display="block"
											fontWeight="normal"
										>
											<Box display="flex" alignItems="center">
												<CheckboxInput
													id="toggle_operations_team_visibility"
													checked={calendarUIPreferences.showOperationsTeam}
													onChange={() => {
														toggleOperationsTeamVisibility()
													}}
												/>
												<Box paddingLeft="2">
													<Box>Show/Hide Operations Team</Box>
												</Box>
											</Box>
										</Box>
									</li>
								) : null}
								<li>
									<Box
										as="label"
										cursor="pointer"
										paddingX="3"
										paddingY="2"
										display="block"
										fontWeight="normal"
									>
										<Box display="flex" alignItems="center">
											<SelectInput
												id="change_transition_window_size"
												value={calendarUIPreferences.transitionWindowSize}
												onChange={(e) => {
													changeTransitionWindowSize(e.currentTarget.value)
												}}
											>
												{TransitionWindowOptions.map((option) => (
													<option key={option} value={option}>
														{option} {pluralize("day", option)}
													</option>
												))}
											</SelectInput>
											<Box paddingLeft="2">
												<Box>Transition Window Size</Box>
												<Box fontSize="sm" color="muted">
													Days to skip on next/previous
												</Box>
											</Box>
										</Box>
									</Box>
								</li>
							</Dropdown.Menu>
						</Dropdown>
					</Inline>
				</Box>
			)}
		>
			{({ setSearchParams }) => (
				<OperationalBookingsCalendar
					params={params}
					onChangeParams={setSearchParams}
					date={params.date}
					{...calendarUIPreferences}
				/>
			)}
		</Search>
	)
}

function OperationalBookingsCalendar({
	params,
	onChangeParams,
	date,
	hasCompactFit,
	alignDateToStart,
	transitionWindowSize,
	showOperationsTeam,
}: {
	params: IOperationalBookingCalendarFilters
	onChangeParams: (params: IOperationalBookingCalendarFilters) => void
	date: Date
	hasCompactFit: boolean
	alignDateToStart?: boolean
	transitionWindowSize: number
	showOperationsTeam: boolean
}) {
	const xhr = useXHR()
	const searchContext = useSearchContext()
	const { xs, sm, md, lg } = useBreakpoints()
	let additionalInterval = 2
	if (searchContext) {
		if (!searchContext.areFilterVisible && !xs && !sm) {
			additionalInterval += 3
		}
	}
	const intervalWindow =
		(xs ? 2 : sm ? 4 : md ? 4 : lg ? 6 : 7) + additionalInterval
	const interval = useMemo(() => {
		return getDaysForIntervalWindow(intervalWindow, date, alignDateToStart)
	}, [date, intervalWindow, alignDateToStart])
	const [selectedTripAndDate, setSelectedTripAndDate] = useState<
		| { tripId: number; tripStartDateUtc: string; day: number; date: Date }
		| undefined
	>(undefined)
	const filters = useMemo(() => {
		return filtersToRequestParams({
			...params,
			start_date: interval[0],
			end_date: interval[interval.length - 1],
		})
	}, [params, interval])

	const { data, mutate: revalidate } = useSWR(
		`cab_schedules_${JSON.stringify(filters)}`,
		() => XHR(xhr).getSchedules(filters),
		{
			refreshInterval: 60000,
		}
	)
	const isFetching = !data
	// cache the fetched data
	const [operationalBookings, setOperationalBookings] = useState<
		Array<TTripOperationalBooking>
	>([])
	useEffect(() => {
		if (data) {
			setOperationalBookings(data)
		}
	}, [data])
	// these are the schedules, grouped for a day for each trip
	// we use it to show the schedules so that schedules for a
	// trip are in a single row
	const schedulesPerTripPerSlot = useMemo(() => {
		return arrangeOperatinalBookingsInCalendar(
			operationalBookings,
			interval,
			hasCompactFit
		)
	}, [operationalBookings, interval, hasCompactFit])
	// these are the schedules, grouped by each day
	// used to show the overview of services for each day
	const operationalBookingsPerDaySlot = useMemo(() => {
		const data: Array<{
			date: Date
			bookings: Array<TTripOperationalBooking>
		}> = []
		interval.forEach((slot, index) => {
			data.push({
				date: slot,
				bookings: schedulesPerTripPerSlot
					.map((a) => a[index].booking)
					.filter((a): a is Exclude<typeof a, undefined> => Boolean(a)),
			})
		})
		return data
	}, [schedulesPerTripPerSlot, interval])
	const now = useMemo(() => new Date(), [])
	const prevWindow = useCallback(() => {
		onChangeParams({
			...params,
			date: subtractUnit(params.date, transitionWindowSize, "days"),
		})
	}, [transitionWindowSize, params, onChangeParams])
	const nextWindow = useCallback(() => {
		onChangeParams({
			...params,
			date: addUnit(params.date, transitionWindowSize, "days"),
		})
	}, [transitionWindowSize, params, onChangeParams])
	const handleSwipe = useCallback(
		(direction: number) => {
			if (direction === -1) {
				// prev
				prevWindow()
			} else {
				// next
				nextWindow()
			}
		},
		[prevWindow, nextWindow]
	)
	const [setSwipeRef] = useSwipe(handleSwipe)
	const { hasPermission } = useCheckPermissions()
	const showOpsTeam =
		showOperationsTeam && hasPermission(PERMISSIONS.MANAGE_TRIP_OWNERS)
	return (
		<Box paddingBottom="4" position="relative" className="calendar">
			{isFetching ? (
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					position="absolute"
					inset="0"
					pointerEvents="none"
					zIndex="50"
				>
					<Spinner />
				</Box>
			) : null}
			<Box ref={setSwipeRef}>
				<Box
					as="table"
					width="full"
					maxWidth="full"
					fontSize="sm"
					style={{
						tableLayout: "fixed",
					}}
					position="relative"
				>
					<thead>
						<Box as="tr" textAlign="center">
							{interval.map((day) => {
								const isToday = isSame(now, day, "day")
								const isSelected = isSame(params.date, day, "day")
								return (
									<Box
										as="td"
										key={day.toISOString()}
										borderWidth="1"
										borderColor="transparent"
										position="sticky"
										top="0"
										bgColor="inset"
									>
										<Box
											as={Link}
											display="block"
											width="full"
											paddingY="2"
											boxShadow={{ focus: "outline" }}
											bgColor={{ hover: "subtle" }}
											title={`Click to view bookings for ${formatDate(
												day,
												"ddd D MMM, YYYY"
											)}`}
											to={
												generatePath("/operational-bookings/date/:date", {
													date: dateToQuery(day),
												}) + queryToSearch(navigateBackQuery(window.location))
											}
											color={{
												default: getUnit(day, "day") === 0 ? "accent" : "muted",
												hover: getUnit(day, "day") === 0 ? "accent" : "default",
											}}
										>
											<Box textAlign="center">
												<Box display="inlineBlock" fontWeight="semibold">
													{formatDate(day, "ddd")}
												</Box>
											</Box>
											<Box fontSize="xl" fontWeight="semibold">
												<Box
													display="inlineFlex"
													width="10"
													height="10"
													alignItems="center"
													justifyContent="center"
													rounded="full"
													backgroundColor={
														isToday ? "primary_emphasis" : undefined
													}
													color={isToday ? "white" : undefined}
													title={isToday ? "Today" : ""}
												>
													{getUnit(day, "date")}
												</Box>
											</Box>
											<Box fontSize="xs">{formatDate(day, "MMM, YYYY")}</Box>
										</Box>
										{isSelected ? (
											<Box
												height="px"
												bgColor="emphasis"
												position="absolute"
												bottom="0"
												left="0"
												right="0"
											/>
										) : null}
									</Box>
								)
							})}
						</Box>
						{operationalBookings.length ? (
							<Box as="tr" textAlign="center">
								{operationalBookingsPerDaySlot.map(({ date, bookings }) => {
									const schedules = bookings.flatMap((s) =>
										s ? s.cab_schedules || [] : []
									)
									const activityBookings = bookings.flatMap((s) =>
										s ? s.travel_activity_bookings || [] : []
									)
									const totalCabsCount = schedules.reduce<number>(
										(n, s) => n + s.cabs.length,
										0
									)
									const totalActivityBookingsCount = activityBookings.length
									const bookedCabsCount = schedules.reduce<number>(
										(n, s) => n + s.booked_cabs_count,
										0
									)
									const bookedActivityBookingsCount =
										activityBookings.reduce<number>(
											(count, n) => count + (n.is_booked ? 1 : 0),
											0
										)
									const totalBookingsCount =
										totalCabsCount + totalActivityBookingsCount
									const totalBookedCount =
										bookedCabsCount + bookedActivityBookingsCount
									const notBookedBookingsCount =
										totalBookingsCount - totalBookedCount
									const diffInDayFromThisDay = schedules.length
										? getDiff(
												utcTimestampToLocalDate(schedules[0].start_date),
												startOf(new Date(), "day"),
												"days"
											)
										: 0
									const alert =
										notBookedBookingsCount &&
										diffInDayFromThisDay >= 0 &&
										diffInDayFromThisDay < 2
									const allBooked =
										totalBookingsCount > 0 && notBookedBookingsCount === 0
									return (
										<Box
											as="td"
											key={date.toISOString()}
											paddingY="2"
											fontSize="lg"
											borderWidth="1"
											textAlign="center"
											backgroundColor={
												allBooked ? "success" : alert ? "danger" : "default"
											}
										>
											<Box
												display="flex"
												justifyContent="center"
												flexWrap="wrap"
											>
												{totalBookingsCount ? (
													<Fragment>
														<Box
															fontWeight="semibold"
															color="success"
															title={`${pluralize(
																"Booking",
																totalBookedCount,
																true
															)} Booked`}
														>
															{totalBookedCount}
														</Box>
														{!allBooked ? (
															<Fragment>
																<Box color="muted" paddingX="1">
																	+
																</Box>
																<Box
																	fontWeight="semibold"
																	color={alert ? "danger" : "warning"}
																	title={`${pluralize(
																		"Booking",
																		notBookedBookingsCount,
																		true
																	)} Not Booked`}
																>
																	{notBookedBookingsCount}
																</Box>
																<Box color="muted" paddingX="1">
																	=
																</Box>
																<Box fontWeight="semibold">
																	{totalBookingsCount}
																</Box>
															</Fragment>
														) : null}
													</Fragment>
												) : null}
											</Box>
										</Box>
									)
								})}
							</Box>
						) : null}
					</thead>
					<tbody>
						{isFetching && operationalBookings.length === 0 ? (
							<tr>
								<Box
									as="td"
									colSpan={interval.length}
									bgColor="default"
									borderWidth="1"
									paddingX="4"
									paddingY="12"
									color="muted"
									fontSize="lg"
									textAlign="center"
								/>
							</tr>
						) : operationalBookings.length === 0 ? (
							<tr>
								<Box
									as="td"
									colSpan={interval.length}
									bgColor="default"
									borderWidth="1"
									padding="4"
									color="muted"
									fontSize="lg"
									textAlign="center"
								>
									No operatable services for this interval
								</Box>
							</tr>
						) : (
							<>
								{schedulesPerTripPerSlot.map((schedulesForRow, i) => (
									<tr key={i}>
										{schedulesForRow.map(({ date, booking }, index, arr) => {
											const startOfBlock =
												index > 0 &&
												booking &&
												arr[index - 1]?.booking?.id !== booking.id
											const endOfBlock =
												index < arr.length - 1 &&
												booking &&
												arr[index + 1]?.booking?.id !== booking.id
											const background = booking
												? `rgba(${getColorForString(
														`${booking.id}${booking.reference_id}${booking.start_date}`,
														{
															contrast: 90,
														}
													).join(",")}, .2)`
												: "transparent"
											return (
												<Box
													as="td"
													paddingY="1"
													borderLeftWidth="1"
													borderRightWidth="1"
													bgColor="default"
													verticalAlign="top"
													paddingLeft={startOfBlock ? "1" : "0"}
													paddingRight={endOfBlock ? "1" : "0"}
													key={date.toISOString()}
													position="relative"
												>
													{!booking ? null : (
														<Box
															as="button"
															type="button"
															data-testid="view-details"
															onClick={() =>
																setSelectedTripAndDate({
																	tripId: booking.id,
																	date,
																	day:
																		getDiff(
																			date,
																			localOrUtcTimestampToLocalDate(
																				booking.start_date_local,
																				booking.start_date
																			),
																			"days"
																		) + 1,
																	tripStartDateUtc: booking.start_date,
																})
															}
															boxShadow={{ focus: "outline" }}
															display="block"
															textAlign="left"
															width="full"
															style={{
																background: background,
															}}
														>
															{booking.cab_schedules?.length ||
															booking.travel_activity_bookings?.length ? (
																xs || sm ? (
																	<BookingInfoCell
																		schedule={booking}
																		showOpsTeam={showOpsTeam}
																	/>
																) : (
																	<Tooltip
																		content={
																			<BookingSummary booking={booking} />
																		}
																	>
																		<Box>
																			<BookingInfoCell
																				schedule={booking}
																				showOpsTeam={showOpsTeam}
																			/>
																		</Box>
																	</Tooltip>
																)
															) : (
																<Box padding={"1"}>
																	<Text
																		color="muted"
																		fontSize="xs"
																		textAlign="center"
																	>
																		No Bookings
																	</Text>
																</Box>
															)}
														</Box>
													)}
												</Box>
											)
										})}
									</tr>
								))}
							</>
						)}
					</tbody>
				</Box>
			</Box>
			{params.hide_past_trips ? (
				<Box marginTop="8">
					<Alert title="Schedules Hidden" status="warning">
						Schedules from old trips (i.e. trips completed before{" "}
						{formatDate(new Date(), "Do MMM, YYYY")}) are hidden from the view.
						<Box marginTop="2">
							<Button
								onClick={() => {
									onChangeParams({ ...params, hide_past_trips: false })
								}}
							>
								Show All
							</Button>
						</Box>
					</Alert>
				</Box>
			) : null}
			{selectedTripAndDate ? (
				<Dialog
					open={Boolean(selectedTripAndDate)}
					onClose={() => setSelectedTripAndDate(undefined)}
					lg
					title="Schedule Details"
				>
					<Fragment>
						<Dialog.Body>
							<TripOperationalBookingsItemForDate
								tripId={selectedTripAndDate.tripId}
								date={selectedTripAndDate.date}
								day={selectedTripAndDate.day}
								tripStartDateUtc={selectedTripAndDate.tripStartDateUtc}
								showQuoteBookingDiffWarning
								onRefresh={() => {
									revalidate()
								}}
							/>
						</Dialog.Body>
					</Fragment>
				</Dialog>
			) : null}
		</Box>
	)
}

function BookingInfoCell({
	schedule: booking,
	showOpsTeam,
}: {
	schedule: TTripOperationalBooking
	showOpsTeam?: boolean
}) {
	const cabSchedule = booking.cab_schedules?.[0]
	const travelActivityBooking = booking.travel_activity_bookings?.[0]
	const totalServices =
		Number(booking.cab_schedules?.length) +
		Number(booking.travel_activity_bookings?.length)
	if (cabSchedule) {
		return (
			<CabScheduleInfoCell
				schedule={cabSchedule}
				opsTeam={!showOpsTeam ? undefined : booking.operations_team}
				contact={booking.contact}
				totalServices={totalServices}
			/>
		)
	}
	if (travelActivityBooking) {
		return (
			<TravelActivityBookingInfoCell
				schedule={travelActivityBooking}
				opsTeam={!showOpsTeam ? undefined : booking.operations_team}
				contact={booking.contact}
				totalServices={totalServices}
			/>
		)
	}
	return null
}

function CabScheduleInfoCell({
	schedule,
	totalServices,
	opsTeam,
	contact,
}: {
	schedule: Required<
		TTripOperationalBooking,
		"cab_schedules"
	>["cab_schedules"][number]
	totalServices: number
	contact: TTripOperationalBooking["contact"]
	opsTeam: TTripOperationalBooking["operations_team"]
}) {
	const { cabs } = schedule
	const serviceProviders: Array<ITransportServiceProvider> = useMemo(() => {
		if (!cabs?.length) return []
		const serviceProviders = cabs
			.map((cab) => cab.transport_service_provider)
			.filter((tsp): tsp is ITransportServiceProvider => Boolean(tsp))
		// get the unique
		return collect(serviceProviders)
			.unique((item) => item.id)
			.toArray()
	}, [cabs])
	return (
		<Stack padding="2" gap="1">
			<Box fontWeight="semibold" textOverflow="truncate">
				<Box
					style={{
						borderRadius: "50%",
					}}
					display="inlineBlock"
					size="2"
					borderWidth="1"
					bgColor={
						schedule.cabs_booked ? "success_emphasis" : "warning_emphasis"
					}
					borderColor={
						schedule.cabs_booked ? "success_emphasis" : "warning_emphasis"
					}
					marginRight="1"
				/>
				{schedule.transport_service.from_to_short}
			</Box>
			{contact ? (
				<Box textOverflow="truncate" fontSize="sm">
					{contact.name}
				</Box>
			) : null}
			{serviceProviders.length ? (
				<Box textOverflow="truncate" fontWeight="semibold">
					{serviceProviders.map((tsp) => tsp.name).join(", ")}
				</Box>
			) : null}
			<Box fontSize="xs" textOverflow="truncate">
				{collect(schedule.cabs)
					.unique((cab) => cab.cab_type.display_name)
					.count() === 1
					? `${schedule.cabs.length} ${schedule.cabs[0].cab_type.display_name}`
					: pluralize("Cab", schedule.cabs.length, true)}
			</Box>
			{opsTeam ? (
				<Inline gap="1" alignItems="center">
					<Box>
						<Icons.User size="3" />
					</Box>
					{opsTeam ? (
						<Text fontSize="xs" textOverflow="truncate">
							{opsTeam
								.map((t, _, arr) =>
									arr.length > 1 ? t.name.slice(0, 4) : t.name
								)
								.join(", ")}
						</Text>
					) : (
						<Text fontSize="xs">N/A</Text>
					)}
				</Inline>
			) : null}
			{totalServices > 1 ? (
				<Text fontWeight="semibold" fontSize="sm">
					+{pluralize("Service", totalServices - 1, true)}
				</Text>
			) : null}
		</Stack>
	)
}

function TravelActivityBookingInfoCell({
	schedule,
	totalServices,
	opsTeam,
	contact,
}: {
	schedule: Required<
		TTripOperationalBooking,
		"travel_activity_bookings"
	>["travel_activity_bookings"][number]
	totalServices: number
	contact: TTripOperationalBooking["contact"]
	opsTeam: TTripOperationalBooking["operations_team"]
}) {
	const {
		activity,
		ticket_type,
		ticket_tourist_configurations_short_name,
		supplier,
		is_booked,
	} = schedule
	return (
		<Stack padding="2" gap="1">
			<Box fontWeight="semibold" textOverflow="truncate">
				<Box
					style={{
						borderRadius: "50%",
					}}
					display="inlineBlock"
					size="2"
					borderWidth="1"
					bgColor={is_booked ? "success_emphasis" : "warning_emphasis"}
					borderColor={is_booked ? "success_emphasis" : "warning_emphasis"}
					marginRight="1"
				/>
				{activity.name}
				{ticket_type ? ` - ${ticket_type.name}` : ``}
			</Box>
			{contact ? (
				<Box textOverflow="truncate" fontSize="sm">
					{contact.name}
				</Box>
			) : null}
			{supplier ? (
				<Box textOverflow="truncate" fontWeight="semibold">
					{supplier.name}
				</Box>
			) : null}
			<Box fontSize="xs" textOverflow="truncate">
				{ticket_tourist_configurations_short_name}
			</Box>
			{opsTeam ? (
				<Inline gap="1" alignItems="center">
					<Box>
						<Icons.User size="3" />
					</Box>
					{opsTeam.length ? (
						<Text fontSize="xs" textOverflow="truncate">
							{opsTeam
								.map((t, _, arr) =>
									arr.length > 1 ? t.name.slice(0, 4) : t.name
								)
								.join(", ")}
						</Text>
					) : (
						<Text fontSize="xs">N/A</Text>
					)}
				</Inline>
			) : null}
			{totalServices > 1 ? (
				<Text fontWeight="semibold" fontSize="sm">
					+{pluralize("Service", totalServices - 1, true)}
				</Text>
			) : null}
		</Stack>
	)
}

function BookingSummary({ booking }: { booking: TTripOperationalBooking }) {
	const { cab_schedules, travel_activity_bookings } = booking
	return (
		<Stack gap="2">
			{cab_schedules?.length ? (
				<Stack gap="2">
					{travel_activity_bookings?.length ? (
						<Inline>
							<Box borderBottomWidth="1">
								<Text fontSize="sm">Cab Schedules</Text>
							</Box>
						</Inline>
					) : null}
					{cab_schedules.map((schedule) => {
						const startDateTime = getStartDateTimeOfCabSchedule(schedule)
						return (
							<Stack key={schedule.id}>
								<Stack gap="px">
									<Text fontWeight="semibold">
										{schedule.transport_service.from_to} -{" "}
										{schedule.transport_service.service}
									</Text>
								</Stack>
								<Box>
									{joinAttributes(
										collect(schedule.cabs)
											.unique((cab) => cab.cab_type.display_name)
											.count() === 1
											? `${schedule.cabs.length} ${schedule.cabs[0].cab_type.display_name}`
											: pluralize("Cab", schedule.cabs.length, true),
										schedule.cabs_booked ? (
											<>
												<Icons.OkCircleSolid color="success" /> Booked
											</>
										) : (
											<>{schedule.booked_cabs_count} Booked</>
										),
										startDateTime ? (
											<Box as="span">
												Pickup {formatDate(startDateTime, "HH:mm [hrs]")}
											</Box>
										) : null
									)}
								</Box>
							</Stack>
						)
					})}
				</Stack>
			) : null}
			{travel_activity_bookings?.length ? (
				<Stack gap="2">
					{cab_schedules?.length ? (
						<Inline>
							<Box borderBottomWidth="1">
								<Text fontSize="sm">Travel Activity Bookings</Text>
							</Box>
						</Inline>
					) : null}
					{travel_activity_bookings.map(
						({
							activity,
							ticket_type,
							ticket_tourist_configurations_short_name,
							...booking
						}) => (
							<Stack key={booking.id}>
								<Text fontWeight="semibold">
									{activity.name}
									{ticket_type ? ` - ${ticket_type.name}` : ``}
								</Text>
								<Box>
									{joinAttributes(
										ticket_tourist_configurations_short_name,
										booking.is_booked ? (
											<>
												<Icons.OkCircleSolid color="success" /> Booked
											</>
										) : (
											"Pending"
										),
										booking.start_time_formatted ? (
											<Box as="span">Start: {booking.start_time_formatted}</Box>
										) : null
									)}
								</Box>
							</Stack>
						)
					)}
				</Stack>
			) : null}
			<Text fontSize="sm">Trip ID: {booking.id}</Text>
		</Stack>
	)
}

export function OperationalBookingFilters() {
	return (
		<Stack gap="4">
			<DatePickerField label="Select A Date" name="date" clearable={false} />
			<SelectField
				select={SelectTripDestination}
				name="trip_destinations"
				multiple
				label="Trip Destinations"
				fetchOnMount
			/>
			<SelectField
				select={SelectTransporServiceProviders}
				name="transport_service_providers"
				multiple
				label="Service Providers"
				fetchOnMount
			/>
			<SelectField
				select={SelectDriver}
				name="drivers"
				multiple
				label="Drivers"
			/>
			<SelectField
				select={SelectTransportServiceLocationPoints}
				name="transport_service_location_points"
				label="Service Cities"
				multiple
			/>
			<SelectField
				select={SelectCabTypes}
				name="cab_types"
				label="Cab Types"
				multiple
				fetchOnMount
			/>
			<FeatureFlag flag="travel_activities">
				<SelectField
					select={SelectActivity}
					name="travel_activities"
					label="Travel Activity Name"
					multiple
				/>
				<GetFieldValue<IOperationalBookingFilters["travel_activities"]>
					name={`travel_activities`}
				>
					{({ value: activities }) => {
						if (!activities) return null
						const ticket_types = activities.flatMap((a) => a.ticket_types)
						if (!ticket_types.length) return null
						return (
							<Box flex="1" maxWidth="sm">
								<SelectField
									label="Ticket/Package Type"
									select={Select}
									name={"travel_activity_ticket_types"}
									options={ticket_types}
									multiple
								/>
							</Box>
						)
					}}
				</GetFieldValue>
			</FeatureFlag>
			<SelectField
				label="Team"
				select={SelectUsers}
				name="owners"
				multiple
				fetchOnMount
			/>
			<SelectField
				select={Select}
				name="status"
				label="Booking Status"
				options={BOOKING_STATUS}
			/>
			<SwitchInputField name="hide_past_trips" label="Hide Old Trips" />
		</Stack>
	)
}

function NavigationButton({
	onClick,
	days,
}: {
	onClick: (days: number) => void
	days: number
}) {
	const prev = days < 0
	const absDays = Math.abs(days)
	return (
		<Button
			type="button"
			onClick={() => onClick(days)}
			data-testid={prev ? "prev-view" : "next-view"}
		>
			{!prev ? (
				<Box as="sup" color="muted">
					{absDays}
				</Box>
			) : null}
			<Icons.ChevronDown rotate={prev ? "90" : "270"} />
			{prev ? (
				<Box as="sup" color="muted">
					{absDays}
				</Box>
			) : null}
		</Button>
	)
}
