import {
	Box,
	Button,
	Inline,
	Icons,
	Select,
	Table,
	Stack,
	Text,
	TableDataCell,
	useTimeout,
	Money,
	Alert,
	Heading,
} from "@sembark-travel/ui/base"
import { useXHR, isAbortError } from "@sembark-travel/xhr"
import React, { useRef, useEffect, useState, useMemo } from "react"
import * as Validator from "yup"
import { IDaywisePrice } from "../utils"
import {
	createDuration,
	dateToUTCString,
	formatDate,
	isSame,
	parseDate,
	startOf,
} from "@sembark-travel/datetime-utils"
import {
	TextInputField,
	SelectField,
	GetFieldValue,
	useFieldValue,
	useForm,
	FieldArray,
	EmptyNumberValidator,
	addKeyToFieldArrayItem,
	getFieldArrayItemKey,
} from "@sembark-travel/ui/form"
import {
	TTravelActivity,
	TTravelActivityTicketType,
	TTravelActivityDuration,
	TTravelActivityTicketTouristConfiguration,
	SelectActivity,
	getTouristConfigurationsForPax,
} from "./../TravelActivities"
import { type TChildrenArray, childrenToQueryParams } from "../Tourists"
import { TTripDestination } from "../TripDestinations"
import {
	formatMoneyByDecimal,
	moneyParseByDecimal,
} from "@sembark-travel/money"
import {
	InlineSelectTenantCurrencyInputField,
	useFunctionalCurrencyOfTenant,
} from "../Currencies"
import { Required } from "utility-types"
import { isTicketTypeClosedOnDate } from "../TravelActivities/utils"

export const TravelActivityDatewisePricesValidationSchema =
	Validator.object().shape({
		dates: Validator.array()
			.of(Validator.date().required("Please select a valid day/date"))
			.required("Days field id required")
			.min(1, "Days field is required"),
		no_of_day: EmptyNumberValidator()
			.positive("Days should be a positive integer")
			.typeError("Days should be a number"),
		activity: Validator.object().required("Please select an activity"),
		ticket_type: Validator.object().nullable(true),
		duration: Validator.object().nullable(true),
		slot: Validator.mixed().nullable(true),
		ticket_tourist_configurations: Validator.array()
			.nullable()
			.of(
				Validator.object().shape({
					configuration: Validator.object().required("Please select a type"),
					quantity: EmptyNumberValidator()
						.positive("Quantity should be a positive integer")
						.integer("Quantity should be a positive integer")
						.required("Quantity field is required"),
					date_wise_prices: Validator.array().required("Please provide prices"),
				})
			),
	})

export type TTravelActivityDatewisePricesInputFieldValue = {
	dates?: Array<Date>
	activity?: TTravelActivity
	ticket_type?: TTravelActivityTicketType
	duration?: TTravelActivityDuration
	slot?: string
	comments?: string
	ticket_tourist_configurations?: Array<{
		__id: number
		quantity?: number
		configuration?: TTravelActivityTicketTouristConfiguration
		fetching_prices?: boolean
		date_wise_prices?: Array<
			IDaywisePrice & {
				per_quantity_price?: number
				per_quantity_given_price?: number
				per_quantity_booked_price?: number
			}
		>
	}>
}

export type TTravelActivityDatewisePricesInputFieldArrayValue = Array<
	{
		__id: number
	} & TTravelActivityDatewisePricesInputFieldValue
>

export type TValidTravelActivityDatewisePricesInputFieldValue = Omit<
	Required<TTravelActivityDatewisePricesInputFieldValue, "dates" | "activity">,
	"ticket_tourist_configurations"
> & {
	ticket_tourist_configurations: Array<
		Required<
			Required<
				TTravelActivityDatewisePricesInputFieldValue,
				"ticket_tourist_configurations"
			>["ticket_tourist_configurations"][number],
			"__id" | "configuration" | "configuration"
		>
	>
}

export type TValidTravelActivityDatewisePricesInputFieldArrayValue = Array<
	{
		__id: number
	} & TValidTravelActivityDatewisePricesInputFieldValue
>

export function TravelActivityDatewisePricesInputFieldArray({
	name,
	hideTitle,
	...props
}: {
	name: string
	hideTitle?: boolean
} & Omit<
	React.ComponentProps<typeof TravelActivityDatewisePricesInputField>,
	"name" | "onRemove" | "onDuplicate"
>) {
	const form = useForm()
	return (
		<FieldArray<TTravelActivityDatewisePricesInputFieldArrayValue[number]>
			name={name}
		>
			{({ fields }) => (
				<Stack gap="4">
					{fields.length && !hideTitle ? (
						<Box paddingTop="4" paddingBottom="2">
							<Heading fontSize="md" color="primary">
								Activities
							</Heading>
						</Box>
					) : null}
					{fields.map((name, index) => (
						<Box
							key={getFieldArrayItemKey(form, name)}
							paddingLeft="4"
							borderLeftWidth="1"
						>
							<TravelActivityDatewisePricesInputField
								{...props}
								name={name}
								onRemove={() => fields.remove(index)}
								onDuplicate={(value) =>
									fields.push(addKeyToFieldArrayItem(value))
								}
							/>
						</Box>
					))}
					<Box paddingY="4">
						<Button
							onClick={() =>
								fields.push(
									addKeyToFieldArrayItem({
										activity: undefined,
										ticket_type: undefined,
										duration: undefined,
										ticket_tourist_configurations: [],
										dates: [],
									})
								)
							}
							size="sm"
							status="primary"
							level="secondary"
						>
							<Icons.Plus /> Add {fields.length ? "More " : ""}Activities
						</Button>
					</Box>
				</Stack>
			)}
		</FieldArray>
	)
}

export function TravelActivityDatewisePricesInputField({
	name,
	currency: propCurrency,
	noOfAdults,
	children,
	dates,
	tripDestinations,
	onRemove,
	onDuplicate,
	showBookedPrices,
	canCustomizeDuration,
}: {
	name: string
	currency?: string
	noOfAdults: number
	children: TChildrenArray
	dates: Array<Date>
	tripDestinations: Array<TTripDestination>
	onRemove?: () => void
	onDuplicate?: (value: TTravelActivityDatewisePricesInputFieldValue) => void
	showBookedPrices?: boolean
	canCustomizeDuration?: boolean
}) {
	const form = useForm()
	const functional_currency = useFunctionalCurrencyOfTenant()
	const currency = useMemo(() => {
		if (propCurrency) return propCurrency
		return tripDestinations.map((d) => d.currency).at(0) || functional_currency
	}, [propCurrency, tripDestinations, functional_currency])
	return (
		<Stack gap="4" borderBottomWidth="1" paddingBottom="4">
			<ActivityDatesInputField name={`${name}.dates`} syncWithDates={dates} />
			<Inline gap="6">
				<Inline gap="4" flex="1" flexWrap="wrap" minWidth="0">
					<Inline flex="1" gap="4" width="full" flexWrap="wrap">
						<Stack gap="4" flex="1" width="full">
							<SelectField
								label="Name"
								select={SelectActivity}
								tripDestinations={tripDestinations}
								name={`${name}.activity`}
								onChange={(value: TTravelActivity | undefined) => {
									// finally, set the activity
									form.change(`${name}.activity` as never, value)
									form.change(
										`${name}.ticket_type` as never,
										value?.ticket_types?.at(0)
									)
									form.change(
										`${name}.duration` as never,
										value?.ticket_types?.at(0)?.durations?.at(0)
									)
									form.change(`${name}.slot` as never, undefined)
									// reset the tickets
									form.change(
										`${name}.ticket_tourist_configurations` as never,
										undefined
									)
								}}
								help={
									<GetFieldValue<TTravelActivity | undefined>
										name={`${name}.activity`}
									>
										{({ value }) =>
											value?.deleted_at ? (
												<Box>
													<Alert status="warning" inline>
														Activity disabled. Please update.
													</Alert>
												</Box>
											) : null
										}
									</GetFieldValue>
								}
							/>
							<Inline flexWrap="wrap" gap="4" collapseBelow="sm">
								<GetFieldValue<TTravelActivity | undefined>
									name={`${name}.activity`}
								>
									{({ value }) =>
										value && value.ticket_types?.length ? (
											<Box flex="1" maxWidth="sm">
												<SelectField
													label="Ticket/Package Type"
													select={Select}
													name={`${name}.ticket_type`}
													options={value.ticket_types}
													clearable={false}
													fullWidth
													onChange={(
														value: TTravelActivityTicketType | undefined
													) => {
														form.change(`${name}.ticket_type` as never, value)
														form.change(
															`${name}.duration` as never,
															value?.durations?.at(0)
														)
														form.change(`${name}.slot` as never, undefined)
													}}
													help={
														<GetFieldValue<
															TTravelActivityTicketType | undefined
														>
															name={`${name}.ticket_type`}
														>
															{({ value }) => {
																if (!value) {
																	return null
																}
																return dates.some((date) =>
																	isTicketTypeClosedOnDate(value, date)
																) ? (
																	<Box>
																		<Alert
																			status="error"
																			title="Activity/Ticket Closed."
																		>
																			<Text>
																				Closed on{" "}
																				{dates
																					.filter((date) =>
																						isTicketTypeClosedOnDate(
																							value,
																							date
																						)
																					)
																					.map((d) =>
																						formatDate(d, "dddd, DD MMM")
																					)
																					.join(", ")}
																				.
																			</Text>
																		</Alert>
																	</Box>
																) : value?.deleted_at ? (
																	<Box>
																		<Alert status="warning" inline>
																			Ticket disabled. Please update.
																		</Alert>
																	</Box>
																) : null
															}}
														</GetFieldValue>
													}
												/>
											</Box>
										) : null
									}
								</GetFieldValue>
								<Inline gap="4">
									<GetFieldValue<TTravelActivityTicketType | undefined>
										name={`${name}.ticket_type`}
									>
										{({ value }) =>
											(value && value.durations?.length) ||
											canCustomizeDuration ? (
												<Box
													style={{
														width: "110px",
													}}
												>
													<ActivityDurationInputField
														options={value?.durations}
														activityFieldName={name}
														creatable={canCustomizeDuration}
													/>
												</Box>
											) : null
										}
									</GetFieldValue>
									<GetFieldValue<TTravelActivityDuration | undefined>
										name={`${name}.duration`}
									>
										{({ value }) =>
											canCustomizeDuration || (value && value.slots?.length) ? (
												<Box
													style={{
														width: "110px",
													}}
												>
													<ActivitySlotInputField
														activityFieldName={name}
														options={value?.slots}
														creatable={canCustomizeDuration}
													/>
												</Box>
											) : null
										}
									</GetFieldValue>
								</Inline>
							</Inline>
						</Stack>
						<ActivityTouristConfigurationsField
							name={name}
							defaultCurrency={currency}
							noOfAdults={noOfAdults}
							children={children}
							showBookedPrices={showBookedPrices}
						/>
					</Inline>
				</Inline>
				{onRemove || onDuplicate ? (
					<Box paddingTop="6">
						<Stack gap="4">
							{onRemove ? (
								<Box>
									<Button onClick={() => onRemove()} size="sm">
										<Icons.Cancel />
									</Button>
								</Box>
							) : null}
							{onDuplicate ? (
								<GetFieldValue<TTravelActivityDatewisePricesInputFieldValue>
									name={name}
								>
									{({ value }) =>
										value && value.activity ? (
											<Box>
												<Button onClick={() => onDuplicate(value)} size="sm">
													<Icons.Duplicate />
												</Button>
											</Box>
										) : null
									}
								</GetFieldValue>
							) : null}
						</Stack>
					</Box>
				) : null}
			</Inline>
		</Stack>
	)
}

function ActivityDatesInputField({
	name,
	syncWithDates,
}: {
	name: string
	syncWithDates: Array<Date>
}) {
	const { onChange } = useFieldValue<Array<Date> | undefined>(name)
	useEffect(() => {
		// sync the field value with given dates
		const handler = setTimeout(() => {
			onChange(syncWithDates)
		})
		return () => clearTimeout(handler)
	}, [onChange, syncWithDates])
	return null
}

function ActivityDurationInputField({
	activityFieldName,
	options,
	creatable,
}: {
	activityFieldName: string
	options?: Array<TTravelActivityDuration>
	creatable?: boolean
}) {
	const form = useForm()
	return (
		<SelectField
			minWidth="auto"
			label="Duration"
			select={Select}
			name={`${activityFieldName}.duration`}
			options={options}
			creatable={Boolean(creatable)}
			placeholder="60 Mins"
			createOptionLabel={(q) => q}
			onCreateNew={(query) => {
				let durationISO = ""
				try {
					durationISO = createDuration(
						query.replace(/[^\d]/g, ""),
						"minutes"
					).toISOString()
				} catch (e) {
					durationISO = ""
				}
				let duration: TTravelActivityDuration | undefined = undefined
				if (durationISO) {
					duration = {
						iso: durationISO,
						name: createDuration(durationISO).asMinutes() + " Mins",
						slots: [],
					}
				}
				return duration
			}}
			onChange={(value: TTravelActivityDuration | undefined) => {
				form.batch(() => {
					form.change(`${activityFieldName}.duration` as never, value)
					form.change(`${activityFieldName}.slot` as never, undefined)
				})
			}}
		/>
	)
}

function ActivitySlotInputField({
	activityFieldName,
	options,
	creatable,
}: {
	activityFieldName: string
	options?: Array<string>
	creatable?: boolean
}) {
	return (
		<SelectField
			minWidth="auto"
			label="Slot"
			select={Select}
			name={`${activityFieldName}.slot`}
			options={options}
			clearable={true}
			placeholder="14:00"
			creatable={Boolean(creatable)}
			createOptionLabel={(q) => q}
			closeOnSelect
			onCreateNew={(query) => {
				let date
				try {
					date = parseDate(query, "HH:mm")
				} catch (e) {
					date = undefined
				}
				return date ? formatDate(date, "HH:mm") : ""
			}}
		/>
	)
}

function ActivityTouristConfigurationsField({
	name: activityFieldName,
	defaultCurrency,
	noOfAdults,
	children,
	showBookedPrices,
}: {
	name: string
	defaultCurrency: string
	noOfAdults: number
	children: TChildrenArray
	showBookedPrices?: boolean
}) {
	const { value: activity } = useFieldValue<
		TTravelActivityDatewisePricesInputFieldValue["activity"]
	>(`${activityFieldName}.activity`)
	const { value: ticket_type } = useFieldValue<
		TTravelActivityDatewisePricesInputFieldValue["ticket_type"]
	>(`${activityFieldName}.ticket_type`)
	const { value: duration } = useFieldValue<
		TTravelActivityDatewisePricesInputFieldValue["duration"]
	>(`${activityFieldName}.duration`)
	const { value: dates } = useFieldValue<
		TTravelActivityDatewisePricesInputFieldValue["dates"]
	>(`${activityFieldName}.dates`)
	const { value: existingComments } = useFieldValue<
		TTravelActivityDatewisePricesInputFieldValue["comments"]
	>(`${activityFieldName}.comments`)
	const [refreshCounter, setRefreshCounter] = useState<number>(0)
	const form = useForm()
	const [commentsVisible, showAddComments] = useState<boolean>(
		Boolean(existingComments?.length)
	)
	if (!activity) return null
	const { ticket_tourist_configurations } = activity
	return (
		<Stack gap="1">
			<Inline gap="4" justifyContent="between">
				<Text fontWeight="semibold">
					Tickets and Prices
					{dates?.length === 1
						? ` - ${formatDate(dates[0], "dddd, D MMM")}`
						: null}
				</Text>
				{dates?.length ? (
					<Inline gap="4" alignItems="center">
						{!commentsVisible ? (
							<Button size="sm" inline onClick={() => showAddComments(true)}>
								<Icons.Annotation /> Comments
							</Button>
						) : null}
						<GetFieldValue<
							TTravelActivityDatewisePricesInputFieldValue["ticket_tourist_configurations"]
						>
							name={`${activityFieldName}.ticket_tourist_configurations`}
						>
							{({ value }) => {
								const fetchingPrices = (value || []).some(
									(c) => c.fetching_prices
								)
								return (
									<Button
										inline
										size="sm"
										title="Refresh Prices"
										onClick={() => setRefreshCounter((counter) => counter + 1)}
									>
										<Icons.Refresh
											spin={fetchingPrices}
											opacity={fetchingPrices ? "50" : undefined}
										/>
									</Button>
								)
							}}
						</GetFieldValue>
					</Inline>
				) : null}
			</Inline>
			<FieldArray<
				Required<TTravelActivityDatewisePricesInputFieldValue>["ticket_tourist_configurations"][number]
			>
				name={`${activityFieldName}.ticket_tourist_configurations`}
			>
				{({ fields }) => (
					<Stack gap="2">
						<Table
							headers={[
								<Inline alignItems="center" gap="4" justifyContent="between">
									<Text>Type</Text>
									{ticket_tourist_configurations?.length ? (
										<Button
											title="Add More Ticket Types for this Activity"
											onClick={() =>
												fields.push(
													addKeyToFieldArrayItem({
														quantity: 1,
														configuration: ticket_tourist_configurations[0],
														date_wise_prices: [],
													})
												)
											}
											inline
											level="tertiary"
										>
											<Icons.Plus />
										</Button>
									) : null}
								</Inline>,
								"Qty.",
							]
								.concat((dates?.length || 0) > 1 ? ["Date"] : [])
								.concat(["Rate", showBookedPrices ? "Booked" : "Given", ""])}
							bordered
						>
							{fields.map((name, index) => (
								<tbody key={getFieldArrayItemKey(form, name)}>
									<RatesForTouristConfiguration
										activity={activity}
										ticketType={ticket_type}
										duration={duration}
										configFieldName={name}
										dates={dates}
										key={refreshCounter}
										isBooking={Boolean(showBookedPrices)}
									/>
									<ResetEditedGivenPriceFieldOnConfigChange
										name={name}
										dates={dates}
										duration={duration}
										ticketType={ticket_type}
										defaultCurrency={defaultCurrency}
									/>
									{!dates?.length ? null : (
										<>
											<FieldArray name={`${name}.date_wise_prices`}>
												{({ fields: dateWisePricesFields }) =>
													dateWisePricesFields.map(
														(dateWisePriceFieldName, row_index) => {
															return (
																<tr key={dateWisePriceFieldName}>
																	<TableDataCell>
																		{row_index === 0 ? (
																			<Box style={{ width: "130px" }}>
																				<SelectField
																					select={Select}
																					options={
																						ticket_tourist_configurations
																					}
																					name={`${name}.configuration`}
																					minWidth="100px"
																					size="sm"
																					clearable={false}
																				/>
																			</Box>
																		) : null}
																	</TableDataCell>
																	<TableDataCell>
																		{row_index === 0 ? (
																			<TextInputField
																				size="sm"
																				name={`${name}.quantity`}
																				style={{
																					width: "60px",
																				}}
																				type="number"
																				placeholder="1"
																				min={1}
																			/>
																		) : null}
																	</TableDataCell>
																	{(dates || []).length > 1 ? (
																		<TableDataCell>
																			{dates[row_index] ? (
																				<Text>
																					{formatDate(
																						dates[row_index],
																						"D MMM"
																					)}
																				</Text>
																			) : null}
																		</TableDataCell>
																	) : null}
																	<GetFieldValue<string>
																		name={`${dateWisePriceFieldName}.currency`}
																	>
																		{({ value: currency }) => {
																			return (
																				<>
																					<TableDataCell>
																						<GetFieldValue<number | undefined>
																							name={`${dateWisePriceFieldName}.per_quantity_price`}
																						>
																							{({ value }) =>
																								value ? (
																									<Money
																										currency={currency}
																										amount={value}
																										showCurrency
																									/>
																								) : (
																									<Box>
																										<Box
																											as="span"
																											color="muted"
																											fontSize="xs"
																											verticalAlign="super"
																										>
																											<InlineSelectTenantCurrencyInputField
																												name={`${dateWisePriceFieldName}.currency`}
																												noCaret
																												defaultValue={
																													defaultCurrency
																												}
																											/>
																										</Box>{" "}
																										<Text
																											color="warning"
																											title="Not Available"
																											as="span"
																										>
																											N/A
																										</Text>
																									</Box>
																								)
																							}
																						</GetFieldValue>
																					</TableDataCell>
																					<TableDataCell>
																						<GetFieldValue<number | undefined>
																							name={`${name}.quantity`}
																						>
																							{({ value }) =>
																								!showBookedPrices ? (
																									<DatewiseGivenPriceForTouristConfiguration
																										name={`${dateWisePriceFieldName}`}
																										quantity={value}
																										currency={currency}
																									/>
																								) : (
																									<DatewiseBookedPriceForTouristConfiguration
																										name={`${dateWisePriceFieldName}`}
																										quantity={value}
																										currency={currency}
																									/>
																								)
																							}
																						</GetFieldValue>
																					</TableDataCell>
																				</>
																			)
																		}}
																	</GetFieldValue>
																	<TableDataCell>
																		{row_index === 0 ? (
																			<Box
																				opacity={
																					(fields.length || 0) <= 1
																						? "0"
																						: "100"
																				}
																			>
																				<Button
																					onClick={() => fields.remove(index)}
																					inline
																					disabled={(fields.length || 0) <= 1}
																				>
																					<Icons.Cancel />
																				</Button>
																			</Box>
																		) : null}
																	</TableDataCell>
																</tr>
															)
														}
													)
												}
											</FieldArray>
										</>
									)}
								</tbody>
							))}
						</Table>
					</Stack>
				)}
			</FieldArray>
			{commentsVisible ? (
				<TextInputField
					name={`${activityFieldName}.comments`}
					label="Comments"
					type="text"
					size="sm"
					placeholder="Any comments regarding prices..."
				/>
			) : null}
			<SetupTouristsConfigurationForPax
				name={activityFieldName}
				children={children}
				noOfAdults={noOfAdults}
			/>
		</Stack>
	)
}

function SetupTouristsConfigurationForPax({
	name,
	noOfAdults,
	children,
}: {
	name: string
	noOfAdults: number
	children: TChildrenArray
}) {
	const { value } =
		useFieldValue<TTravelActivityDatewisePricesInputFieldValue>(name)
	const { activity, ticket_tourist_configurations } = value || {}
	const hasTouristsConfigurationAttached = Boolean(
		ticket_tourist_configurations?.length
	)
	const lastSavedTicketTouristConfigurations = useRef(
		ticket_tourist_configurations
	)

	if (ticket_tourist_configurations) {
		lastSavedTicketTouristConfigurations.current = ticket_tourist_configurations
	}

	const form = useForm()
	const paxRef = useRef({
		noOfAdults,
		children,
	})
	// reset the config if pax changes
	useEffect(() => {
		if (
			noOfAdults !== paxRef.current.noOfAdults ||
			childrenToQueryParams(paxRef.current.children) !==
				childrenToQueryParams(children)
		) {
			paxRef.current.noOfAdults = noOfAdults
			paxRef.current.children = children
			// reset the configuration
			form.change(`${name}.ticket_tourist_configurations`, undefined)
		}
	}, [noOfAdults, children, form, name])

	// create a ticket configuration if not already exists
	useEffect(() => {
		if (!hasTouristsConfigurationAttached && activity) {
			// add tourist configurations
			const touristsConfigurations = getTouristConfigurationsForPax(
				activity.ticket_tourist_configurations,
				noOfAdults,
				children
			)
			if (!touristsConfigurations.length) {
				touristsConfigurations.push({
					quantity: 1,
					configuration: activity.ticket_tourist_configurations[0],
				})
			}
			const handler = setTimeout(() => {
				const touristsConfigurationsFieldValue: TTravelActivityDatewisePricesInputFieldValue["ticket_tourist_configurations"] =
					touristsConfigurations.map((config) =>
						addKeyToFieldArrayItem({
							...config,
							date_wise_prices:
								lastSavedTicketTouristConfigurations.current?.find(
									(c) => c.configuration?.id === config.configuration.id
								)?.date_wise_prices || [],
						})
					)
				form.change(
					`${name}.ticket_tourist_configurations`,
					touristsConfigurationsFieldValue
				)
			})
			return () => clearTimeout(handler)
		}
	}, [
		form,
		activity,
		hasTouristsConfigurationAttached,
		children,
		noOfAdults,
		name,
	])
	return null
}

function RatesForTouristConfiguration({
	activity,
	ticketType,
	duration,
	configFieldName,
	dates,
	isBooking,
}: {
	activity: TTravelActivity
	ticketType?: TTravelActivityTicketType
	duration?: TTravelActivityDuration
	configFieldName: string
	dates?: Array<Date>
	isBooking: boolean
}) {
	const { value: quantity } = useFieldValue<
		Required<TTravelActivityDatewisePricesInputFieldValue>["ticket_tourist_configurations"][number]["quantity"]
	>(`${configFieldName}.quantity`)
	const { value: configuration } = useFieldValue<
		Required<TTravelActivityDatewisePricesInputFieldValue>["ticket_tourist_configurations"][number]["configuration"]
	>(`${configFieldName}.configuration`)
	const form = useForm()
	const xhr = useXHR()
	const abortController = useRef<AbortController>()
	const { set: setTimer, clear: clearTimer } = useTimeout()
	useEffect(() => {
		// abort any pending requests
		abortController.current = new AbortController()
		if (!dates?.length || !configuration) {
			abortController.current?.abort()
			clearTimer()
			return () => undefined
		}
		setTimer(() => {
			// fetch the prices and update
			Promise.resolve()
				.then(async () => {
					form.change(`${configFieldName}.fetching_prices`, 1)
					const prices = await Promise.all(
						dates.map((date) =>
							xhr
								.get<{
									data: {
										price: number | null
										per_quantity_price: number | null
										currency: string | null
									}
								}>(`/travel-activity-prices/calculate`, {
									params: {
										date: dateToUTCString(date),
										date_local: formatDate(date, "YYYY-MM-DD"),
										activity_id: activity.id,
										ticket_tourist_configuration_id: configuration.id,
										ticket_type_id: ticketType?.id,
										duration: duration?.iso,
										quantity,
										is_booking: isBooking ? 1 : 0,
									},
									signal: abortController.current?.signal,
								})
								.then((resp) => resp.data.data)
						)
					)
					abortController.current = undefined
					dates.forEach((date, index) => {
						const per_quantity_price = prices[index].per_quantity_price
						const total_price = prices[index].price
						const currency = prices[index].currency

						form.change(
							`${configFieldName}.date_wise_prices[${index}].date`,
							date
						)
						if (currency) {
							form.change(
								`${configFieldName}.date_wise_prices[${index}].currency`,
								currency
							)
							form.change(
								`${configFieldName}.date_wise_prices[${index}].per_quantity_price`,
								per_quantity_price !== undefined && per_quantity_price !== null
									? formatMoneyByDecimal(
											moneyParseByDecimal(per_quantity_price, currency)
										)
									: undefined
							)
							form.change(
								`${configFieldName}.date_wise_prices[${index}].price`,
								total_price !== undefined && total_price !== null
									? formatMoneyByDecimal(
											moneyParseByDecimal(total_price, currency)
										)
									: undefined
							)
						} else {
							form.change(
								`${configFieldName}.date_wise_prices[${index}].per_quantity_price`,
								undefined
							)
							form.change(
								`${configFieldName}.date_wise_prices[${index}].price`,
								undefined
							)
						}
					})
				})
				.then(() => {
					form.change(`${configFieldName}.fetching_prices`, 0)
				})
				.catch((e) => {
					if (!isAbortError(e)) {
						form.change(`${configFieldName}.fetching_prices`, 0)
						throw e
					}
				})
		}, 300)
		return () => {
			// Cancel any pending fetch
			abortController.current?.abort()
			clearTimer()
		}
	}, [
		activity,
		ticketType,
		duration,
		configuration,
		quantity,
		form,
		dates,
		configFieldName,
		xhr,
		setTimer,
		clearTimer,
		isBooking,
	])
	return null
}

function DatewiseGivenPriceForTouristConfiguration({
	name,
	quantity,
	currency,
}: {
	name: string
	quantity?: number
	currency: string
}) {
	const form = useForm()
	const { value: price } = useFieldValue<number | undefined>(`${name}.price`)
	const { value: per_quantity_price } = useFieldValue<number | undefined>(
		`${name}.per_quantity_price`
	)
	const { value: per_quantity_given_price } = useFieldValue<number | undefined>(
		`${name}.per_quantity_given_price`
	)
	const { value: edited_given_price } = useFieldValue<boolean | undefined>(
		`${name}.edited_given_price`
	)
	useEffect(() => {
		if (!edited_given_price) {
			const handler = setTimeout(() => {
				form.change(`${name}.per_quantity_given_price`, per_quantity_price)
				form.change(`${name}.given_price`, price || 0)
			})
			return () => clearTimeout(handler)
		}
	}, [name, price, per_quantity_price, edited_given_price, form])

	// update the given price when per_quantity_given_price or noOfCabs changes
	useEffect(() => {
		if (edited_given_price) {
			const given_price =
				Number(quantity || 0) * Number(per_quantity_given_price || 0)
			const handler = setTimeout(() => {
				form.change(`${name}.given_price`, given_price)
			})
			return () => clearTimeout(handler)
		}
	}, [edited_given_price, quantity, per_quantity_given_price, form, name])
	return (
		<TextInputField
			size="sm"
			name={`${name}.per_quantity_given_price`}
			type="number"
			placeholder="1"
			style={{ maxWidth: "100px" }}
			readOnly={!quantity}
			help={
				<GetFieldValue<number | undefined> name={`${name}.given_price`}>
					{({ value }) =>
						value && Number(quantity || 0) > 1 ? (
							<Text>
								x {quantity} = <Money currency={currency} amount={value} />
							</Text>
						) : null
					}
				</GetFieldValue>
			}
			onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
				const value = e.currentTarget.value
				form.change(`${name}.edited_given_price` as never, true as never)
				form.change(`${name}.per_quantity_given_price` as never, value as never)
			}}
		/>
	)
}

function DatewiseBookedPriceForTouristConfiguration({
	name,
	quantity,
	currency,
}: {
	name: string
	quantity?: number
	currency: string
}) {
	const form = useForm()
	const { value: price } = useFieldValue<number | undefined>(`${name}.price`)
	const { value: per_quantity_price } = useFieldValue<number | undefined>(
		`${name}.per_quantity_price`
	)
	const { value: per_quantity_booked_price } = useFieldValue<
		number | undefined
	>(`${name}.per_quantity_booked_price`)
	const { value: edited_booked_price } = useFieldValue<boolean | undefined>(
		`${name}.edited_booked_price`
	)
	useEffect(() => {
		if (!edited_booked_price) {
			const handler = setTimeout(() => {
				form.change(`${name}.per_quantity_booked_price`, per_quantity_price)
				form.change(`${name}.booked_price`, price || 0)
			})
			return () => clearTimeout(handler)
		}
	}, [name, price, per_quantity_price, edited_booked_price, form])

	// update the given price when per_quantity_given_price or noOfCabs changes
	useEffect(() => {
		if (edited_booked_price) {
			const booked_price =
				Number(quantity || 0) * Number(per_quantity_booked_price || 0)
			const handler = setTimeout(() => {
				form.change(`${name}.booked_price`, booked_price)
			})
			return () => clearTimeout(handler)
		}
	}, [edited_booked_price, quantity, per_quantity_booked_price, form, name])
	return (
		<TextInputField
			size="sm"
			name={`${name}.per_quantity_booked_price`}
			type="number"
			placeholder="1"
			style={{ maxWidth: "100px" }}
			readOnly={!quantity}
			help={
				<GetFieldValue<number | undefined> name={`${name}.booked_price`}>
					{({ value }) =>
						value && Number(quantity || 0) > 1 ? (
							<Text>
								x {quantity} = <Money currency={currency} amount={value} />
							</Text>
						) : null
					}
				</GetFieldValue>
			}
			onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
				const value = e.currentTarget.value
				form.change(`${name}.edited_booked_price` as never, true as never)
				form.change(
					`${name}.per_quantity_booked_price` as never,
					value as never
				)
			}}
		/>
	)
}

function ResetEditedGivenPriceFieldOnConfigChange({
	name,
	dates,
	ticketType,
	defaultCurrency,
	duration,
}: {
	name: string
	dates?: Array<Date>
	ticketType?: TTravelActivityTicketType
	defaultCurrency: string
	duration?: TTravelActivityDuration
}) {
	const { value } =
		useFieldValue<
			Required<TTravelActivityDatewisePricesInputFieldValue>["ticket_tourist_configurations"][number]
		>(name)
	const previousValues = useRef({
		configuration: value.configuration,
		// on-mount, if we have dates but not date-wise-prices,
		// we set it to empty to ensure that we generate the date_wise_prices
		dates: dates?.length && !value.date_wise_prices?.length ? [] : dates,
		ticketType,
		duration,
	})
	const form = useForm()
	useEffect(() => {
		if (
			previousValues.current.configuration?.id !== value.configuration?.id ||
			!areDatesSame(previousValues.current.dates, dates) ||
			previousValues.current.ticketType?.id !== ticketType?.id ||
			previousValues.current.duration?.iso !== duration?.iso
		) {
			const date_wise_prices = !dates?.length
				? []
				: dates.map((date) => ({
						...(value.date_wise_prices?.find((data) =>
							isSame(data.date, date, "day")
						) || {
							currency: defaultCurrency,
							given_price: 0,
							per_quantity_given_price: 0,
							booked_price: 0,
							per_quantity_booked_price: 0,
							price: 0,
							per_quantity_price: 0,
						}),
						date,
						edited_given_price: false,
						edited_booked_price: false,
					}))
			const handler = setTimeout(() => {
				form.change(`${name}.date_wise_prices`, date_wise_prices)
			})
			previousValues.current.configuration = value.configuration
			previousValues.current.dates = dates
			previousValues.current.ticketType = ticketType
			previousValues.current.duration = duration
			return () => clearTimeout(handler)
		}
	}, [value, form, name, dates, ticketType, defaultCurrency, duration])
	return null
}

function areDatesSame(
	dates1?: Array<Date | undefined> | "",
	dates2?: Array<Date | undefined> | ""
): boolean {
	function datesToString(dates?: Array<Date | undefined> | "") {
		return dates
			? dates
					.map((d) =>
						d
							? d instanceof Date
								? startOf(d, "day").toISOString()
								: String(d)
							: "-"
					)
					.join(",")
			: ""
	}
	return datesToString(dates1) === datesToString(dates2)
}
