import { Helmet } from "react-helmet-async"
import { CabTypesList } from "../../../CabTypes"

export default function CabTypesListingPage() {
	return (
		<>
			<Helmet>
				<title>Cab Types</title>
			</Helmet>
			<CabTypesList />
		</>
	)
}
