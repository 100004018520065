import {
	Badge,
	Box,
	Button,
	Icons,
	CheckboxInput,
	useId,
	Inline,
	Stack,
	Heading,
	Text,
	joinAttributes,
	Spinner,
	TabContent,
	TabItem,
	Tabs,
	TabsList,
	ShadowRoot,
} from "@sembark-travel/ui/base"
import { useDialog, Dialog } from "@sembark-travel/ui/dialog"
import {
	CopyToClipboardButton,
	CopyToClipboard,
} from "@sembark-travel/ui/copy-to-clipboard"
import { ShareViaWhatsApp } from "@sembark-travel/ui/share"
import {
	utcTimestampToLocalDate,
	utcTimestampToLocalDateString,
	getDiff,
	localOrUtcTimestampToLocalDate,
	formatDate,
	parseDate,
} from "@sembark-travel/datetime-utils"
import { withOrdinalSuffix } from "@sembark-travel/number-utils"
import { useXHR } from "@sembark-travel/xhr"
import {
	arrayMutators,
	CheckboxInputField,
	Form,
	GetFieldValue,
	isTruthy,
} from "@sembark-travel/ui/form"
import pluralize from "pluralize"
import React, {
	Fragment,
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useState,
} from "react"
import useSWR from "swr"
import { Optional, Required } from "utility-types"
import { ICab } from "../Cabs"
import { ICabType } from "../CabTypes"
import { TDriver } from "../Drivers"
import type { ITransportServiceProvider } from "../TransportServiceProviders"
import { collect } from "../utils"
import type { IScheduledCab, ICabSchedule } from "./../CabScheduling/store"
import type { TArrivalDepartureDetail } from "../Trips"
import { TTripOperationalBooking } from "./store"
import { TTravelActiviytBooking } from "../TravelActivityBookings"
import {
	TMoney,
	addMoneyFromDifferentCurrencies,
	formatMoneyByIntl,
	isZeroMoney,
	moneyParseByDecimal,
} from "@sembark-travel/money"
import { Markdown } from "@sembark-travel/ui/markdown"
import { getStartDateTimeOfCabSchedule } from "../CabScheduling"

type TSharableTripOperationalBooking = Optional<
	Pick<TTripOperationalBooking, "id" | "daywise_services">,
	"daywise_services"
>

const TrackingShareContentType = "cab_schedules"

type ShareWith = Array<
	"guest" | "transportServiceProvider" | "driver" | "booking"
>

const DEFAULT_SHARE_WITH: ShareWith = [
	"guest",
	"transportServiceProvider",
	"driver",
	"booking",
]

type TShareTripOperationalBookingsProps = {
	trip?: TSharableTripOperationalBooking
	trips?: Array<TSharableTripOperationalBooking>
	date?: Date
	canShareWith?: ShareWith
	cabSchedules?: Array<ICabSchedule>
	travelActivityBookings?: Array<TTravelActiviytBooking>
	children: (props: { share: () => void }) => React.ReactNode
}

export function ShareTripOperationalBookings({
	trip,
	trips,
	children,
	cabSchedules,
	travelActivityBookings,
	canShareWith = DEFAULT_SHARE_WITH,
}: TShareTripOperationalBookingsProps) {
	const [isOpen, open, close] = useDialog()
	const [{ selectedTripOperationalBookings, selectedTripId }, dispatch] =
		useReducer<
			React.Reducer<
				{
					selectedTripOperationalBookings: Array<TSharableTripOperationalBooking>
					selectedTripId?: number
				},
				{
					selectedTripOperationalBookings?: Array<TSharableTripOperationalBooking>
					selectedTripId?: number
				}
			>
		>((state, action) => ({ ...state, ...action }), {
			selectedTripOperationalBookings: trip ? [trip] : trips || [],
			selectedTripId: undefined,
		})
	const onCancel = useCallback(() => {
		close()
		dispatch({
			selectedTripOperationalBookings: trip ? [trip] : trips || [],
			selectedTripId: undefined,
		})
	}, [close, trip, trips])
	return (
		<>
			{children({
				share: () => {
					open()
				},
			})}
			{isOpen ? (
				<Dialog open={isOpen} onClose={onCancel} xl>
					<Dialog.Header>
						<Box display={{ sm: "flex" }} justifyContent="between">
							<Dialog.Title>Share Operational Bookings</Dialog.Title>
							{!selectedTripId &&
							selectedTripOperationalBookings.length === 1 ? (
								<Box marginRight="8" marginTop={{ xs: "2", sm: "0" }}>
									<Button
										level="secondary"
										status="primary"
										size="sm"
										onClick={() => {
											dispatch({
												selectedTripId: selectedTripOperationalBookings[0].id,
												selectedTripOperationalBookings: [],
											})
										}}
									>
										Select Other Bookings
									</Button>
								</Box>
							) : null}
						</Box>
					</Dialog.Header>
					{selectedTripOperationalBookings.length ? (
						<Box>
							<ShareSelectedTripOperationalBookings
								bookings={selectedTripOperationalBookings}
								canShareWith={canShareWith}
								cabSchedules={cabSchedules}
								travelActivityBookings={travelActivityBookings}
							/>
						</Box>
					) : trip?.id || selectedTripId ? (
						<Dialog.Body>
							<SelectTripOperationalBookingsToShare
								tripId={(trip?.id || selectedTripId) as number}
								onCancel={onCancel}
								onSelect={(schedules) =>
									dispatch({
										selectedTripOperationalBookings: schedules,
										selectedTripId: undefined,
									})
								}
							/>
						</Dialog.Body>
					) : (
						<Box>Please select a trip or a schedule to share</Box>
					)}
				</Dialog>
			) : null}
		</>
	)
}

type TInclusionOptions = {
	prices: boolean
	itinerary: boolean
	arrivalDepartures: boolean
	remarks: boolean
}

function ShareSelectedTripOperationalBookings({
	bookings,
	canShareWith = DEFAULT_SHARE_WITH,
	cabSchedules,
	travelActivityBookings,
}: {
	bookings: Array<TSharableTripOperationalBooking>
	canShareWith?: ShareWith
	cabSchedules?: Array<ICabSchedule>
	travelActivityBookings?: Array<TTravelActiviytBooking>
}) {
	const xhr = useXHR()
	const id = useId()
	const [shareWithTab, setShareWithTab] = useState<ShareWith[number]>(
		canShareWith[0]
	)
	const [shareVia, setShareVia] = useState<"whatsapp" | "email">("whatsapp")
	const [shareWithDriver, setShareWithDriver] = useState<TDriver | undefined>()
	const [shareWithTrip, setShareWithTrip] = useState<
		TTripOperationalBooking | undefined
	>()
	const [
		{
			prices: includePrices,
			itinerary: includeItinerary,
			arrivalDepartures: includeArrivalDepartures,
			remarks: includeRemarks,
		},
		toggleInclusions,
	] = useReducer(
		(state: TInclusionOptions, actions: Partial<TInclusionOptions>) => ({
			...state,
			...actions,
		}),
		{
			prices: true,
			itinerary: false,
			arrivalDepartures: true,
			remarks: true,
		}
	)
	const [
		shareWithTransportServiceProvider,
		setShareWithTransportServiceProvider,
	] = useState<ITransportServiceProvider | undefined>()
	const { data } = useSWR(
		`/api/trip-operational-bookings/${bookings.map((s) => s.id).join("-")}?${id}`,
		() =>
			Promise.all(
				bookings.map((s) => {
					const selectedCabSchedules = cabSchedules
						? cabSchedules.map((s) => s.id)
						: (s?.daywise_services || []).flatMap((s) =>
								s.services.map((s) => s.cab_schedule?.id).filter(Boolean)
							)
					const selectedTravelActivityBookings = travelActivityBookings
						? travelActivityBookings.map((t) => t.id)
						: (s?.daywise_services || []).flatMap((s) =>
								s.services
									.map((s) => s.travel_activity_booking?.id)
									.filter(Boolean)
							)
					return xhr.get<{ data: TTripOperationalBooking }>(
						`/trip-operational-bookings/${s.id}`,
						{
							params: {
								cab_schedules: selectedCabSchedules.length
									? selectedCabSchedules
									: [0], // ensure that we pass something unexpected, otherwise, we will get all bookings
								travel_activity_bookings: selectedTravelActivityBookings.length
									? selectedTravelActivityBookings
									: [0], // ensure that we pass something unexpected, otherwise, we will get all bookings
								include: "itinerary,hotels,arrival_departure,tenant_brand",
							},
						}
					)
				})
			).then((schedules) => {
				return schedules.map((s) => s.data.data)
			})
	)
	// filter out the schedules that the user can modify
	const modifiableSchedules = useMemo(
		() => (!data ? [] : data.filter((s) => s.can_modify)),
		[data]
	)
	const whatsappTextForBooking = useMemo(
		() =>
			getWhatsAppMessageContentForBooking(modifiableSchedules, {
				includePrices,
				includeItinerary,
				includeRemarks,
			}),
		[modifiableSchedules, includePrices, includeItinerary, includeRemarks]
	)
	const whatsappTextForGuestPerTrip = useMemo(
		() =>
			!data
				? {}
				: getWhatsAppMessageContentForGuestPerTrip(data, {
						includeItinerary,
						includeRemarks,
					}),
		[data, includeItinerary, includeRemarks]
	)
	const whatsappTextPerDriver: { [driverId: string]: string } = useMemo(
		() =>
			getWhatsAppMessageContentForDrivers(modifiableSchedules, {
				itinerary: includeItinerary,
				arrivalDepartures: includeArrivalDepartures,
				remarks: includeRemarks,
			}),
		[
			modifiableSchedules,
			includeItinerary,
			includeArrivalDepartures,
			includeRemarks,
		]
	)
	const whatsappTextPerTransportServiceProvider: {
		[tspId: string]: string
	} = useMemo(
		() =>
			getWhatsAppMessageContentForTransportServiceProviders(
				modifiableSchedules,
				{
					prices: includePrices,
					itinerary: includeItinerary,
					arrivalDepartures: includeArrivalDepartures,
					remarks: includeRemarks,
				}
			),
		[
			modifiableSchedules,
			includePrices,
			includeItinerary,
			includeArrivalDepartures,
			includeRemarks,
		]
	)
	const trips = data
	const drivers = useMemo(() => {
		const drivers: Array<TDriver> = []
		modifiableSchedules
			.flatMap((s) => s.cab_schedules || [])
			.forEach(function (schedule) {
				schedule.cabs.forEach((cab) => {
					const { driver } = cab
					if (driver && drivers.findIndex((d) => d.id === driver.id) === -1) {
						drivers.push(driver)
					}
				})
			})
		return drivers
	}, [modifiableSchedules])
	const transportServiceProviders = useMemo(() => {
		return getTransportServiceProvidersWithBookings(modifiableSchedules)
			.transportServiceProviders
	}, [modifiableSchedules])
	const hasAnythingBooked = useMemo(() => {
		if (!data) return false
		return data.some(
			(data) =>
				data.cab_schedules?.some((s) => s.cabs_booked) ||
				data.travel_activity_bookings?.some((s) => s.is_booked)
		)
	}, [data])
	const hasAnythingAssigned = useMemo(() => {
		if (!data) return false
		return data.some(
			(data) =>
				data.cab_schedules?.some((s) =>
					s.cabs.some((s) => s.transport_service_provider)
				) || data.travel_activity_bookings?.some((s) => s.supplier)
		)
	}, [data])
	useEffect(() => {
		// set the first booked trip when sharing with guest and
		// trip is not already set
		if (
			trips &&
			trips?.length !== 0 &&
			shareWithTab === "guest" &&
			shareWithTrip === undefined
		) {
			setShareWithTrip(trips[0])
		}
	}, [trips, data, shareWithTab, shareWithTrip])
	useEffect(() => {
		// set the first tsp when sharing with transportServiceProvider and
		// tsp is not already set
		if (
			data &&
			transportServiceProviders.length !== 0 &&
			shareWithTab === "transportServiceProvider" &&
			shareWithTransportServiceProvider === undefined
		) {
			setShareWithTransportServiceProvider(transportServiceProviders[0])
		}
	}, [
		transportServiceProviders,
		data,
		shareWithTab,
		shareWithTransportServiceProvider,
	])
	useEffect(() => {
		// set the first driver when sharing with driver and
		// driver is not already set
		if (
			data &&
			drivers.length !== 0 &&
			shareWithTab === "driver" &&
			shareWithDriver === undefined
		) {
			setShareWithDriver(drivers[0])
		}
	}, [drivers, data, shareWithTab, shareWithDriver])
	useEffect(() => {
		// default active is `Share with Guest`
		// Set the active tab to `booking` if no schedules are booked
		if (data && !hasAnythingBooked && shareWithTab === "guest") {
			if (
				hasAnythingAssigned &&
				canShareWith.indexOf("transportServiceProvider") !== -1
			) {
				setShareWithTab("transportServiceProvider")
				setShareVia("whatsapp")
			} else if (
				modifiableSchedules.length > 0 &&
				canShareWith.indexOf("booking") !== -1
			) {
				setShareWithTab("booking")
				setShareVia("whatsapp")
			}
		}
	}, [
		hasAnythingBooked,
		hasAnythingAssigned,
		data,
		shareWithTab,
		modifiableSchedules,
		canShareWith,
	])
	if (!data) {
		return (
			<Box>
				<Spinner alignCenter padding="4" />
			</Box>
		)
	}
	if (!hasAnythingBooked && modifiableSchedules.length === 0) {
		return (
			<Box padding="8" textAlign="center">
				<Box fontWeight="semibold" marginBottom="4">
					No Schedules to Share
				</Box>
				<Box as="p" color="muted">
					Selected schedule(s) have not been booked, hence can not be shared
					with guest.
				</Box>
			</Box>
		)
	}
	return (
		<Tabs layout="col" gap="0" position="relative">
			<TabsList>
				{hasAnythingBooked && canShareWith.indexOf("guest") !== -1 && trips ? (
					<TabItem
						active={shareWithTab === "guest"}
						onClick={() => {
							setShareWithTab("guest")
							setShareWithTrip(trips[0])
							setShareVia("whatsapp")
						}}
					>
						{pluralize("Guests", trips.length)}
					</TabItem>
				) : null}
				{transportServiceProviders.length > 0 &&
				canShareWith.indexOf("transportServiceProvider") !== -1 ? (
					<TabItem
						active={shareWithTab === "transportServiceProvider"}
						onClick={() => {
							setShareWithTab("transportServiceProvider")
							setShareWithTransportServiceProvider(transportServiceProviders[0])
							setShareVia("whatsapp")
						}}
					>
						{pluralize("Service Provider", transportServiceProviders.length)}
					</TabItem>
				) : null}
				{drivers.length > 0 && canShareWith.indexOf("driver") !== -1 ? (
					<TabItem
						active={shareWithTab === "driver"}
						onClick={() => {
							setShareWithTab("driver")
							setShareWithDriver(drivers[0])
							setShareVia("whatsapp")
						}}
					>
						{pluralize("Driver", drivers.length)}
					</TabItem>
				) : null}
				{modifiableSchedules.length &&
				canShareWith.indexOf("booking") !== -1 ? (
					<TabItem
						active={shareWithTab === "booking"}
						onClick={() => {
							setShareWithTab("booking")
							setShareVia("whatsapp")
						}}
					>
						Booking Enquiry
					</TabItem>
				) : null}
			</TabsList>
			<Box
				padding="4"
				bgColor="subtle"
				position="sticky"
				top="0"
				zIndex="10"
				borderBottomWidth="1"
				boxShadow="sm"
			>
				<Inline gap="4">
					{shareWithTab !== "guest" && shareWithTab !== "driver" ? (
						<Inline as="label" gap="2" alignItems="center">
							<CheckboxInput
								name="include_price"
								checked={includePrices}
								onChange={() => toggleInclusions({ prices: !includePrices })}
							/>
							<Text>Price</Text>
						</Inline>
					) : null}
					<Inline as="label" gap="2" alignItems="center">
						<CheckboxInput
							checked={includeItinerary}
							name="include_itinerary"
							onChange={() =>
								toggleInclusions({ itinerary: !includeItinerary })
							}
						/>
						<Text>Itinerary</Text>
					</Inline>
					<Inline as="label" gap="2" alignItems="center">
						<CheckboxInput
							checked={includeRemarks}
							name="include_remarks"
							onChange={() => toggleInclusions({ remarks: !includeRemarks })}
						/>
						<Text>Remarks</Text>
					</Inline>
				</Inline>
			</Box>
			<TabContent active={shareWithTab === "booking"} key="booking_whatsapp">
				<Box paddingX="4" paddingY="2">
					<Text color="muted">
						Please share it with your respective{" "}
						<b>transport service provider</b>.
					</Text>
				</Box>
				<Box roundedBottom="md" overflow="hidden">
					<Tabs layout="col" gap="0">
						<TabsList>
							<TabItem
								onClick={() => setShareVia("whatsapp")}
								active={shareVia === "whatsapp"}
							>
								WhatsApp
							</TabItem>
							<TabItem
								onClick={() => setShareVia("email")}
								active={shareVia === "email"}
							>
								Email
							</TabItem>
						</TabsList>
						<TabContent active={shareVia === "whatsapp"}>
							<ShareViaWhatsApp
								text={whatsappTextForBooking}
								trackingContentType={TrackingShareContentType}
								trackingContentId="booking_enquiry"
							/>
						</TabContent>
						<TabContent active={shareVia === "email"}>
							<EmailShare shareWith="booking_enquiry">
								<EmailContentForBooking
									bookings={data}
									includePrices={includePrices}
									includeItinerary={includeItinerary}
									includeRemarks={includeRemarks}
								/>
							</EmailShare>
						</TabContent>
					</Tabs>
				</Box>
			</TabContent>
			{trips && trips.length > 1 && shareWithTab === "guest" ? (
				<Stack>
					<Box paddingX="4" paddingY="2">
						<Text>Please select the guest you want to share details with:</Text>
					</Box>
					<Tabs layout="col">
						<TabsList>
							{trips.map((trip) => (
								<TabItem
									key={trip.id}
									active={shareWithTrip?.id === trip.id}
									onClick={() => {
										setShareWithTrip(trip)
									}}
								>
									{trip.contact.name}
								</TabItem>
							))}
						</TabsList>
					</Tabs>
				</Stack>
			) : null}
			{transportServiceProviders.length > 1 &&
			shareWithTab === "transportServiceProvider" ? (
				<Stack>
					<Box paddingX="4" paddingY="2">
						<Text>
							Please select the service provider you want to share details with:
						</Text>
					</Box>
					<Tabs layout="col">
						<TabsList>
							{transportServiceProviders.map((d) => (
								<TabItem
									key={d.id}
									active={shareWithTransportServiceProvider?.id === d.id}
									onClick={() => {
										setShareWithTransportServiceProvider(d)
									}}
								>
									{d.name}
								</TabItem>
							))}
						</TabsList>
					</Tabs>
				</Stack>
			) : null}
			{drivers.length > 1 && shareWithTab === "driver" ? (
				<Stack>
					<Box paddingX="4" paddingY="2">
						<Text>
							Please select the driver you want to share details with:
						</Text>
					</Box>
					<Tabs layout="col">
						<TabsList>
							{drivers.map((d) => (
								<TabItem
									key={d.id}
									active={shareWithDriver?.id === d.id}
									onClick={() => {
										setShareWithDriver(d)
									}}
								>
									{d.name}
								</TabItem>
							))}
						</TabsList>
					</Tabs>
				</Stack>
			) : null}
			{trips?.map((trip) => (
				<TabContent
					active={
						shareWithTab === "guest" &&
						shareVia === "whatsapp" &&
						shareWithTrip?.id === trip.id
					}
					key={`guest_${trip.id}_whatsapp`}
				>
					<ShareViaWhatsApp
						text={whatsappTextForGuestPerTrip[trip.id]}
						phoneNumber={trip.contact.phone_numbers}
						trackingContentType={TrackingShareContentType}
						trackingContentId="guest"
					/>
				</TabContent>
			))}
			<TabContent
				active={shareWithTab === "transportServiceProvider"}
				key={`tsp_whatsapp`}
			>
				<Tabs layout="col">
					<TabsList>
						<TabItem
							onClick={() => setShareVia("whatsapp")}
							active={shareVia === "whatsapp"}
						>
							WhatsApp
						</TabItem>
						<TabItem
							onClick={() => setShareVia("email")}
							active={shareVia === "email"}
						>
							Email
						</TabItem>
					</TabsList>
					<TabContent active={shareVia === "whatsapp"}>
						{transportServiceProviders.map((tsp) => (
							<TabContent
								active={shareWithTransportServiceProvider?.id === tsp.id}
								key={`tsp_${tsp.id}_whatsapp`}
							>
								<ShareViaWhatsApp
									text={whatsappTextPerTransportServiceProvider[tsp.id]}
									phoneNumber={
										tsp.contacts?.length
											? tsp.contacts[0].phone_numbers
											: undefined
									}
									trackingContentType={TrackingShareContentType}
									trackingContentId="transport_service_provider"
								/>
							</TabContent>
						))}
					</TabContent>
					<TabContent active={shareVia === "email"}>
						<EmailContentForTransportServiceProviders
							bookings={data}
							selectedTsp={shareWithTransportServiceProvider}
							includePrices={includePrices}
							includeItinerary={includeItinerary}
							includeRemarks={includeRemarks}
						/>
					</TabContent>
				</Tabs>
			</TabContent>
			{drivers.map((driver) => (
				<TabContent
					active={
						shareWithTab === "driver" &&
						shareVia === "whatsapp" &&
						shareWithDriver?.id === driver.id
					}
					key={`driver_${driver.id}_whatsapp`}
				>
					<ShareViaWhatsApp
						text={whatsappTextPerDriver[driver.id]}
						phoneNumber={driver.phone_number}
						trackingContentType={TrackingShareContentType}
						trackingContentId="driver"
					/>
				</TabContent>
			))}
		</Tabs>
	)
}

function getWhatsAppMessageContentForBooking(
	schedules: Array<TTripOperationalBooking>,
	{
		includePrices,
		includeItinerary,
		includeRemarks,
	}: {
		includePrices: boolean
		includeItinerary: boolean
		includeRemarks: boolean
	}
) {
	if (!schedules.length)
		return `There are not schedules you can share via bookings.`
	let totalPrice: Array<TMoney> = []
	return `Hi,
We have a new booking request with following details:
${schedules
	.map((trip) => {
		const schedules = trip.cab_schedules || []
		const daywise_services = trip.daywise_services
		const hasSameCabTypeForAll =
			!trip.travel_activity_bookings?.length &&
			collect(
				schedules.map((s) => {
					const { cabs } = s
					return cabs
						.map((c) => c.cab_type.id)
						.sort((a, b) => (a > b ? 1 : 0))
						.join("-")
				})
			)
				.unique()
				.count() === 1
		let cabTypeWithCount: Array<{ count: number; name: string }> = []
		if (hasSameCabTypeForAll) {
			const cabsCountByName = (trip.cab_schedules?.[0]?.cabs || []).reduce<{
				[cab: string]: number
			}>((cabTypeWithCount, cab) => {
				if (!cabTypeWithCount[cab.cab_type.display_name]) {
					cabTypeWithCount[cab.cab_type.display_name] = 0
				}
				cabTypeWithCount[cab.cab_type.display_name] += 1
				return cabTypeWithCount
			}, {})
			cabTypeWithCount = Object.keys(cabsCountByName).map((name) => ({
				name,
				count: cabsCountByName[name],
			}))
		}
		return `\`\`\`-----------\`\`\`
\`\`\`Trip Id   :\`\`\` ${trip.id}
\`\`\`Pax       :\`\`\` *${pluralize("Adult", trip.no_of_adults, true)}${
			trip.children.length
				? ` with ${pluralize(
						"Child",
						trip.children.length,
						true
					)}(${trip.children.map((c) => `${c.age}y`).join(",")})`
				: ``
		}*${
			hasSameCabTypeForAll
				? `\n\`\`\`Cab${
						cabTypeWithCount.length > 1 ? "s" : " "
					}      :\`\`\` ${cabTypeWithCount
						.map(({ count, name }) => `*${count}-${name}*`)
						.join(" + ")}`
				: ``
		}
\`\`\`-----------\`\`\`
${daywise_services
	.map(({ date, date_local, services }) => {
		return `🗓️ *${formatDate(localOrUtcTimestampToLocalDate(date_local, date), "dddd DD MMMM, YYYY")}*
----
${services
	.map((s) => {
		if (s.cab_schedule) {
			const schedule = s.cab_schedule
			const {
				cabs,
				transport_service,
				remarks,
				itinerary,
				start_date,
				start_date_local,
				start_time_local,
			} = schedule
			if (!hasSameCabTypeForAll) {
				const cabsCountByName = cabs.reduce<{ [cab: string]: number }>(
					(cabTypeWithCount, cab) => {
						if (!cabTypeWithCount[cab.cab_type.display_name]) {
							cabTypeWithCount[cab.cab_type.display_name] = 0
						}
						cabTypeWithCount[cab.cab_type.display_name] += 1
						return cabTypeWithCount
					},
					{}
				)
				cabTypeWithCount = Object.keys(cabsCountByName).map((name) => ({
					name,
					count: cabsCountByName[name],
				}))
			}
			const price = getBookingPriceForCabs(cabs)
			totalPrice = addMoneyFromDifferentCurrencies(totalPrice.concat(price))
			return `\`\`\`-\`\`\` *${transport_service.from_to}*${
				showPickup(start_date, start_time_local, start_date_local)
					? ` *(Pickup at ${formatDate(start_time_local ? parseDate(start_time_local, "HH:mm:ss") : utcTimestampToLocalDate(start_date), "HH:mm [hrs]")})*`
					: ``
			}
\`\`\` |\`\`\` ${transport_service.service_display_name}${
				remarks && includeRemarks
					? `
\`\`\` •\`\`\` *Remarks/Notes*
${remarks
	.split("\n")
	.map((c) => `\`\`\` |\`\`\` ${c}`)
	.join("\n")}`
					: ``
			}${
				!hasSameCabTypeForAll
					? `
\`\`\` :\`\`\` ${cabTypeWithCount
							.map(({ name, count }) => `*${count}-${name}*`)
							.join(" + ")}${
							includePrices && price.length
								? ` (${price.map((p) => formatMoneyByIntl(p)).join(" + ")} /-)`
								: ``
						}`
					: includePrices && price.length
						? `
\`\`\` :\`\`\` ${price.map((p) => formatMoneyByIntl(p)).join(" + ")} /-`
						: ``
			}${includeItinerary && itinerary?.description ? `\n${itinerary.description}` : ``}`
		}
		if (s.travel_activity_booking) {
			const travelActivityBooking = s.travel_activity_booking
			const {
				start_time_formatted,
				activity,
				ticket_type,
				duration_formatted,
				ticket_tourist_configurations_full_name,
				booked_price_money,
				remarks,
				itinerary,
			} = travelActivityBooking
			totalPrice = addMoneyFromDifferentCurrencies(
				totalPrice.concat([booked_price_money])
			)
			return `\`\`\`-\`\`\` *${activity.name}${ticket_type ? ` - ${ticket_type.display_name}` : ``}*
\`\`\` |\`\`\` *${ticket_tourist_configurations_full_name}*${start_time_formatted || duration_formatted ? ` *(${[start_time_formatted, duration_formatted].filter(Boolean).join(" - ")})*` : ``}${
				remarks && includeRemarks
					? `
\`\`\` •\`\`\` *Remarks/Notes*
${remarks
	.split("\n")
	.map((c) => `\`\`\` |\`\`\` ${c}`)
	.join("\n")}`
					: ``
			}${
				includePrices && !isZeroMoney(booked_price_money)
					? `
\`\`\` :\`\`\` ${formatMoneyByIntl(booked_price_money, { showCurrency: true })} /-`
					: ``
			}${includeItinerary && itinerary?.description ? `\n${itinerary.description}` : ``}`
		}
		return ``
	})
	.join("\n\n")}`
	})
	.join("\n\n----\n")}`
	})
	.join("\n\n")}${
		includePrices && totalPrice.length
			? `

-------
*Total Price: ${totalPrice.map((p) => formatMoneyByIntl(p, { showCurrency: true })).join(" + ")} /-*`
			: ``
	}

*Please confirm this booking.*
`
}

function EmailContentForBooking({
	bookings,
	includePrices,
	includeItinerary,
	includeRemarks,
}: {
	bookings: Array<TTripOperationalBooking>
	includePrices: boolean
	includeItinerary: boolean
	includeRemarks: boolean
}) {
	let totalPrice: Array<TMoney> = []
	const tenantBrand = bookings[0]?.tenant_brand
	return (
		<>
			<p>Hi,</p>
			{!tenantBrand ? null : (
				<>
					<br />
					<p>
						Greetings from{" "}
						<b>
							{tenantBrand.short_name}
							{tenantBrand.name
								.toLowerCase()
								.indexOf(tenantBrand.short_name.toLowerCase()) === -1
								? ` (${tenantBrand.name})`
								: null}
						</b>
						.
					</p>
				</>
			)}
			<br />
			<p>We have a new booking request with following details:</p>
			<br />
			<table>
				{bookings.map((trip, index) => {
					const cab_schedules = trip.cab_schedules || []
					const hasSameCabTypeForAll =
						!trip.travel_activity_bookings?.length &&
						collect(
							cab_schedules.map((s) => {
								const { cabs } = s
								return cabs
									.map((c) => c.cab_type.id)
									.sort((a, b) => (a > b ? 1 : 0))
									.join("-")
							})
						)
							.unique()
							.count() === 1
					let cabTypeWithCount: Array<{ count: number; name: string }> = []
					if (hasSameCabTypeForAll) {
						const cabsCountByName = cab_schedules[0].cabs.reduce<{
							[cab: string]: number
						}>((cabTypeWithCount, cab) => {
							if (!cabTypeWithCount[cab.cab_type.display_name]) {
								cabTypeWithCount[cab.cab_type.display_name] = 0
							}
							cabTypeWithCount[cab.cab_type.display_name] += 1
							return cabTypeWithCount
						}, {})
						cabTypeWithCount = Object.keys(cabsCountByName).map((name) => ({
							name,
							count: cabsCountByName[name],
						}))
					}
					const cols = 3 + (includePrices ? 1 : 0)
					return (
						<tbody key={trip.id}>
							{index > 0 ? (
								<>
									<tr>
										<td colSpan={cols}></td>
									</tr>
									<tr>
										<td colSpan={cols}>+++++++++++++++++++++++++</td>
									</tr>
								</>
							) : null}
							<tr>
								<td>Trip Id</td>
								<td colSpan={cols - 1 - (hasSameCabTypeForAll ? 1 : 0)}>Pax</td>
								{hasSameCabTypeForAll ? (
									<td>{pluralize("Cab", cabTypeWithCount.length)}</td>
								) : null}
							</tr>
							<tr>
								<td>{trip.id}</td>
								<th colSpan={cols - 1 - (hasSameCabTypeForAll ? 1 : 0)}>
									{pluralize("Adult", trip.no_of_adults, true)}
									{trip.children.length
										? ` with ${pluralize(
												"Child",
												trip.children.length,
												true
											)} (${trip.children.map((c) => `${c.age}y`).join(",")})`
										: ``}
								</th>
								{hasSameCabTypeForAll ? (
									<td>
										<b>
											{cabTypeWithCount
												.map(({ name, count }) => `${count}-${name}`)
												.join(" + ")}
										</b>
									</td>
								) : null}
							</tr>
							<tr>
								<td colSpan={cols}>
									<small>Here are the service details:</small>
								</td>
							</tr>
							<tr key="heading">
								<th>Date</th>
								<th colSpan={2}>Service</th>
								{includePrices ? (
									<th style={{ textAlign: "right" }}>Price</th>
								) : null}
							</tr>
							{trip.daywise_services.map(
								({ date, date_local, services }, i) => {
									return (
										<Fragment key={i}>
											{services.map((s, index) => {
												const dateRow =
													index === 0 ? (
														<tr>
															<td rowSpan={services.length + 1}>
																<b>
																	{formatDate(
																		localOrUtcTimestampToLocalDate(
																			date_local,
																			date
																		),
																		"DD MMM"
																	)}
																</b>
																<br />
																<small style={{ whiteSpace: "nowrap" }}>
																	{formatDate(
																		localOrUtcTimestampToLocalDate(
																			date_local,
																			date
																		),
																		"ddd YYYY"
																	)}
																</small>
															</td>
														</tr>
													) : null
												if (s.cab_schedule) {
													const schedule = s.cab_schedule
													const {
														cabs,
														start_date,
														start_date_local,
														start_time_local,
														transport_service,
														remarks,
														itinerary,
													} = schedule
													if (!hasSameCabTypeForAll) {
														const cabsCountByName = cabs.reduce<{
															[cab: string]: number
														}>((cabTypeWithCount, cab) => {
															if (
																!cabTypeWithCount[cab.cab_type.display_name]
															) {
																cabTypeWithCount[cab.cab_type.display_name] = 0
															}
															cabTypeWithCount[cab.cab_type.display_name] += 1
															return cabTypeWithCount
														}, {})
														cabTypeWithCount = Object.keys(cabsCountByName).map(
															(name) => ({ name, count: cabsCountByName[name] })
														)
													}
													const price = getBookingPriceForCabs(cabs)
													totalPrice = addMoneyFromDifferentCurrencies(
														totalPrice.concat(price)
													)
													return (
														<Fragment key={schedule.id}>
															{dateRow}
															<tr>
																<td colSpan={hasSameCabTypeForAll ? 2 : 1}>
																	<b>
																		{transport_service.from_to} -{" "}
																		{transport_service.service_display_name}
																	</b>
																	{showPickup(
																		start_date,
																		start_time_local,
																		start_date_local
																	) ? (
																		<>
																			<br />
																			<b>
																				<small>
																					{formatDate(
																						start_time_local
																							? parseDate(
																									start_time_local,
																									"HH:mm:ss"
																								)
																							: utcTimestampToLocalDate(
																									start_date
																								),
																						"HH:mm [hrs]"
																					)}
																				</small>
																			</b>
																		</>
																	) : null}
																	{remarks && includeRemarks ? (
																		<>
																			<br />
																			<p
																				style={{
																					fontWeight: "bold",
																					borderLeft: "2px solid silver",
																					paddingLeft: "4px",
																				}}
																			>
																				<small
																					style={{
																						whiteSpace: "pre-line",
																					}}
																				>
																					{remarks}
																				</small>
																			</p>
																		</>
																	) : null}
																	{includeItinerary &&
																	itinerary?.description ? (
																		<Markdown className="itinerary">
																			{itinerary.description}
																		</Markdown>
																	) : null}
																</td>
																{!hasSameCabTypeForAll ? (
																	<td>
																		{cabTypeWithCount
																			.map(
																				({ name, count }) => `${count}-${name}`
																			)
																			.join(" + ")}
																	</td>
																) : null}
																{includePrices ? (
																	<td style={{ textAlign: "right" }}>
																		{price.length
																			? `${price.map((p) => formatMoneyByIntl(p, { showCurrency: true })).join(" + ")}`
																			: null}
																	</td>
																) : null}
															</tr>
														</Fragment>
													)
												}

												if (s.travel_activity_booking) {
													const travelActivityBooking =
														s.travel_activity_booking
													const {
														activity,
														ticket_type,
														ticket_tourist_configurations_full_name,
														start_time_formatted,
														duration_formatted,
														booked_price_money,
														remarks,
														itinerary,
													} = travelActivityBooking
													const price = booked_price_money
													totalPrice = addMoneyFromDifferentCurrencies(
														totalPrice.concat(price)
													)
													return (
														<Fragment key={travelActivityBooking.id}>
															{dateRow}
															<tr>
																<td>
																	<b>
																		{activity.name}
																		{ticket_type
																			? ` - ${ticket_type.display_name}`
																			: null}
																	</b>
																	{start_time_formatted ||
																	duration_formatted ? (
																		<>
																			<br />
																			<b>
																				<small>
																					{[
																						start_time_formatted,
																						duration_formatted,
																					]
																						.filter(Boolean)
																						.join(" - ")}
																				</small>
																			</b>
																		</>
																	) : null}
																	{remarks && includeRemarks ? (
																		<>
																			<br />
																			<p
																				style={{
																					fontWeight: "bold",
																					borderLeft: "2px solid silver",
																					paddingLeft: "4px",
																				}}
																			>
																				<small
																					style={{
																						whiteSpace: "pre-line",
																					}}
																				>
																					{remarks}
																				</small>
																			</p>
																		</>
																	) : null}
																	{includeItinerary &&
																	itinerary?.description ? (
																		<Markdown className="itinerary">
																			{itinerary.description}
																		</Markdown>
																	) : null}
																</td>
																<td>
																	{ticket_tourist_configurations_full_name}
																</td>
																{includePrices ? (
																	<td style={{ textAlign: "right" }}>
																		{price
																			? `${formatMoneyByIntl(price, { showCurrency: true })}`
																			: null}
																	</td>
																) : null}
															</tr>
														</Fragment>
													)
												}
												return null
											})}
										</Fragment>
									)
								}
							)}
						</tbody>
					)
				})}
			</table>
			{includePrices && totalPrice.length ? (
				<>
					<br />
					<p>
						<b>
							Total Price:{" "}
							{totalPrice
								.map((p) => formatMoneyByIntl(p, { showCurrency: true }))
								.join(" + ")}{" "}
							/-
						</b>
					</p>
				</>
			) : null}
			<br />
			<p>
				<b>Please confirm this booking.</b>
			</p>
		</>
	)
}

function getWhatsAppMessageContentForGuestPerTrip(
	schedules: Array<TTripOperationalBooking>,
	{
		includeItinerary,
		includeRemarks,
	}: { includeItinerary: boolean; includeRemarks: boolean }
) {
	if (schedules[0]?.travel_activity_bookings?.length) {
		return getWhatsAppMessageContentForGuestPerTripForActivities(schedules, {
			includeItinerary,
			includeRemarks,
		})
	}
	return schedules.reduce<{ [TripId: string]: string }>(
		(messagePerTrip, trip) => {
			const { id, contact, no_of_adults, children } = trip
			const schedulesPerTrip = trip.daywise_services || []
			const schedulesPerDriverCabs = schedulesPerTrip.reduce<
				Array<{
					id: string
					driversAndCabs: Array<{
						driver: TDriver
						cab?: ICab
						cab_type: ICabType
					}>
					daywiseServices: Array<
						Omit<
							TTripOperationalBooking["daywise_services"][number],
							"services"
						> & {
							services: Array<
								Required<
									TTripOperationalBooking["daywise_services"][number]["services"][number]
								>["cab_schedule"]
							>
						}
					>
				}>
			>((schedulesPerDriverCabs, scheduleData) => {
				const driversAndCabs = collect(
					scheduleData.services
						.flatMap((s) => (s.cab_schedule ? s.cab_schedule.cabs || [] : []))
						.filter((c): c is Required<IScheduledCab, "driver"> =>
							Boolean(c.driver)
						)
						.map((c) => ({
							driver: c.driver,
							cab: c.cab,
							cab_type: c.cab_type,
						}))
				)
					.unique(
						(c) =>
							`d${c.driver.id}-${c.cab ? `c${c.cab.id}` : `ct${c.cab_type.id}`}`
					)
					.toArray()
				if (!driversAndCabs.length) return schedulesPerDriverCabs
				const id = driversAndCabs
					.reduce<
						[drivers: Array<{ id: number }>, cabs: Array<{ id: number }>]
					>(
						(data, d) => {
							data[0].push(d.driver)
							data[1].push(d.cab || d.cab_type)
							return data
						},
						[[], []]
					)
					.map((d) =>
						d
							.sort((a, b) => (a.id > b.id ? 1 : 0))
							.map((a) => a.id)
							.join(",")
					)
					.join("-")
				const existingDataIndex = schedulesPerDriverCabs.findIndex(
					(d) => d.id === id
				)
				const data = {
					...scheduleData,
					services: scheduleData.services
						.map((s) =>
							s.cab_schedule
								? {
										...s.cab_schedule,
										cabs: s.cab_schedule.cabs.filter((c) => {
											const { driver, cab_type, cab } = c
											if (!driver) return false
											return driversAndCabs.filter(
												(c) =>
													c.driver.id === driver.id &&
													(c.cab
														? c.cab.id === cab?.id
														: c.cab_type.id === cab_type.id)
											)
										}),
									}
								: undefined
						)
						.filter((s): s is Exclude<typeof s, undefined> => Boolean(s))
						.filter((s) => s.cabs.length),
				}
				if (existingDataIndex === -1) {
					schedulesPerDriverCabs.push({
						id,
						driversAndCabs,
						daywiseServices: [data],
					})
				} else {
					schedulesPerDriverCabs[existingDataIndex].daywiseServices.push(data)
				}
				return schedulesPerDriverCabs
			}, [])
			if (!schedulesPerDriverCabs.length) {
				messagePerTrip[id] = `Missing driver and cab details.
Please assign driver/cabs to the schedule(s) to share.
`
				return messagePerTrip
			}
			messagePerTrip[id] = `Dear ${contact.name},
Here are the tour operational details for your *Trip Id ${id}*.

\`\`\`Pax Details :\`\`\` *${pluralize("Adult", no_of_adults, true)}*${
				children.length
					? ` with *${pluralize("Child", children.length, true)} (${children
							.map((c) => `${c.age}y`)
							.join(",")})*`
					: ""
			}

${schedulesPerDriverCabs
	.map(({ driversAndCabs, daywiseServices }) => {
		return `*Driver/Cab*
${driversAndCabs
	.map(
		({ driver, cab, cab_type }) =>
			`\`\`\`-\`\`\` *${driver.name}*${
				driver.phone_number ? ` (${driver.phone_number})` : ``
			}
\`\`\` :\`\`\` *${
				cab ? `${cab.name} - ${cab.number_plate}` : `${cab_type.display_name}`
			}*`
	)
	.join("\n")}
_will handle following ${pluralize("service", schedules.length)}:_

${daywiseServices
	.map(
		(
			{
				date,
				date_local,
				checkin_hotels,
				checkout_hotels,
				arrival_details,
				departure_details,
				stay_hotels,
				services,
			},
			index
		) => {
			const whatsappForHotels = getWhatsAppContentForHotels(
				{ checkin_hotels, checkout_hotels, stay_hotels },
				index > 0 ? daywiseServices[index - 1] : undefined
			)
			return `🗓️ *${withOrdinalSuffix(
				getDiff(
					localOrUtcTimestampToLocalDate(date_local, date),
					localOrUtcTimestampToLocalDate(
						trip.start_date_local,
						trip.start_date
					),
					"days"
				) + 1
			)} Day* (${formatDate(localOrUtcTimestampToLocalDate(date_local, date), "ddd DD MMM")})${
				arrival_details?.length
					? `
*Arrival Details:*
${arrival_details
	.map(
		(d) =>
			`\`\`\`- ${formatDate(
				localOrUtcTimestampToLocalDate(d.date_time_local, d.date_time),
				"ddd DD MMM [at] HH:mm [hrs]"
			)}\`\`\`\n_${d.description}_`
	)
	.join("\n")}\n`
					: ``
			}
${services
	.map((cab_schedule) => {
		const {
			start_date,
			start_date_local,
			start_time_local,
			transport_service,
			remarks,
			itinerary,
		} = cab_schedule
		return `\`\`\`-\`\`\` *${transport_service.from_to}* - ${transport_service.service_display_name}${
			showPickup(start_date, start_time_local, start_date_local)
				? `
\`\`\` :\`\`\` *Pickup at ${formatDate(
						start_time_local
							? parseDate(start_time_local, "HH:mm:ss")
							: utcTimestampToLocalDate(start_date),
						"HH:mm [hrs]"
					)}*`
				: ``
		}${
			remarks && includeRemarks
				? `
\`\`\` •\`\`\` *Remarks/Notes*
${remarks
	.split("\n")
	.map((c) => `\`\`\` |\`\`\` ${c}`)
	.join("\n")}`
				: ``
		}${includeItinerary && itinerary?.description ? `\n${itinerary.description}` : ``}`
	})
	.join("\n")}${
				whatsappForHotels
					? `

${whatsappForHotels}
`
					: ``
			}${
				departure_details?.length
					? `

*Departure Details:*
${departure_details
	.map(
		(d) =>
			`\`\`\`- ${formatDate(
				localOrUtcTimestampToLocalDate(d.date_time_local, d.date_time),
				"ddd DD MMM [at] HH:mm [hrs]"
			)}\`\`\`\n_${d.description}_`
	)
	.join("\n")}`
					: ``
			}`
		}
	)
	.join("\n\n")}`
	})
	.join(`\n\`\`\`------------\`\`\`\n\n`)}
\`\`\`------------\`\`\`
_For any queries, please contact the trip coordinator._`
			return messagePerTrip
		},
		{}
	)
}

function getWhatsAppMessageContentForGuestPerTripForActivities(
	schedules: Array<TTripOperationalBooking>,
	{
		includeItinerary,
		includeRemarks,
	}: { includeItinerary: boolean; includeRemarks: boolean }
) {
	return schedules.reduce<{ [TripId: string]: string }>(
		(messagePerTrip, trip) => {
			const { id, contact, no_of_adults, children, daywise_services } = trip
			const daywise_booked_services = daywise_services
				.map((d) => ({
					...d,
					services: d.services
						.map((d) => ({
							...d,
							cab_schedule: d.cab_schedule
								? {
										...d.cab_schedule,
										cabs: d.cab_schedule.cabs.filter(
											(d): d is Required<typeof d, "driver" | "booked"> =>
												Boolean(d.booked && d.driver)
										),
									}
								: undefined,
							travel_activity_booking: d.travel_activity_booking?.is_booked
								? d.travel_activity_booking
								: undefined,
						}))
						.filter(
							(d) =>
								d.travel_activity_booking ||
								(d.cab_schedule && d.cab_schedule.cabs.length)
						),
				}))
				.filter((d) => d.services.length)
			if (!daywise_booked_services.length) {
				messagePerTrip[id] = `Missing driver and cab details.
Please assign driver/cabs to the schedule(s) to share.
`
				return messagePerTrip
			}
			messagePerTrip[id] = `Dear ${contact.name},
Here are the tour operational details for your *Trip Id ${id}*.

\`\`\`Pax Details :\`\`\` *${pluralize("Adult", no_of_adults, true)}*${
				children.length
					? ` with *${pluralize("Child", children.length, true)} (${children
							.map((c) => `${c.age}y`)
							.join(",")})*`
					: ""
			}

${daywise_booked_services
	.map(
		(
			{
				date,
				date_local,
				services,
				arrival_details,
				departure_details,
				checkin_hotels,
				checkout_hotels,
				stay_hotels,
			},
			index
		) => {
			const whatsappForHotels = getWhatsAppContentForHotels(
				{ checkin_hotels, checkout_hotels, stay_hotels },
				index > 0 ? daywise_booked_services[index - 1] : undefined
			)
			return `🗓️ *${withOrdinalSuffix(
				getDiff(
					localOrUtcTimestampToLocalDate(date_local, date),
					localOrUtcTimestampToLocalDate(
						trip.start_date_local,
						trip.start_date
					),
					"days"
				) + 1
			)} Day* - *${formatDate(localOrUtcTimestampToLocalDate(date_local, date), "ddd DD MMM, YYYY")}*
---${
				arrival_details?.length
					? `
*Arrival Details:*
${arrival_details
	.map(
		(d) =>
			`\`\`\`- ${formatDate(
				localOrUtcTimestampToLocalDate(d.date_time_local, d.date_time),
				"ddd DD MMM [at] HH:mm [hrs]"
			)}\`\`\`\n_${d.description}_`
	)
	.join("\n")}\n`
					: ``
			}
${services
	.map(({ cab_schedule, travel_activity_booking }) => {
		if (cab_schedule) {
			const {
				transport_service,
				cabs,
				start_date,
				start_date_local,
				start_time_local,
				remarks,
				itinerary,
			} = cab_schedule
			return `\`\`\`-\`\`\` *${transport_service.from_to}* - ${transport_service.service_display_name}${
				showPickup(start_date, start_time_local, start_date_local)
					? `
\`\`\` :\`\`\` *Pickup at ${formatDate(
							start_time_local
								? parseDate(start_time_local, "HH:mm:ss")
								: utcTimestampToLocalDate(start_date),
							"HH:mm [hrs]"
						)}*`
					: ``
			}
${cabs
	.map(
		({ driver, cab, cab_type, confirmation_details }) =>
			`\`\`\` -\`\`\` *${driver.name}*${
				driver.phone_number ? ` (${driver.phone_number})` : `*`
			}
\`\`\` :\`\`\` *${
				cab ? `${cab.name} - ${cab.number_plate}` : `${cab_type.display_name}`
			}*${
				confirmation_details
					? `
${confirmation_details
	.split("\n")
	.map((c) => `\`\`\` |\`\`\` ${c}`)
	.join("\n")}`
					: ``
			}`
	)
	.join("\n")}${
				remarks && includeRemarks
					? `
\`\`\` •\`\`\` *Remarks/Notes*
${remarks
	.split("\n")
	.map((c) => `\`\`\` |\`\`\` ${c}`)
	.join("\n")}`
					: ``
			}${includeItinerary && itinerary?.description ? `\n${itinerary.description}` : ``}`
		}
		if (travel_activity_booking) {
			const {
				activity,
				ticket_type,
				supplier_contact,
				ticket_tourist_configurations_full_name,
				start_time_formatted,
				duration_formatted,
				confirmation_details,
				remarks,
				itinerary,
			} = travel_activity_booking
			return `\`\`\`-\`\`\` *${activity.name}${ticket_type ? ` - ${ticket_type.display_name}` : ""}*${
				start_time_formatted || duration_formatted
					? `
\`\`\` :\`\`\` *${[start_time_formatted, duration_formatted].filter(Boolean).join(" - ")}*`
					: ``
			}
\`\`\` :\`\`\` *${ticket_tourist_configurations_full_name}*${
				confirmation_details
					? `
${confirmation_details
	.split("\n")
	.map((c) => `\`\`\` |\`\`\` ${c}`)
	.join("\n")}`
					: ``
			}${
				remarks && includeRemarks
					? `
\`\`\` •\`\`\` *Remarks/Notes*
${remarks
	.split("\n")
	.map((c) => `\`\`\` |\`\`\` ${c}`)
	.join("\n")}`
					: ``
			}${
				supplier_contact
					? `
\`\`\` :\`\`\` *Contact Person*
\`\`\`  \`\`\` ${supplier_contact.name} (${supplier_contact.phone_number})`
					: ``
			}${includeItinerary && itinerary?.description ? `\n${itinerary.description}` : ``}`
		}
		return ``
	})
	.filter(Boolean)
	.join("\n\n")}${
				whatsappForHotels
					? `

${whatsappForHotels}
`
					: ``
			}${
				departure_details?.length
					? `

*Departure Details:*
${departure_details
	.map(
		(d) =>
			`\`\`\`- ${formatDate(
				localOrUtcTimestampToLocalDate(d.date_time_local, d.date_time),
				"ddd DD MMM [at] HH:mm [hrs]"
			)}\`\`\`\n_${d.description}_`
	)
	.join("\n")}`
					: ``
			}`
		}
	)
	.join(`\n\`\`\`------------\`\`\`\n\n`)}
\`\`\`------------\`\`\`
_For any queries, please contact the trip coordinator._`
			return messagePerTrip
		},
		{}
	)
}

function getWhatsAppMessageContentForDrivers(
	bookings: Array<TTripOperationalBooking>,
	{
		itinerary: includeItinerary,
		remarks: includeRemarks,
	}: Omit<TInclusionOptions, "prices">
) {
	const drivers: Array<TDriver> = []
	const schedules = bookings.flatMap(({ daywise_services, ...t }) =>
		(daywise_services || []).flatMap(({ services, ...others }) =>
			services
				.map((s) => s.cab_schedule)
				.filter((s): s is ICabSchedule => Boolean(s))
				.map((schedule) => ({
					...others,
					...schedule,
					trip: t,
				}))
		)
	)
	schedules.forEach(function (s) {
		s.cabs.forEach((cab) => {
			const { driver } = cab
			if (driver && drivers.findIndex((d) => d.id === driver.id) === -1) {
				drivers.push(driver)
			}
		})
	})
	const schedulesPerDriver = drivers.map((driver) => {
		return {
			driver,
			schedules: schedules
				.map((s) => ({
					...s,
					cabs: s.cabs.filter((cab) => cab.driver?.id === driver.id),
				}))
				.filter((s) => s.cabs.length),
		}
	})
	return schedulesPerDriver
		.map(function getMessagePerDriver({
			driver,
			schedules,
		}): [driver: TDriver, message: string] {
			const groupedByTrip = collect(schedules)
				.groupBy((s) => s.trip_id)
				.toArray()
			return [
				driver,
				`Dear ${driver.name},
Here are the details of your upcoming ${pluralize("service", schedules.length)}.

${groupedByTrip
	.map((schedules) => {
		const schedule = schedules[0]
		const { trip } = schedule
		const hasSameCabsForAll =
			collect(
				schedules
					.map((s) => {
						const { cabs } = s
						return cabs
							.reduce<Array<string>>((cabs, cab) => {
								if (cab.cab) {
									cabs.push(`${cab.cab.id}C`)
								} else {
									cabs.push(`${cab.cab_type.id}CT`)
								}
								return cabs
							}, [])
							.filter(Boolean)
							.sort()
							.join("_")
					})
					.filter(Boolean)
			)
				.unique()
				.count() === 1
		const sameCabsForAll = schedule.cabs
		return `\`\`\`-----------\`\`\`
\`\`\`Trip Id :\`\`\` ${trip.id}
\`\`\`Guest   :\`\`\` *${trip.tourist?.name || "N/A"}*${
			trip.tourist?.phone_numbers?.length
				? `
\`\`\`        |\`\`\` ${trip.tourist.phone_numbers
						.map((p) => p.phone_number)
						.join(", ")}`
				: ``
		}
\`\`\`Pax     :\`\`\` *${pluralize("Adult", trip.no_of_adults, true)}${
			trip.children.length
				? ` with ${pluralize(
						"Child",
						trip.children.length,
						true
					)} (${trip.children.map((c) => `${c.age}y`).join(",")})`
				: ``
		}*${
			hasSameCabsForAll
				? `\n\`\`\`Cab     :\`\`\` ${sameCabsForAll
						.map(
							({ cab, cab_type }) =>
								`*${cab?.full_name || cab_type.display_name}*`
						)
						.join(`\n\`\`\`        |\`\`\` `)}`
				: ``
		}

${schedules
	.map((schedule, index, schedules) => {
		const {
			cabs,
			start_date,
			start_time_local,
			start_date_local,
			transport_service,
			checkin_hotels,
			checkout_hotels,
			stay_hotels,
			arrival_details,
			departure_details,
			remarks,
			itinerary,
		} = schedule
		const whatsappForHotels = getWhatsAppContentForHotels(
			{ checkout_hotels, checkin_hotels, stay_hotels },
			index > 0 ? schedules[index - 1] : undefined
		)
		return `---------------------------------
*${formatDate(localOrUtcTimestampToLocalDate(start_date_local, start_date), "ddd DD MMM, YYYY")}*${
			showPickup(start_date, start_time_local, start_date_local)
				? ` *(Pickup at ${formatDate(start_time_local ? parseDate(start_time_local, "HH:mm:ss") : utcTimestampToLocalDate(start_date), "HH:mm [hrs]")})*`
				: ``
		}${
			arrival_details?.length
				? `

*Guest Arrival Details:*
${arrival_details
	.map(
		(d) =>
			`\`\`\`- ${formatDate(
				localOrUtcTimestampToLocalDate(d.date_time_local, d.date_time),
				"ddd DD MMM [at] HH:mm [hrs]"
			)}\`\`\`\n_${d.description}_`
	)
	.join("\n")}`
				: ``
		}

\`\`\`-\`\`\` *${transport_service.from_to}*
\`\`\` |\`\`\` ${transport_service.service_display_name}${
			remarks && includeRemarks
				? `
\`\`\` •\`\`\` *Remarks/Notes*
${remarks
	.split("\n")
	.map((c) => `\`\`\` |\`\`\` ${c}`)
	.join("\n")}`
				: ``
		}${
			!hasSameCabsForAll
				? `
\`\`\`  :\`\`\` ${cabs
						.map((cab) =>
							[cab.cab?.full_name || cab.cab_type.display_name]
								.filter(Boolean)
								.join(" • ")
						)
						.join(`\n\`\`\`  +\`\`\` `)}`
				: ``
		}${includeItinerary && itinerary?.description ? `\n${itinerary.description}` : ``}${
			whatsappForHotels
				? `

${whatsappForHotels}`
				: ``
		}${
			departure_details?.length
				? `

*Guest Departure Details:*
${departure_details
	.map(
		(d) =>
			`\`\`\`- ${formatDate(
				localOrUtcTimestampToLocalDate(d.date_time_local, d.date_time),
				"ddd DD MMM [at] HH:mm [hrs]"
			)}\`\`\`\n_${d.description}_`
	)
	.join("\n")}`
				: ``
		}`
	})
	.join("\n\n")}`
	})
	.join("\n\n")}`,
			]
		})
		.reduce<{ [driverId: string]: string }>(
			(messageByDriver, [driver, message]) => {
				messageByDriver[driver.id] = message
				return messageByDriver
			},
			{}
		)
}

function getTransportServiceProvidersWithBookings(
	bookings: Array<TTripOperationalBooking>
) {
	const transportServiceProviders: Array<ITransportServiceProvider> = []
	bookings.forEach(function ({ daywise_services }) {
		daywise_services.forEach(({ services }) => {
			services.forEach(({ cab_schedule, travel_activity_booking }) => {
				if (cab_schedule) {
					cab_schedule.cabs.forEach((cab) => {
						const { transport_service_provider } = cab
						if (
							transport_service_provider &&
							transportServiceProviders.findIndex(
								(d) => d.id === transport_service_provider.id
							) === -1
						) {
							transportServiceProviders.push(transport_service_provider)
						}
					})
				}
				if (travel_activity_booking) {
					const { supplier } = travel_activity_booking
					if (
						supplier &&
						transportServiceProviders.findIndex((d) => d.id === supplier.id) ===
							-1
					) {
						transportServiceProviders.push(supplier)
					}
				}
			})
		})
	})

	const schedulesPerTsp = transportServiceProviders.map((tsp) => {
		return {
			transport_service_provider: tsp,
			bookings: bookings
				.map(({ cab_schedules, travel_activity_bookings, ...b }) => ({
					...b,
					daywise_services: b.daywise_services
						.map((d) => ({
							...d,
							services: d.services
								.map((s) => ({
									...s,
									cab_schedule: !s.cab_schedule
										? undefined
										: {
												...s.cab_schedule,
												cabs: s.cab_schedule.cabs.filter(
													(s) => s.transport_service_provider?.id === tsp.id
												),
											},
									travel_activity_booking:
										s.travel_activity_booking &&
										s.travel_activity_booking.supplier?.id === tsp.id
											? s.travel_activity_booking
											: undefined,
								}))
								.filter(
									(s) =>
										s.cab_schedule?.cabs?.length || s.travel_activity_booking
								),
						}))
						.filter((d) => d.services?.length),
				}))
				.filter((b) => b.daywise_services?.length),
		}
	})

	const schedulesByTspId = schedulesPerTsp.reduce<{
		[key: string]: (typeof schedulesPerTsp)[number]["bookings"]
	}>((byId, { transport_service_provider, bookings }) => {
		const id = transport_service_provider.id
		if (!byId[id]) {
			byId[id] = []
		}
		byId[id] = byId[id].concat(bookings)
		return byId
	}, {})
	return { transportServiceProviders, schedulesPerTsp, schedulesByTspId }
}

function getWhatsAppMessageContentForTransportServiceProviders(
	bookings: Array<TTripOperationalBooking>,
	{
		prices: includePrices,
		itinerary: includeItinerary,
		remarks: includeRemarks,
	}: TInclusionOptions
) {
	const { schedulesPerTsp } = getTransportServiceProvidersWithBookings(bookings)

	return schedulesPerTsp
		.map(function getMessagePerTsp({
			transport_service_provider,
			bookings,
		}): [tsp: ITransportServiceProvider, message: string] {
			let totalPrice: Array<TMoney> = []
			return [
				transport_service_provider,
				`Hi ${transport_service_provider.name},
Here are the details of your upcoming ${pluralize("service", bookings.length)}.

${bookings
	.map(({ daywise_services, ...trip }) => {
		const cab_schedules = daywise_services
			.flatMap((d) => d.services)
			.map((s) => s.cab_schedule)
			.filter((s): s is Exclude<typeof s, undefined> => Boolean(s))
		const hasActivities = daywise_services.some((d) =>
			d.services.some((s) => s.travel_activity_booking)
		)
		const schedule = cab_schedules[0]
		const hasSameCabsForAll =
			!hasActivities && getHasSameCabDriverForSchedules(cab_schedules)
		const sameCabsForAll = schedule?.cabs || []
		const hasDriverOrCabAssigned = sameCabsForAll.some((s) => s.driver || s.cab)
		let cabTypeWithCount: Array<{ count: number; name: string }> = []
		if (hasSameCabsForAll && !hasDriverOrCabAssigned) {
			const cabsCountByName = sameCabsForAll.reduce<{
				[cab: string]: number
			}>((cabTypeWithCount, cab) => {
				if (!cabTypeWithCount[cab.cab_type.display_name]) {
					cabTypeWithCount[cab.cab_type.display_name] = 0
				}
				cabTypeWithCount[cab.cab_type.display_name] += 1
				return cabTypeWithCount
			}, {})
			cabTypeWithCount = Object.keys(cabsCountByName).map((name) => ({
				name,
				count: cabsCountByName[name],
			}))
		}
		return `\`\`\`-----------\`\`\`
\`\`\`Trip Id :\`\`\` ${trip.id}
\`\`\`Guest   :\`\`\` *${trip.tourist?.name || "N/A"}*${
			trip.tourist?.phone_numbers?.length
				? `
\`\`\`        |\`\`\` ${trip.tourist.phone_numbers
						.map((p) => p.phone_number)
						.join(", ")}`
				: ``
		}
\`\`\`Pax     :\`\`\` *${pluralize("Adult", trip.no_of_adults, true)}${
			trip.children.length
				? ` with ${pluralize(
						"Child",
						trip.children.length,
						true
					)} (${trip.children.map((c) => `${c.age}y`).join(",")})`
				: ``
		}*${
			hasSameCabsForAll
				? `\n----\n*${hasDriverOrCabAssigned ? "Driver/Cab" : "Cabs"}*
${
	hasDriverOrCabAssigned
		? sameCabsForAll
				.map(
					({ cab, driver, cab_type }) =>
						`\`\`\`- \`\`\` *${cab?.full_name || cab_type.display_name}*${
							!driver
								? ``
								: `
\`\`\`  :\`\`\` _${driver?.name} (${driver?.phone_number})_`
						}`
				)
				.join(`\n`)
		: `\`\`\`- \`\`\` ${cabTypeWithCount
				.map((c) => `${c.count}-${c.name}`)
				.join(" • ")}`
}`
				: ``
		}

${daywise_services
	.map((schedule, index, schedules) => {
		const {
			date,
			date_local,
			services,
			checkin_hotels,
			checkout_hotels,
			stay_hotels,
			arrival_details,
			departure_details,
		} = schedule
		const whatsappForHotels = getWhatsAppContentForHotels(
			{ checkin_hotels, checkout_hotels, stay_hotels },
			index > 0 ? schedules[index - 1] : undefined
		)
		return `---------------------------------
🗓️ *${formatDate(localOrUtcTimestampToLocalDate(date_local, date), "dddd DD MMM, YYYY")}*
----${
			arrival_details?.length
				? `
*Guest Arrival Details:*
${arrival_details
	.map(
		(d) =>
			`\`\`\`- ${formatDate(
				localOrUtcTimestampToLocalDate(d.date_time_local, d.date_time),
				"ddd DD MMM [at] HH:mm [hrs]"
			)}\`\`\`\n_${d.description}_`
	)
	.join("\n")}`
				: ``
		}

${services
	.map(({ cab_schedule, travel_activity_booking }) => {
		if (cab_schedule) {
			const {
				cabs,
				start_date,
				start_date_local,
				start_time_local,
				transport_service,
				remarks,
				itinerary,
			} = cab_schedule
			const price = getBookingPriceForCabs(cabs)
			totalPrice = addMoneyFromDifferentCurrencies(totalPrice.concat(price))
			return `\`\`\`-\`\`\` *${transport_service.from_to}*${
				showPickup(start_date, start_time_local, start_date_local)
					? ` *(Pickup at ${formatDate(start_time_local ? parseDate(start_time_local, "HH:mm:ss") : utcTimestampToLocalDate(start_date), "HH:mm [hrs]")})*`
					: ``
			}
\`\`\` |\`\`\` ${transport_service.service_display_name}${
				remarks && includeRemarks
					? `
\`\`\` •\`\`\` *Remarks/Notes*
${remarks
	.split("\n")
	.map((c) => `\`\`\` |\`\`\` ${c}`)
	.join("\n")}`
					: ``
			}${
				includePrices && price.length
					? `
\`\`\` -\`\`\` *${price.map((p) => formatMoneyByIntl(p, { showCurrency: true })).join(" + ")}* /-`
					: ``
			}${
				!hasSameCabsForAll
					? `
\`\`\` :\`\`\` ${cabs
							.map((cab) =>
								[
									cab.cab
										? `*${cab.cab.full_name}*`
										: cab.cab_type.display_name,
									cab.driver
										? `${cab.driver.name} (${cab.driver.phone_number})`
										: ``,
								]
									.filter(Boolean)
									.join(`\n\`\`\` - \`\`\``)
							)
							.join(`\n\`\`\` +\`\`\` `)}`
					: ``
			}${includeItinerary && itinerary?.description ? `\n${itinerary.description}` : ``}`
		}
		if (travel_activity_booking) {
			const {
				activity,
				ticket_type,
				ticket_tourist_configurations_full_name,
				start_time_formatted,
				duration_formatted,
				booked_price_money,
				confirmation_details,
				remarks,
				itinerary,
			} = travel_activity_booking
			totalPrice = addMoneyFromDifferentCurrencies(
				totalPrice.concat([booked_price_money])
			)
			return `\`\`\`-\`\`\` *${activity.name}${ticket_type ? ` - ${ticket_type.display_name}` : ``}*${
				start_time_formatted || duration_formatted
					? ` *(${[start_time_formatted, duration_formatted].filter(Boolean).join(" - ")})*`
					: ``
			}
\`\`\` :\`\`\` *${ticket_tourist_configurations_full_name}*${
				confirmation_details
					? `
${confirmation_details
	.split("\n")
	.map((c) => `\`\`\` |\`\`\` ${c}`)
	.join("\n")}`
					: ``
			}${
				remarks && includeRemarks
					? `
\`\`\` •\`\`\` *Remarks/Notes*
${remarks
	.split("\n")
	.map((c) => `\`\`\` |\`\`\` ${c}`)
	.join("\n")}`
					: ``
			}${
				includePrices && booked_price_money?.amount
					? `
\`\`\` -\`\`\` *${formatMoneyByIntl(booked_price_money, { showCurrency: true })}* /-`
					: ``
			}${includeItinerary && itinerary?.description ? `\n${itinerary.description}` : ``}`
		}
		return ``
	})
	.join("\n\n")}${
			whatsappForHotels
				? `

${whatsappForHotels}
`
				: ``
		}${
			departure_details?.length
				? `

*Guest Departure Details:*
${departure_details
	.map(
		(d) =>
			`\`\`\`- ${formatDate(
				localOrUtcTimestampToLocalDate(d.date_time_local, d.date_time),
				"ddd DD MMM [at] HH:mm [hrs]"
			)}\`\`\`\n_${d.description}_`
	)
	.join("\n")}`
				: ``
		}`
	})
	.join("\n\n")}`
	})
	.join("\n\n")}${
					includePrices && totalPrice.length
						? `

*Total Price: ${totalPrice.map((p) => formatMoneyByIntl(p, { showCurrency: true })).join(" + ")} /-*`
						: ``
				}
`,
			]
		})
		.reduce<{ [tspId: string]: string }>((messageByTsp, [tsp, message]) => {
			messageByTsp[tsp.id] = message
			return messageByTsp
		}, {})
}

function EmailContentForTransportServiceProviders({
	bookings,
	selectedTsp,
	includePrices,
	includeItinerary,
	includeRemarks,
}: {
	bookings: Array<TTripOperationalBooking>
	selectedTsp?: ITransportServiceProvider
	includePrices: boolean
	includeItinerary: boolean
	includeRemarks: boolean
}) {
	const schedulesByTspId = useMemo(() => {
		return getTransportServiceProvidersWithBookings(bookings).schedulesByTspId
	}, [bookings])

	if (!selectedTsp) return null
	const schedulesForSelectedTsp = schedulesByTspId[selectedTsp.id]
	const schedules = schedulesForSelectedTsp
	let totalPrice: Array<TMoney> = []
	const tenantBrand = schedulesForSelectedTsp[0]?.tenant_brand
	return (
		<EmailShare
			shareWith="transport_service_provider"
			subject={
				<Text>
					Trip ID
					{": "}
					{collect(schedules.map((d) => d.id))
						.unique()
						.toArray()
						.join("_")}{" "}
					:: Transport Service Details to {selectedTsp.name}
				</Text>
			}
		>
			<>
				<Box as="p">Hi {selectedTsp.name},</Box>
				{!tenantBrand ? null : (
					<>
						<br />
						<Box as="p">
							Greetings from{" "}
							<b>
								{tenantBrand.short_name}
								{tenantBrand.name
									.toLowerCase()
									.indexOf(tenantBrand.short_name.toLowerCase()) === -1
									? ` (${tenantBrand.name})`
									: null}
							</b>
							.
						</Box>
					</>
				)}
				<br />
				<Box as="p">
					Here are the details of your upcoming{" "}
					{pluralize("Trips", schedules.length)}.
				</Box>
				<br />
				<table style={{ width: "100%" }}>
					{schedules.map(({ daywise_services, ...trip }, index) => {
						const cab_schedules = daywise_services
							.flatMap((d) => d.services)
							.map((s) => s.cab_schedule)
							.filter((s): s is Exclude<typeof s, undefined> => Boolean(s))
						const hasActivities = daywise_services.some((d) =>
							d.services.some((d) => d.travel_activity_booking)
						)
						const schedule = cab_schedules[0]
						const hasSameCabsForAll =
							!hasActivities && getHasSameCabDriverForSchedules(cab_schedules)
						const sameCabsForAll = schedule?.cabs || []
						const hasDriverOrCabAssigned = sameCabsForAll.some(
							(s) => s.driver || s.cab
						)
						let cabTypeWithCount: Array<{ count: number; name: string }> = []
						if (hasSameCabsForAll && !hasDriverOrCabAssigned) {
							const cabsCountByName = sameCabsForAll.reduce<{
								[cab: string]: number
							}>((cabTypeWithCount, cab) => {
								if (!cabTypeWithCount[cab.cab_type.display_name]) {
									cabTypeWithCount[cab.cab_type.display_name] = 0
								}
								cabTypeWithCount[cab.cab_type.display_name] += 1
								return cabTypeWithCount
							}, {})
							cabTypeWithCount = Object.keys(cabsCountByName).map((name) => ({
								name,
								count: cabsCountByName[name],
							}))
						}
						const cols = (hasSameCabsForAll ? 4 : 5) + (includePrices ? 1 : 0)
						const arrival_details: TArrivalDepartureDetail[] = []
						for (const dayDetails of daywise_services) {
							if (dayDetails.arrival_details) {
								dayDetails.arrival_details.forEach((s) => {
									if (!arrival_details.find((d) => d.id === s.id)) {
										arrival_details.push(s)
									}
								})
							}
						}
						const departure_details: TArrivalDepartureDetail[] = []
						for (const dayDetails of daywise_services) {
							if (dayDetails.departure_details) {
								dayDetails.departure_details.forEach((s) => {
									if (!departure_details.find((d) => d.id === s.id)) {
										departure_details.push(s)
									}
								})
							}
						}
						return (
							<tbody key={trip.id}>
								{index > 0 ? (
									<>
										<tr>
											<td colSpan={cols + 1}></td>
										</tr>
										<tr>
											<td colSpan={cols + 1}>+++++++++++++++++++++++++</td>
										</tr>
									</>
								) : null}
								<tr>
									<th>Trip Id</th>
									<th>Guest</th>
									<th>Contact No.</th>
									<th colSpan={cols - 2}>Pax</th>
								</tr>
								<tr>
									<td>{trip.id}</td>
									<th>
										{trip.tourist ? trip.tourist.name : "Name - To be updated"}
									</th>
									<td>
										{trip.tourist?.phone_numbers?.length
											? trip.tourist.phone_numbers.map((p, idx) => (
													<Fragment key={p.phone_number}>
														{idx >= 1 ? <span>, </span> : null}
														<a href={`tel:${p.phone_number}`}>
															{p.phone_number}
														</a>
													</Fragment>
												))
											: null}
									</td>
									<th colSpan={cols - 2}>
										{pluralize("Adult", trip.no_of_adults, true)}
										{trip.children.length
											? ` with ${pluralize(
													"Child",
													trip.children.length,
													true
												)} (${trip.children.map((c) => `${c.age}y`).join(",")})`
											: ``}
									</th>
								</tr>
								{arrival_details.length || departure_details.length ? (
									<>
										{arrival_details.map((d, index, arr) => (
											<tr key={d.id}>
												{index === 0 ? (
													<th rowSpan={arr.length}>Arrival Details</th>
												) : null}
												<td>
													{formatDate(
														localOrUtcTimestampToLocalDate(
															d.date_time_local,
															d.date_time
														),
														"ddd DD MMM [at] HH:mm [hrs]"
													)}
												</td>
												<td colSpan={cols - 1}>{d.description}</td>
											</tr>
										))}
										{departure_details.map((d, index, arr) => (
											<tr key={d.id}>
												{index === 0 ? (
													<th rowSpan={arr.length}>Departure Details</th>
												) : null}
												<td>
													{formatDate(
														localOrUtcTimestampToLocalDate(
															d.date_time_local,
															d.date_time
														),
														"ddd DD MMM [at] HH:mm [hrs]"
													)}
												</td>
												<td colSpan={cols - 1}>{d.description}</td>
											</tr>
										))}
									</>
								) : null}
								{hasSameCabsForAll ? (
									<>
										{!hasDriverOrCabAssigned ? (
											<tr>
												<th>Cab Details</th>
												<th colSpan={cols}>
													{cabTypeWithCount
														.map((c) => `${c.count}-${c.name}`)
														.join(", ")}
												</th>
											</tr>
										) : (
											<>
												<tr>
													<td colSpan={cols + 1} style={{ paddingTop: "16px" }}>
														<small>Cab and Driver Details</small>
													</td>
												</tr>
												<tr>
													<th>Sr.</th>
													<th>Cab</th>
													<th>Driver</th>
													<th colSpan={cols - 1}>Contact No.</th>
												</tr>
												{sameCabsForAll.map(
													({ id, cab, driver, cab_type }, index) => (
														<tr key={id}>
															<td>{index + 1}.</td>
															<td>
																{cab?.name || cab_type.display_name}
																{cab ? ` - ${cab?.number_plate}` : null}
															</td>
															<td>{driver?.name}</td>
															<td colSpan={cols - 1}>
																{driver?.phone_number ? (
																	<a href={`tel:${driver.phone_number}`}>
																		{driver.phone_number}
																	</a>
																) : null}
															</td>
														</tr>
													)
												)}
											</>
										)}
									</>
								) : null}
								<tr>
									<td colSpan={cols + 1} style={{ paddingTop: "16px" }}>
										<small>
											Here are the date-wise services with check-in/check-out
											hotel details
										</small>
									</td>
								</tr>
								<tr>
									<th>Date</th>
									<th colSpan={hasSameCabsForAll ? 2 : 1}>Service</th>
									{!hasSameCabsForAll ? (
										<>
											<th>Supplies</th>
										</>
									) : null}
									{includePrices ? (
										<th style={{ textAlign: "right" }}>Price</th>
									) : null}
									<th>Check-Out</th>
									<th>Check-In/Stay</th>
								</tr>
								{daywise_services.map((schedule, index) => {
									const {
										date,
										date_local,
										checkin_hotels,
										checkout_hotels,
										stay_hotels,
									} = schedule
									const rowsCount = schedule.services.reduce<number>(
										(totalRows, { cab_schedule, travel_activity_booking }) =>
											totalRows +
											(hasSameCabsForAll
												? cab_schedule
													? 1
													: 0
												: cab_schedule?.cabs.length || 0) +
											(travel_activity_booking ? 1 : 0),
										0
									)
									return (
										<React.Fragment key={index}>
											{schedule.services.map(function (
												{ cab_schedule, travel_activity_booking },
												daywiseServiceIndex
											) {
												const dateCell =
													daywiseServiceIndex === 0 ? (
														<td rowSpan={rowsCount}>
															<Box
																as="time"
																whiteSpace="preserve"
																dateTime={localOrUtcTimestampToLocalDate(
																	date_local,
																	date
																).toISOString()}
															>
																<b>
																	{formatDate(
																		localOrUtcTimestampToLocalDate(
																			date_local,
																			date
																		),
																		"DD MMM"
																	)}
																</b>
																<br />
																<small>
																	{formatDate(
																		localOrUtcTimestampToLocalDate(
																			date_local,
																			date
																		),
																		"ddd YYYY"
																	)}
																</small>
															</Box>
														</td>
													) : null
												const hotelCells =
													daywiseServiceIndex === 0 ? (
														<>
															{stay_hotels.length ? (
																<>
																	<td rowSpan={rowsCount}></td>
																	<td rowSpan={rowsCount}>
																		{stay_hotels.length
																			? stay_hotels.map((h) => (
																					<Box key={h.id}>
																						<small>
																							<b>{h.hotel.name}</b>
																						</small>
																						<br />
																						<small>
																							{h.hotel.location.short_name}
																						</small>
																					</Box>
																				))
																			: null}
																	</td>
																</>
															) : (
																<>
																	<td rowSpan={rowsCount}>
																		{checkout_hotels.length
																			? checkout_hotels.map((h) => (
																					<Box key={h.id}>
																						<small>
																							<b>{h.hotel.name}</b>
																						</small>
																						<br />
																						<small>
																							{h.hotel.location.short_name}
																						</small>
																					</Box>
																				))
																			: null}
																	</td>
																	<td rowSpan={rowsCount}>
																		{checkin_hotels.length
																			? checkin_hotels.map((h) => (
																					<Box key={h.id}>
																						<small>
																							<b>{h.hotel.name}</b>
																						</small>
																						<br />
																						<small>
																							{h.hotel.location.short_name}
																						</small>
																					</Box>
																				))
																			: null}
																	</td>
																</>
															)}
														</>
													) : null
												if (cab_schedule) {
													const {
														cabs,
														start_date,
														start_date_local,
														start_time_local,
														transport_service,
														remarks,
														itinerary,
													} = cab_schedule
													const price = getBookingPriceForCabs(cabs)
													totalPrice = addMoneyFromDifferentCurrencies(
														totalPrice.concat(price)
													)
													return (
														<React.Fragment
															key={`cab_schedule_${cab_schedule.id}`}
														>
															{cabs.map((cab, cabIndex) => {
																if (hasSameCabsForAll && cabIndex > 0)
																	return null
																const cabsRows = hasSameCabsForAll
																	? 1
																	: cabs.length
																return (
																	<tr key={cab.id}>
																		{cabIndex === 0 ? (
																			<>
																				{dateCell}
																				<td
																					rowSpan={cabsRows}
																					colSpan={hasSameCabsForAll ? 2 : 1}
																				>
																					<Box as="b" whiteSpace="preserve">
																						{transport_service.from_to} -{" "}
																						{
																							transport_service.service_display_name
																						}
																					</Box>
																					{showPickup(
																						start_date,
																						start_time_local,
																						start_date_local
																					) ? (
																						<>
																							<br />
																							<b>
																								<small>
																									{formatDate(
																										start_time_local
																											? parseDate(
																													start_time_local,
																													"HH:mm:ss"
																												)
																											: utcTimestampToLocalDate(
																													start_date
																												),
																										"HH:mm [hrs]"
																									)}
																								</small>
																							</b>
																						</>
																					) : null}
																					{remarks && includeRemarks ? (
																						<>
																							<br />
																							<p
																								style={{
																									fontWeight: "bold",
																									borderLeft:
																										"2px solid silver",
																									paddingLeft: "4px",
																								}}
																							>
																								<small
																									style={{
																										whiteSpace: "pre-line",
																									}}
																								>
																									{remarks}
																								</small>
																							</p>
																						</>
																					) : null}
																					{includeItinerary &&
																					itinerary?.description ? (
																						<Markdown className="itinerary">
																							{itinerary.description}
																						</Markdown>
																					) : null}
																				</td>
																			</>
																		) : null}
																		{!hasSameCabsForAll ? (
																			<>
																				<td>
																					{cab.cab ? (
																						<Box>
																							<b>{cab.cab.name}</b>
																							<br />
																							<small>
																								{cab.cab.number_plate}
																							</small>
																						</Box>
																					) : (
																						<b>{cab.cab_type.display_name}</b>
																					)}
																					{cab.driver ? (
																						<Box>
																							<b>{cab.driver.name}</b>
																							{cab.driver.phone_number ? (
																								<>
																									<br />
																									<a
																										href={`tel:${cab.driver.phone_number}`}
																									>
																										<small>
																											{cab.driver.phone_number}
																										</small>
																									</a>
																								</>
																							) : null}
																						</Box>
																					) : null}
																				</td>
																			</>
																		) : null}
																		{cabIndex === 0 ? (
																			<>
																				{includePrices ? (
																					<td
																						rowSpan={cabsRows}
																						style={{ textAlign: "right" }}
																					>
																						{price.length
																							? price
																									.map((p) =>
																										formatMoneyByIntl(p, {
																											showCurrency: true,
																										})
																									)
																									.join(" + ")
																							: null}
																					</td>
																				) : null}
																				{hotelCells}
																			</>
																		) : null}
																	</tr>
																)
															})}
														</React.Fragment>
													)
												}
												if (travel_activity_booking) {
													const {
														start_time_formatted,
														duration_formatted,
														activity,
														ticket_type,
														ticket_tourist_configurations_full_name,
														booked_price_money,
														confirmation_details,
														remarks,
														itinerary,
													} = travel_activity_booking
													totalPrice = addMoneyFromDifferentCurrencies(
														totalPrice.concat([booked_price_money])
													)
													return (
														<tr
															key={`travel_activity_booking_${travel_activity_booking.id}`}
														>
															{dateCell}
															<td>
																<Box as="b" whiteSpace="preserve">
																	{activity.name}
																	{ticket_type
																		? ` - ${ticket_type.display_name}`
																		: null}
																</Box>
																{start_time_formatted || duration_formatted ? (
																	<>
																		<br />
																		<b>
																			<small>
																				{[
																					start_time_formatted,
																					duration_formatted,
																				]
																					.filter(Boolean)
																					.join(" - ")}
																			</small>
																		</b>
																	</>
																) : null}
																{remarks && includeRemarks ? (
																	<>
																		<br />
																		<p
																			style={{
																				fontWeight: "bold",
																				borderLeft: "2px solid silver",
																				paddingLeft: "4px",
																			}}
																		>
																			<small
																				style={{
																					whiteSpace: "pre-line",
																				}}
																			>
																				{remarks}
																			</small>
																		</p>
																	</>
																) : null}
																{includeItinerary && itinerary?.description ? (
																	<Markdown className="itinerary">
																		{itinerary.description}
																	</Markdown>
																) : null}
															</td>
															<td>
																<b>{ticket_tourist_configurations_full_name}</b>
																{confirmation_details ? (
																	<>
																		<br />
																		<p
																			style={{
																				whiteSpace: "pre-line",
																				display: "inline-block",
																				padding: "2px 4px",
																				backgroundColor: "#f1f3f5",
																			}}
																		>
																			{confirmation_details}
																		</p>
																	</>
																) : null}
															</td>
															{includePrices ? (
																<td style={{ textAlign: "right" }}>
																	{Number(booked_price_money.amount)
																		? formatMoneyByIntl(booked_price_money, {
																				showCurrency: true,
																			})
																		: null}
																</td>
															) : null}
															{hotelCells}
														</tr>
													)
												}
												return null
											})}
										</React.Fragment>
									)
								})}
							</tbody>
						)
					})}
				</table>
				{includePrices && totalPrice.length ? (
					<>
						<br />
						<Box>
							<b>
								Total Price:{" "}
								{totalPrice
									.map((p) => formatMoneyByIntl(p, { showCurrency: true }))
									.join(" + ")}{" "}
								/-{" "}
							</b>
						</Box>
					</>
				) : null}
			</>
		</EmailShare>
	)
}

function getHasSameCabDriverForSchedules(
	schedules: Array<
		Pick<Required<TTripOperationalBooking>["cab_schedules"][number], "cabs">
	>
) {
	return (
		collect(
			schedules
				.map((s) => {
					const { cabs } = s
					return cabs
						.reduce<[drivers: Array<string>, cabs: Array<string>]>(
							(data, cab) => {
								const drivers = data[0]
								const cabs = data[1]
								if (cab.cab) {
									cabs.push(`${cab.cab.id}C`)
									if (!cab.driver) {
										drivers.push(`${cab.cab_type.id}CT`)
									}
								}
								if (cab.driver) {
									drivers.push(`${cab.driver.id}D`)
									if (!cab.cab) {
										drivers.push(`${cab.cab_type.id}CT`)
									}
								}
								if (!cab.driver && !cab.cab) {
									drivers.push(`${cab.cab_type.id}CT`)
									cabs.push(`${cab.cab_type.id}CT`)
								}
								return data
							},
							[[], []]
						)
						.map((d) => d.sort().join(","))
						.filter(Boolean)
						.join("_")
				})
				.filter(Boolean)
		)
			.unique()
			.count() === 1
	)
}

function getBookingPriceForCabs(cabs: Array<IScheduledCab>) {
	return addMoneyFromDifferentCurrencies(
		cabs.map<TMoney>((cab) =>
			moneyParseByDecimal(Number(cab.booked_price || 0), cab.currency)
		)
	)
}

function getWhatsAppContentForHotels(
	{
		stay_hotels,
		checkout_hotels,
		checkin_hotels,
	}: Pick<
		TTripOperationalBooking["daywise_services"][number],
		"stay_hotels" | "checkout_hotels" | "checkin_hotels"
	>,
	hotelsFromPreviousSchedule:
		| Pick<
				TTripOperationalBooking["daywise_services"][number],
				"stay_hotels" | "checkout_hotels" | "checkin_hotels"
		  >
		| undefined
) {
	const checkinsFromPreviousSchedule = hotelsFromPreviousSchedule
		? hotelsFromPreviousSchedule.checkin_hotels
		: []
	const staysFromPreviousSchedule = hotelsFromPreviousSchedule
		? hotelsFromPreviousSchedule.stay_hotels
		: []
	const previousStaysIds = collect(
		checkinsFromPreviousSchedule
			.concat(staysFromPreviousSchedule)
			.map((b) => b.hotel.id)
			.sort((a, b) => (a > b ? 1 : -1))
	)
		.unique()
		.toArray()
	const currentCheckoutsIds = collect(
		checkout_hotels.map((b) => b.hotel.id).sort((a, b) => (a > b ? 1 : -1))
	)
		.unique()
		.toArray()
	const areCheckinsFromPreivousSameAsCurrentCheckouts =
		previousStaysIds.join("-") === currentCheckoutsIds.join("-")
	return (
		[
			[stay_hotels as typeof checkin_hotels, `\`\`\`Stay    :\`\`\`` as string],
			[
				// don't render checkouts if we have checkin from previous sames as current checkout
				// And there are we have some checkins/staus for current
				// check fo current checkins/staus is needed to show checkouts on the last schedule
				areCheckinsFromPreivousSameAsCurrentCheckouts &&
				(checkin_hotels.length > 0 || stay_hotels.length > 0)
					? []
					: checkout_hotels,
				`\`\`\`Checkout:\`\`\``,
			],
			[checkin_hotels, `\`\`\`Checkin :\`\`\``],
		] as Array<[typeof checkin_hotels, "string"]>
	)
		.map(([hotels, label]) =>
			!hotels.length
				? ``
				: `${label} ${hotels
						.map(
							(h) =>
								`*${h.hotel.location.short_name}*
\`\`\`        |\`\`\` *${h.hotel.name}*`
						)
						.join(`\n\`\`\`        :\`\`\` `)}`
		)
		.filter(Boolean)
		.join("\n")
}

function SelectTripOperationalBookingsToShare({
	tripId,
	onCancel,
	onSelect,
}: {
	tripId: number
	onCancel: () => void
	onSelect: (selectedSchedules: Array<TSharableTripOperationalBooking>) => void
}) {
	const xhr = useXHR()
	const id = useId()
	const { data } = useSWR(
		`/trip-operational-bookings/${tripId}?${id}&share`,
		() =>
			xhr
				.get<{
					data: TTripOperationalBooking
				}>(`/trip-operational-bookings/${tripId}`)
				.then((resp) => resp.data.data)
	)
	const initialValues = useMemo(() => {
		if (!data) {
			return {
				daywise_services: [],
			}
		}
		return {
			daywise_services: data.daywise_services.map((s) => ({
				...s,
				services: s.services.map((s) => ({ ...s, selected: true })),
			})),
		}
	}, [data])
	if (!data) {
		return <Spinner padding="4" alignCenter />
	}
	if (!data.cab_schedules?.length && !data.travel_activity_bookings?.length) {
		return (
			<Box textAlign="center" padding="4">
				No operational bookings for this trip
			</Box>
		)
	}
	return (
		<Stack gap="4">
			<Stack gap="2">
				<Heading as="h4">Select Bookings to Share</Heading>
				<Text color="muted">
					Please select the bookings that you want to share with yours customers
					or service providers.
				</Text>
			</Stack>
			<Form<typeof initialValues>
				initialValues={initialValues}
				onSubmit={async (values) => {
					onSelect([
						{
							...data,
							daywise_services: values.daywise_services
								.map((s) => ({
									...s,
									services: s.services.filter((s) => isTruthy(s.selected)),
								}))
								.filter((s) => s.services.length),
						},
					])
				}}
				subscription={{ submitting: true }}
				mutators={{ ...arrayMutators }}
			>
				{({ handleSubmit, form }) => (
					<form noValidate onSubmit={handleSubmit}>
						<Box>
							<GetFieldValue<
								(typeof initialValues)["daywise_services"]
							> name="daywise_services">
								{({ value: daywise_services }) => (
									<Box
										as="label"
										paddingX="4"
										paddingBottom="2"
										marginBottom="4"
										display="flex"
										alignItems="center"
										borderBottomWidth="2"
										borderColor="default"
										cursor="pointer"
									>
										<CheckboxInput
											name="select_all"
											checked={daywise_services.every((s) =>
												s.services.every((s) => s.selected)
											)}
											onChange={(e) => {
												if (e.currentTarget.checked) {
													// select all the cabs
													form.change(
														"daywise_services",
														daywise_services.map((s) => ({
															...s,
															services: s.services.map((s) => ({
																...s,
																selected: true,
															})),
														}))
													)
												} else {
													// unselect all the cabs
													form.change(
														"daywise_services",
														daywise_services.map((s) => ({
															...s,
															services: s.services.map((s) => ({
																...s,
																selected: false,
															})),
														}))
													)
												}
											}}
										/>
										<Box marginLeft="2" fontWeight="semibold">
											Select All Bookings
										</Box>
									</Box>
								)}
							</GetFieldValue>
							<Box as="ol">
								{initialValues.daywise_services.map(
									({ date, services }, dayIndex) => {
										const daywiseServiceFieldName = `daywise_services[${dayIndex}]`
										return (
											<Inline key={dayIndex} gap="4" as="li" collapseBelow="sm">
												<Box style={{ width: "80px" }}>
													<GetFieldValue<
														(typeof initialValues)["daywise_services"][number]
													>
														name={daywiseServiceFieldName}
													>
														{({ value, onChange }) => (
															<Button
																inline
																title="Select All Bookings of this Day"
																onClick={() => {
																	onChange({
																		...value,
																		services: value.services.map((s) => ({
																			...s,
																			selected: true,
																		})),
																	})
																}}
															>
																<Stack
																	gap="1"
																	padding="1"
																	color="default"
																	textAlign="left"
																>
																	<Text fontSize="md" fontWeight="semibold">
																		{utcTimestampToLocalDateString(
																			date,
																			"D MMM"
																		)}
																	</Text>
																	<Text fontSize="sm">
																		{utcTimestampToLocalDateString(
																			date,
																			"dddd"
																		)}
																	</Text>
																</Stack>
															</Button>
														)}
													</GetFieldValue>
												</Box>
												<Stack flex="1" as="ol">
													{services.map((s, serviceIndex) => {
														const checkboxInputFieldName = `${daywiseServiceFieldName}.services[${serviceIndex}].selected`
														return (
															<Box as="li" key={serviceIndex}>
																<GetFieldValue name={checkboxInputFieldName}>
																	{({ value: selected }) => (
																		<Inline
																			as="label"
																			paddingX="4"
																			paddingY="2"
																			rounded="lg"
																			marginBottom="4"
																			borderWidth="1"
																			backgroundColor={{
																				default: selected
																					? "primary"
																					: "subtle",
																				hover: "primary",
																			}}
																			cursor="pointer"
																			gap="4"
																		>
																			<Box>
																				<CheckboxInputField
																					name={checkboxInputFieldName}
																					type="checkbox"
																				/>
																			</Box>
																			<Box flex="1" minWidth="0">
																				{s.cab_schedule ? (
																					<SelectableCabScheduleItem
																						schedule={s.cab_schedule}
																					/>
																				) : s.travel_activity_booking ? (
																					<SelectableTravelActivityBookingItem
																						schedule={s.travel_activity_booking}
																					/>
																				) : null}
																			</Box>
																		</Inline>
																	)}
																</GetFieldValue>
															</Box>
														)
													})}
												</Stack>
											</Inline>
										)
									}
								)}
							</Box>
						</Box>
						<Box
							textAlign="right"
							marginTop="8"
							borderTopWidth="1"
							paddingTop="8"
						>
							<Inline flexDirection="rowReverse" gap="4">
								<GetFieldValue<
									(typeof initialValues)["daywise_services"]
								> name="daywise_services">
									{({ value: schedules }) => {
										const hasSelectedSomething = schedules.some((s) =>
											s.services.some((s) => s.selected)
										)
										return (
											<Button
												disabled={!hasSelectedSomething}
												status={!hasSelectedSomething ? "warning" : "primary"}
												type="submit"
											>
												{!hasSelectedSomething ? (
													<>
														<Icons.Attention /> Select Some Bookings!
													</>
												) : (
													<>
														Next
														<Icons.ChevronDown rotate="270" />
													</>
												)}
											</Button>
										)
									}}
								</GetFieldValue>
								<Button onClick={onCancel} level="tertiary">
									Cancel
								</Button>
							</Inline>
						</Box>
					</form>
				)}
			</Form>
		</Stack>
	)
}

function SelectableCabScheduleItem({
	schedule,
}: {
	schedule: Required<TTripOperationalBooking>["cab_schedules"][number]
}) {
	return (
		<Stack gap="1">
			<Box display={{ sm: "flex" }} justifyContent="between">
				<Box>
					<Box fontWeight="semibold" display="inlineBlock">
						{schedule.transport_service.from_to}
					</Box>
					<Box display="inline" paddingX="2">
						-
					</Box>
					<Box display="inlineBlock">
						{schedule.transport_service.service_display_name}
					</Box>
				</Box>
			</Box>
			<Box as="ul" fontWeight="normal">
				{schedule.cabs.map((cab) => {
					return (
						<Box
							key={cab.id}
							as="li"
							marginTop="1"
							display={{ sm: "flex" }}
							alignItems="center"
							fontSize="sm"
						>
							<Box
								marginRight="4"
								paddingRight="2"
								borderRightWidth={cab.transport_service_provider ? "4" : "0"}
							>
								{cab.cab_type.display_name}
							</Box>
							{cab.transport_service_provider ? (
								<Box display="flex" flexWrap="wrap">
									{joinAttributes(
										<Box>{cab.transport_service_provider.name}</Box>,
										cab.driver &&
											!cab.transport_service_provider.solo_driver ? (
											<Box>{cab.driver.name}</Box>
										) : null,
										cab.cab ? <Box>{cab.cab.name}</Box> : null,
										cab.cab ? <Box>{cab.cab.number_plate}</Box> : null
									)}
								</Box>
							) : null}
							<Box display="flex">
								{cab.booked ? (
									<Box marginLeft="2">
										<Badge success outlined>
											Booked
										</Badge>
									</Box>
								) : cab.transport_service_provider ? (
									<Box marginLeft="2">
										<Badge outlined warning>
											Assigned
										</Badge>
									</Box>
								) : null}
							</Box>
						</Box>
					)
				})}
			</Box>
		</Stack>
	)
}

function SelectableTravelActivityBookingItem({
	schedule,
}: {
	schedule: Required<TTripOperationalBooking>["travel_activity_bookings"][number]
}) {
	const {
		activity,
		ticket_type,
		ticket_tourist_configurations_short_name,
		is_booked,
		supplier,
	} = schedule
	return (
		<Stack gap="1" fontWeight="normal">
			<Text fontWeight="semibold">
				{activity.name}
				{ticket_type ? ` - ${ticket_type.display_name}` : null}
			</Text>
			<Inline gap="4" fontSize="sm" alignItems="center">
				<Box paddingRight="2" borderRightWidth={supplier ? "4" : "0"}>
					{ticket_tourist_configurations_short_name}
				</Box>
				<Inline gap="2" alignItems="center">
					{supplier ? (
						<Box display="flex" flexWrap="wrap">
							<Box>{supplier.name}</Box>
						</Box>
					) : null}
					<Box display="flex">
						{is_booked ? (
							<Box marginLeft="2">
								<Badge success outlined>
									Booked
								</Badge>
							</Box>
						) : supplier ? (
							<Box marginLeft="2">
								<Badge outlined warning>
									Assigned
								</Badge>
							</Box>
						) : null}
					</Box>
				</Inline>
			</Inline>
		</Stack>
	)
}

function EmailShare({
	children,
	subject,
	disabled,
}: {
	shareWith: "booking_enquiry" | "transport_service_provider"
	children: React.ReactNode
	subject?: React.ReactNode
	disabled?: boolean
}) {
	return (
		<CopyToClipboard<HTMLDivElement>>
			{({ nodeToCopy, copy }) => (
				<Box position="relative">
					{subject ? (
						<Stack gap="4" paddingX="4" paddingTop="4">
							<CopyToClipboard<HTMLDivElement>>
								{({ nodeToCopy, copyNodeTextToClipboard }) => (
									<Stack gap="1">
										<Inline justifyContent="between" alignItems="center">
											<Text fontWeight="semibold">Subject</Text>
											{!disabled ? (
												<CopyToClipboardButton
													size="sm"
													level="primary"
													onClick={async () => {
														return copyNodeTextToClipboard()
													}}
												>
													<Icons.ClipboardCopy /> Copy Subject
												</CopyToClipboardButton>
											) : null}
										</Inline>
										<Box
											paddingY="2"
											paddingX="4"
											borderWidth="1"
											rounded="md"
											bgColor="subtle"
										>
											<Box ref={nodeToCopy}>{subject}</Box>
										</Box>
									</Stack>
								)}
							</CopyToClipboard>
							<Text fontWeight="semibold">Body</Text>
						</Stack>
					) : null}
					{!disabled ? (
						<Box position="sticky" top="0">
							<Box position="absolute" right="0" padding="4">
								<CopyToClipboardButton
									level="primary"
									disabled={disabled}
									onClick={async () => {
										return copy()
									}}
								>
									<Icons.ClipboardCopy /> Copy
								</CopyToClipboardButton>
							</Box>
						</Box>
					) : null}
					<Box padding="4" overflowX="auto">
						<ShadowRoot>
							<Box ref={nodeToCopy}>
								<Box style={{ width: "100%" }}>
									<style>{`* { border: none; margin: 0; }
									td, th, span, p, b, body, li, h1, h2, h3, h4, h5, h6, div, strong, a { font-family: Verdana; font-size: 10pt; }
									.itinerary { max-width: 600px;}
									.itinerary span, .itinerary p, .itinerary b, body, .itinerary li, .itinerary strong, .itinerary a { font-size: smaller; }
									table { border-collapse: collapse;}
									td, th { border: 1px solid #dfe3e6; padding: 4px 8px; vertical-align: top; text-align: left;}
									a { color: black; text-decoration: none;}
								`}</style>
									{children}
								</Box>
							</Box>
						</ShadowRoot>
					</Box>
				</Box>
			)}
		</CopyToClipboard>
	)
}

function showPickup(
	pickup_at: string,
	start_time_local: string | undefined | null,
	start_date_local: string | undefined | null
) {
	return Boolean(
		getStartDateTimeOfCabSchedule({
			start_date: pickup_at,
			start_time_local: start_time_local || undefined,
			start_date_local: start_date_local || undefined,
		})
	)
}
