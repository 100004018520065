import { Helmet } from "react-helmet-async"
import { TripOperationalBookingsList } from "./../../../TripOperationalBookings"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../Auth"

export default function TripOperationalBookingsListPage() {
	return (
		<>
			<Helmet>
				<title>Trip Operational Bookings List</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.VIEW_CAB_SCHEDULES}>
				<TripOperationalBookingsList />
			</ForbidUnlessAuthorized>
		</>
	)
}
