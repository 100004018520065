import {
	Stack,
	Box,
	Button,
	Icons,
	Select,
	Inline,
	useTimeout,
	SROnly,
} from "@sembark-travel/ui/base"
import { Col, Grid } from "@sembark-travel/ui/base"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import {
	getDiff,
	formatDate,
	addUnit,
	dateToUTCString,
	startOf,
	isSame,
} from "@sembark-travel/datetime-utils"
import { withOrdinalSuffix } from "@sembark-travel/number-utils"
import { useCallback, useMemo, useRef, useEffect } from "react"
import { Required } from "utility-types"
import * as Validator from "yup"
import { SelectOtherExtraServices, IExtraService } from "./../ExtraServices"
import {
	EmptyNumberValidator,
	Form,
	FieldArray,
	FormProps,
	TextInputField,
	SelectField,
	useFormState,
	FieldGroup,
	DatePickerField,
	validateFormValues,
	arrayMutators,
	MoneyInputField,
} from "@sembark-travel/ui/form"
import { TTripDestination } from "../TripDestinations"
import { useCurrenciesOfTenant } from "../Currencies"

function XHR(_: XHRInstance) {
	return {
		async getPrice<T = unknown>(other_extras: T) {
			return Promise.resolve({ other_extras })
			// return xhr
			//   .get("/prices", { params: { other_extras } })
			//   .then(resp => resp.data)
		},
	}
}

export interface OtherExtraServicesParams {
	other_extras?: Array<{
		currency: string
		service?: IExtraService
		price?: number
		date?: Date
		comments?: string
	}>
}

const validationSchema = Validator.object().shape({
	other_extras: Validator.array().of(
		Validator.object().shape({
			service: Validator.object()
				.typeError("Service field is required")
				.required("Service field is required"),
			date: Validator.date().nullable(),
			price: EmptyNumberValidator().positive("Price should be positive"),
			comments: Validator.string(),
		})
	),
})

const validate = validateFormValues(validationSchema)

interface ExtraServicesFormProps {
	initialValues?: OtherExtraServicesParams
	onChange?: (
		data: Required<OtherExtraServicesParams, "other_extras">["other_extras"]
	) => void
	bookingFrom?: string
	bookingTo?: string
	shouldEmptyInitialValues?: boolean
	tripDestinations: Array<TTripDestination>
}
export function ExtraServicesForm({
	initialValues: initialValuesProp,
	shouldEmptyInitialValues = true,
	onChange,
	bookingFrom,
	bookingTo,
	tripDestinations,
}: ExtraServicesFormProps) {
	const xhr = useXHR()
	const currency = useMemo(
		() => tripDestinations.map((d) => d.currency).at(0) || "INR",
		[tripDestinations]
	)
	const allCurrencies = useCurrenciesOfTenant()
	const INITIAL_VALUES: Required<OtherExtraServicesParams, "other_extras"> =
		useRef({
			other_extras: [
				{
					currency: currency,
					service: undefined,
					date: undefined,
					price: undefined,
					comments: "",
				},
			],
		}).current
	const initialValues =
		initialValuesProp ||
		(shouldEmptyInitialValues ? { other_extras: [] } : INITIAL_VALUES)
	const notifyOnChange = useCallback(
		(flattenValues: OtherExtraServicesParams) => {
			onChange &&
				onChange(flattenValues.other_extras?.filter((oe) => oe.service) || [])
		},
		[onChange]
	)
	const bookingDates = useMemo(() => {
		const dates: Array<{ id: string; name: string; value: Date }> = []
		if (!bookingTo || !bookingFrom) return dates
		const days = getDiff(bookingTo, bookingFrom, "days")
		for (let i = 0; i <= days; i++) {
			const date = addUnit(bookingFrom, i, "day")
			dates.push({
				id: formatDate(date, "YYYY-MM-DD"),
				name: `${withOrdinalSuffix(i + 1)} Day (${formatDate(date, "ddd")})`,
				value: startOf(date, "day"),
			})
		}
		return dates
	}, [bookingFrom, bookingTo])
	const onSubmit: FormProps<OtherExtraServicesParams>["onSubmit"] = useCallback(
		async (values) => {
			const other_extras: Array<
				Omit<
					Required<
						OtherExtraServicesParams,
						"other_extras"
					>["other_extras"][number],
					"date" | "service"
				> & {
					date?: string
					service?: string
				}
			> = []
			// flatten values so that we can show the prices for each row
			const flattenValues: Required<OtherExtraServicesParams, "other_extras"> =
				{
					other_extras: [],
				}
			values.other_extras?.forEach((values) => {
				const { date, service, ...otherData } = values
				if (service) {
					flattenValues.other_extras.push({
						...values,
					})
					other_extras.push({
						...otherData,
						date: date ? dateToUTCString(startOf(date, "day")) : "",
						service: service ? service.name : undefined,
					})
				}
			})
			return XHR(xhr)
				.getPrice(other_extras)
				.then((data) => {
					flattenValues.other_extras = flattenValues.other_extras.map(
						(item, i) => ({
							...item,
							price: data.other_extras[i].price,
						})
					)
					notifyOnChange(flattenValues)
				})
		},
		[xhr, notifyOnChange]
	)
	return (
		<Form<OtherExtraServicesParams>
			initialValues={initialValues}
			validate={validate}
			onSubmit={onSubmit}
			subscription={{}}
			mutators={{ ...arrayMutators }}
		>
			{({ handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<FieldArray<
						Required<OtherExtraServicesParams>["other_extras"][number]
					> name="other_extras">
						{({ fields }) => (
							<Stack gap="4">
								{fields.map((name, index) => (
									<Box
										key={name}
										borderBottomWidth="4"
										borderColor="default"
										paddingBottom="4"
									>
										<Grid gap="4">
											<Col>
												<SelectField
													name={`${name}.service`}
													select={SelectOtherExtraServices}
													label="Service"
													placeholder="Select or add a service..."
													required
													creatable
													fetchOnMount
												/>
											</Col>
											<Col>
												<MoneyInputField
													label={`Total Price`}
													name={`${name}.price`}
													currencyFieldName={`${name}.currency`}
													currencies={allCurrencies}
												/>
											</Col>
											<Col>
												{bookingFrom && bookingTo ? (
													<FieldGroup<
														Required<OtherExtraServicesParams>["other_extras"][number]["date"]
													>
														name={`${name}.date`}
														label="Date"
													>
														{({ input }) => {
															const { value } = input
															return (
																<Select
																	{...input}
																	options={bookingDates}
																	searchable={false}
																	placeholder="Select a date..."
																	required
																	value={
																		value
																			? bookingDates.find((d) =>
																					isSame(value, d.value, "day")
																				)
																			: undefined
																	}
																	onChange={(startDate) => {
																		input.onChange(
																			startDate ? startDate.value : undefined
																		)
																	}}
																/>
															)
														}}
													</FieldGroup>
												) : (
													<DatePickerField
														label="Date"
														name={`${name}.date`}
														required
													/>
												)}
											</Col>
											<Col>
												<TextInputField
													name={`${name}.comments`}
													label="Comments"
													placeholder="Any comments regarding service"
												/>
											</Col>
											<Col paddingTop="6" textAlign="right">
												<Inline gap="4">
													<Button
														onClick={() => fields.remove(index)}
														title="Remove"
														size="sm"
														level="tertiary"
													>
														<Icons.Cancel />
													</Button>
												</Inline>
											</Col>
										</Grid>
									</Box>
								))}
								<Box>
									<Button
										onClick={() => fields.push(INITIAL_VALUES.other_extras[0])}
									>
										<Icons.Plus /> Add Service
									</Button>
								</Box>
							</Stack>
						)}
					</FieldArray>
					<SubmitOnChange onChange={notifyOnChange} />
					<SROnly>
						<Button type="submit">Get Prices</Button>
					</SROnly>
				</form>
			)}
		</Form>
	)
}

function SubmitOnChange({
	onChange,
}: {
	onChange: (values: OtherExtraServicesParams) => void
}) {
	const { values } = useFormState<OtherExtraServicesParams>({
		subscription: { values: true },
	})
	const { set: setNotifyParentTimeout, clear: clearNotifyParentTimeout } =
		useTimeout()

	const onChangeRef = useRef(onChange)
	onChangeRef.current = onChange

	// notify parent
	useEffect(() => {
		setNotifyParentTimeout(() => {
			onChangeRef.current(values)
		}, 500)
		return () => clearNotifyParentTimeout()
	}, [values, setNotifyParentTimeout, clearNotifyParentTimeout])
	return null
}
