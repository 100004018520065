import {
	isBetween,
	dateToQuery,
	parseDateFromQuery,
	dateToUTCString,
	endOf,
	startOf,
	localOrUtcTimestampToLocalDate,
	formatDate,
} from "@sembark-travel/datetime-utils"
import { TSearchParams } from "@sembark-travel/ui/list"
import { Required } from "utility-types"
import { ILocation } from "../Locations"
import { ITransportServiceProvider } from "../TransportServiceProviders"
import { TTransportServiceLocationPoint } from "../TransportServices"
import { TTripDestination } from "../TripDestinations"
import { ICabType } from "../CabTypes"
import { IUser } from "../Users"
import Storage from "../storage"
import { useCallback, useEffect, useMemo, useReducer } from "react"
import { TTravelActivity, TTravelActivityTicketType } from "../TravelActivities"
import { TDriver } from "../Drivers"

type OperationBooking = {
	id: number
	start_date: string
	start_date_local?: string
	end_date: string
	end_date_local?: string
	cab_schedules?: Array<{
		id: number
		start_date: string
		start_date_local?: string
		end_date: string
		end_date_local?: string
	}>
	travel_activity_bookings?: Array<{
		id: number
		start_date: string
		start_date_local?: string
		end_date: string
		end_date_local?: string
	}>
}

export function arrangeOperatinalBookingsInCalendar<
	TOperationalBooking extends OperationBooking,
>(
	schedules: Array<TOperationalBooking>,
	interval: Array<Date>,
	compact?: boolean
) {
	const trips = schedules.concat([])
	// sort the trips with start date
	trips.sort((a, b) =>
		a.start_date > b.start_date
			? 1
			: a.start_date === b.start_date
				? a.id - b.id
				: -1
	)
	// lets construct a 3D array which will hold schedules
	// X -> rows
	// Y -> Slots
	// Z -> Schedules
	const bookingsPerRowPerSlot: Array<
		Array<{
			date: Date
			booking:
				| Required<
						TOperationalBooking,
						"cab_schedules" | "travel_activity_bookings"
				  >
				| undefined
		}>
	> = []
	let currentRow = 0
	if (!compact) {
		trips.forEach((trip) => {
			const bookingsPerSlot = getBookingsPerSlot(trip, interval)
			bookingsPerRowPerSlot[currentRow] = bookingsPerSlot
			currentRow += 1
		})
		return bookingsPerRowPerSlot
	} else {
		for (const trip of trips) {
			const bookingsPerSlot = getBookingsPerSlot(trip, interval)
			// see if we need to move to the next row or we can push it to an earlier row
			// Steps:
			// get the first none empty slot in for this trip
			const firstNonEmptyCell = bookingsPerSlot.findIndex((booking) =>
				Boolean(booking.booking)
			)
			// which row to push (default to current row)
			let pushToRow = currentRow
			if (firstNonEmptyCell !== -1 && currentRow > 0) {
				// now search in the existing rows, in the reverse order, where we have no schedules in the slot
				for (let j = pushToRow - 1; j >= 0; j--) {
					// check if all the slots are empty after the firstNonEmptyCell
					const allSlotsEmptyAfter = bookingsPerRowPerSlot[j]
						.slice(firstNonEmptyCell)
						.every((booking) => !booking.booking)
					if (allSlotsEmptyAfter) {
						pushToRow = j
					}
				}
			}
			if (!bookingsPerRowPerSlot[pushToRow]) {
				bookingsPerRowPerSlot[pushToRow] = bookingsPerSlot
			} else {
				// merge the schedules
				bookingsPerRowPerSlot[pushToRow] = bookingsPerRowPerSlot[pushToRow].map(
					(schedules, i) => (schedules.booking ? schedules : bookingsPerSlot[i])
				)
			}
			// if it was pushed to the current row, move to next row
			// otherwise continue with this row
			if (pushToRow === currentRow) {
				currentRow += 1
			}
		}
		return bookingsPerRowPerSlot
	}
}

function getBookingsPerSlot<TOperationalBooking extends OperationBooking>(
	trip: TOperationalBooking,
	interval: Array<Date>
) {
	const bookingsPerSlot: Array<{
		date: Date
		booking:
			| Required<
					TOperationalBooking,
					"cab_schedules" | "travel_activity_bookings"
			  >
			| undefined
	}> = []
	interval.forEach((slot) => {
		let bookingForThisSlot:
			| Required<
					TOperationalBooking,
					"cab_schedules" | "travel_activity_bookings"
			  >
			| undefined = undefined
		const { cab_schedules, travel_activity_bookings, ...otherData } = trip
		const isBookingBetween = isBetween(
			slot,
			localOrUtcTimestampToLocalDate(trip.start_date_local, trip.start_date),
			localOrUtcTimestampToLocalDate(trip.end_date_local, trip.end_date),
			"day",
			"[]"
		)
		const cab_schedules_for_slot = (cab_schedules || []).filter((s) => {
			return isBetween(
				slot,
				localOrUtcTimestampToLocalDate(s.start_date_local, s.start_date),
				localOrUtcTimestampToLocalDate(s.end_date_local, s.end_date),
				"day",
				"[]"
			)
		})
		const travel_activity_bookings_for_slot = (
			travel_activity_bookings || []
		).filter((s) =>
			isBetween(
				slot,
				localOrUtcTimestampToLocalDate(s.start_date_local, s.start_date),
				localOrUtcTimestampToLocalDate(s.end_date_local, s.end_date),
				"day",
				"[]"
			)
		)

		if (
			cab_schedules_for_slot.length ||
			travel_activity_bookings_for_slot.length ||
			isBookingBetween
		) {
			bookingForThisSlot = {
				...otherData,
				cab_schedules: cab_schedules_for_slot,
				travel_activity_bookings: travel_activity_bookings_for_slot,
			}
		}
		bookingsPerSlot.push({
			date: slot,
			booking: bookingForThisSlot,
		})
	})
	return bookingsPerSlot
}

export const BOOKING_STATUS = [
	["not_assigned", "Supplier Not Assigned"],
	["assigned_but_not_booked", "Assigned but Not Booked"],
	["booked", "Marked as Booked"],
].map(([id, name]) => ({ id: id, name }))

export interface IOperationalBookingFilters extends TSearchParams {
	date?: Date
	transport_locations?: ILocation[]
	transport_service_providers?: Array<ITransportServiceProvider>
	drivers?: Array<TDriver>
	transport_service_location_points?: Array<TTransportServiceLocationPoint>
	trip_destinations?: Array<TTripDestination>
	cab_types?: Array<ICabType>
	travel_activities?: Array<TTravelActivity>
	travel_activity_ticket_types?: Array<TTravelActivityTicketType>
	hide_past_trips?: boolean
	status?: (typeof BOOKING_STATUS)[number]
	owners?: Array<IUser>
	trip_operational_status?:
		| "new"
		| "in_progress"
		| "booked"
		| "on_trip"
		| "on_trip"
		| "past"
		| "dropped"
		| "all"
	trips_starting_between_interval?: boolean
}

export interface IOperationalBookingFiltersInLocationQuery
	extends TSearchParams {
	date?: string | null
	tls?: string[]
	tsps?: Array<string>
	drivers?: Array<string>
	tslp?: Array<string>
	ct?: Array<string>
	ta?: Array<string>
	tatt?: Array<string>
	tds?: Array<string>
	hpt?: 0 | 1
	status?: string
	owners?: string[]
	tos?:
		| "new"
		| "in_progress"
		| "booked"
		| "on_trip"
		| "on_trip"
		| "past"
		| "dropped"
		| "all"
	tbt?: 0 | 1
}

export function operationalBookingFilterParamsToLocationQuery(
	params: IOperationalBookingFilters
): IOperationalBookingFiltersInLocationQuery {
	const {
		date,
		q,
		page,
		sort,
		transport_locations,
		transport_service_providers,
		drivers,
		transport_service_location_points,
		trip_destinations,
		cab_types,
		travel_activities,
		travel_activity_ticket_types,
		hide_past_trips,
		status,
		owners,
		trip_operational_status,
		trips_starting_between_interval: trips_beginning_today,
	} = params
	const filters: IOperationalBookingFiltersInLocationQuery = {
		tos: trip_operational_status,
	}
	if (q) filters.q = q
	if (page) filters.page = page
	if (sort) filters.sort = sort
	if (date) filters.date = dateToQuery(date)
	if (transport_locations && transport_locations.length)
		filters.tls = transport_locations.map((t) => `${t.id}_${t.name}`)
	if (transport_service_providers && transport_service_providers.length)
		filters.tsps = transport_service_providers.map((t) => `${t.id}_${t.name}`)
	if (
		transport_service_location_points &&
		transport_service_location_points.length
	)
		filters.tslp = transport_service_location_points.map(
			(t) => `${t.id}_${t.name}`
		)
	if (cab_types && cab_types.length)
		filters.ct = cab_types.map((t) => `${t.id}_${t.name}`)
	if (travel_activities && travel_activities.length)
		filters.ta = travel_activities.map((t) => `${t.id}_${t.name}`)
	if (travel_activity_ticket_types && travel_activity_ticket_types.length)
		filters.tatt = travel_activity_ticket_types.map((t) => `${t.id}_${t.name}`)
	if (trip_destinations && trip_destinations.length)
		filters.tds = trip_destinations.map((t) => `${t.id}_${t.name}`)
	if (hide_past_trips) {
		filters.hpt = 1
	}
	if (owners && owners.length) {
		filters.owners = owners.map((t) => `${t.id}_${t.name}`)
	}
	if (drivers && drivers.length) {
		filters.drivers = drivers.map((t) => `${t.id}_${t.name}`)
	}
	if (status) filters.status = status.name

	if (trips_beginning_today) {
		filters.tbt = 1
	}

	return filters
}

export function operationBookingLocationQueryToParams(
	query: IOperationalBookingFiltersInLocationQuery
): IOperationalBookingFilters {
	const {
		date,
		q,
		page,
		sort,
		tls,
		hpt,
		tsps,
		drivers,
		tslp,
		ct,
		ta,
		tatt,
		tds,
		status,
		owners,
		tos,
		tbt,
	} = query
	const filters: IOperationalBookingFilters = {
		trip_operational_status: tos,
	}
	if (date) {
		filters.date = parseDateFromQuery(date)
	}
	if (q) filters.q = q
	if (page) filters.page = page
	if (sort) filters.sort = sort
	if (tls && tls.length)
		filters.transport_locations = tls.map((t) => {
			const [id, ...names] = t.split("_")
			return {
				id: parseInt(id),
				name: names.join("_"),
			} as unknown as ILocation
		})
	if (tsps && tsps.length)
		filters.transport_service_providers = tsps.map((t) => {
			const [id, ...names] = t.split("_")
			return {
				id: parseInt(id),
				name: names.join("_"),
			} as unknown as ITransportServiceProvider
		})
	if (tslp && tslp.length)
		filters.transport_service_location_points = tslp.map((t) => {
			const [id, ...names] = t.split("_")
			return {
				id: parseInt(id),
				name: names.join("_"),
			} as unknown as TTransportServiceLocationPoint
		})
	if (ct && ct.length)
		filters.cab_types = ct.map((t) => {
			const [id, ...names] = t.split("_")
			return {
				id: parseInt(id),
				name: names.join("_"),
			} as unknown as ICabType
		})
	if (ta && ta.length)
		filters.travel_activities = ta.map((t) => {
			const [id, ...names] = t.split("_")
			return {
				id: parseInt(id),
				name: names.join("_"),
			} as unknown as TTravelActivity
		})
	if (tatt && tatt.length)
		filters.travel_activity_ticket_types = tatt.map((t) => {
			const [id, ...names] = t.split("_")
			return {
				id: parseInt(id),
				name: names.join("_"),
			} as unknown as TTravelActivityTicketType
		})
	if (tds && tds.length)
		filters.trip_destinations = tds.map((t) => {
			const [id, ...names] = t.split("_")
			return {
				id: parseInt(id),
				name: names.join("_"),
			} as unknown as TTripDestination
		})
	if (owners && owners.length)
		filters.owners = owners.map((t) => {
			const [id, ...names] = t.split("_")
			return {
				id: parseInt(id),
				name: names.join("_"),
			} as unknown as IUser
		})
	if (drivers && drivers.length)
		filters.drivers = drivers.map((t) => {
			const [id, ...names] = t.split("_")
			return {
				id: parseInt(id),
				name: names.join("_"),
			} as unknown as TDriver
		})
	if (hpt && Number(hpt) === 1) {
		filters.hide_past_trips = true
	}
	if (status) {
		filters.status = BOOKING_STATUS.find((s) => s.id === status)
	}

	if (tbt && Number(tbt) === 1) {
		filters.trips_starting_between_interval = true
	}
	return filters
}

const OPERATIONAL_BOOKING_CALENDAR_HAS_COMPACT_FIT_KEY = "obc_icf"
const OPERATIONAL_BOOKING_CALENDAR_TRANSITION_WINDOW_SIZE_KEY = "obc_tws"
const OPERATIONAL_BOOKING_CALENDAR_SHOW_OPERATIONS_TEAM_KEY = "obc_sot"

function storeCompactFitStateToStorage(value: boolean) {
	Storage.put(OPERATIONAL_BOOKING_CALENDAR_HAS_COMPACT_FIT_KEY, Number(value))
}

function storeShowOperationsTeamToStorage(value: boolean) {
	Storage.put(
		OPERATIONAL_BOOKING_CALENDAR_SHOW_OPERATIONS_TEAM_KEY,
		Number(value)
	)
}

function storeTransitionWindowSizeToStorage(value: number) {
	Storage.put(OPERATIONAL_BOOKING_CALENDAR_TRANSITION_WINDOW_SIZE_KEY, value)
}

function getHasCompactFitFromStorage(): boolean {
	return castStorageValueToBoolean(
		Storage.get(OPERATIONAL_BOOKING_CALENDAR_HAS_COMPACT_FIT_KEY, 1),
		true
	)
}

function getShowOperationsTeamFromStorage(): boolean {
	return castStorageValueToBoolean(
		Storage.get(OPERATIONAL_BOOKING_CALENDAR_SHOW_OPERATIONS_TEAM_KEY, 1),
		true
	)
}

function castStorageValueToBoolean(value: unknown, defaultValue = false) {
	if (["0", "1"].indexOf(value as string) === -1) {
		// default or when invalid value
		return defaultValue
	}
	return Boolean(Number(value))
}

type TAllowedWindowSizes = 1 | 3 | 5 | 7 | 10

export const TransitionWindowOptions: Array<TAllowedWindowSizes> = [
	1, 3, 5, 7, 10,
]

function ensureValueInAllowedOptions(value: unknown): TAllowedWindowSizes {
	if (TransitionWindowOptions.indexOf(Number(value) as never) === -1) {
		return 1
	}
	return Number(value) as TAllowedWindowSizes
}

function getTransitionWindowSizeFromStorage(): TAllowedWindowSizes {
	const size = Storage.get(
		OPERATIONAL_BOOKING_CALENDAR_TRANSITION_WINDOW_SIZE_KEY,
		1
	)
	return ensureValueInAllowedOptions(size)
}

export function useCalendarUIPreferences() {
	const initialPreferences = useMemo(() => {
		return {
			hasCompactFit: getHasCompactFitFromStorage(),
			transitionWindowSize: getTransitionWindowSizeFromStorage(),
			showOperationsTeam: getShowOperationsTeamFromStorage(),
		}
	}, [])
	const [calendarUIPreferences, dispatch] = useReducer<
		React.Reducer<
			typeof initialPreferences,
			| {
					type: "TOGGLE_COMPACT_FIT" | "TOGGLE_OPERATIONS_TEAM_VISIBILITY"
			  }
			| { type: "CHANGE_TRANSITION_WINDOW_SIZE"; payload: TAllowedWindowSizes }
		>
	>((state, action) => {
		switch (action.type) {
			case "TOGGLE_COMPACT_FIT":
				return {
					...state,
					hasCompactFit: !state.hasCompactFit,
				}
			case "TOGGLE_OPERATIONS_TEAM_VISIBILITY":
				return {
					...state,
					showOperationsTeam: !state.showOperationsTeam,
				}
			case "CHANGE_TRANSITION_WINDOW_SIZE":
				return {
					...state,
					transitionWindowSize: action.payload,
				}
			default:
				return state
		}
	}, initialPreferences)

	const toggleCompactFit = useCallback(() => {
		dispatch({ type: "TOGGLE_COMPACT_FIT" })
	}, [])
	const toggleOperationsTeamVisibility = useCallback(() => {
		dispatch({ type: "TOGGLE_OPERATIONS_TEAM_VISIBILITY" })
	}, [])
	const changeTransitionWindowSize = useCallback((value: unknown) => {
		const size = ensureValueInAllowedOptions(value)
		dispatch({
			type: "CHANGE_TRANSITION_WINDOW_SIZE",
			payload: size,
		})
	}, [])
	// store the preferences into local storage
	useEffect(() => {
		storeCompactFitStateToStorage(calendarUIPreferences.hasCompactFit)
		storeTransitionWindowSizeToStorage(
			calendarUIPreferences.transitionWindowSize
		)
		storeShowOperationsTeamToStorage(calendarUIPreferences.showOperationsTeam)
	}, [calendarUIPreferences])
	return {
		calendarUIPreferences,
		toggleCompactFit,
		changeTransitionWindowSize,
		toggleOperationsTeamVisibility,
	}
}

export function filtersToRequestParams(
	params: IOperationalBookingFilters & {
		start_date?: Date
		end_date?: Date
	}
) {
	const {
		q,
		page,
		limit,
		sort,
		transport_locations,
		hide_past_trips,
		transport_service_providers,
		drivers,
		transport_service_location_points,
		cab_types,
		status,
		trip_destinations,
		owners,
		travel_activities,
		travel_activity_ticket_types,
		start_date,
		end_date,
		trip_operational_status,
		trips_starting_between_interval,
	} = params

	return {
		q: q ? q.trim() : null,
		page,
		limit,
		sort,
		trip_operational_status,
		transport_locations:
			transport_locations && transport_locations.length
				? transport_locations.map((t) => t.name)
				: [],
		start_date: start_date
			? dateToUTCString(startOf(start_date, "day"))
			: undefined,
		start_date_local: start_date
			? formatDate(start_date, "YYYY-MM-DD")
			: undefined,
		end_date: end_date ? dateToUTCString(endOf(end_date, "day")) : undefined,
		end_date_local: end_date ? formatDate(end_date, "YYYY-MM-DD") : undefined,
		trips_start_date_after:
			trips_starting_between_interval && start_date
				? dateToUTCString(startOf(start_date, "day"))
				: undefined,
		trips_start_date_after_local:
			trips_starting_between_interval && start_date
				? formatDate(start_date, "YYYY-MM-DD")
				: undefined,
		trips_start_date_before:
			trips_starting_between_interval && end_date
				? dateToUTCString(startOf(end_date, "day"))
				: undefined,
		trips_start_date_before_local:
			trips_starting_between_interval && end_date
				? formatDate(end_date, "YYYY-MM-DD")
				: undefined,
		hide_past_trips: hide_past_trips ? 1 : undefined,
		transport_service_providers: transport_service_providers?.length
			? transport_service_providers.map((t) => t.id)
			: [],
		drivers: drivers?.length ? drivers.map((t) => t.id) : [],
		suppliers: transport_service_providers?.length
			? transport_service_providers.map((t) => t.id)
			: [],
		transport_service_location_points: transport_service_location_points?.length
			? transport_service_location_points.map((t) => t.id)
			: [],
		cab_types: cab_types?.length ? cab_types.map((t) => t.id) : [],
		trip_destinations: trip_destinations?.length
			? trip_destinations.map((t) => t.id)
			: [],
		owners: owners?.length ? owners.map((o) => o.id) : [],
		travel_activities: travel_activities?.length
			? travel_activities.map((o) => o.id)
			: [],
		travel_activity_ticket_types: travel_activity_ticket_types?.length
			? travel_activity_ticket_types.map((o) => o.id)
			: [],
		status: status?.id,
	}
}
