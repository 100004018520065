import { Button, Icons, useMounted } from "@sembark-travel/ui/base"
import { queryToSearch } from "@sembark-travel/ui/router"
import { hideSnackbar, showSnackbar } from "@sembark-travel/ui/snackbar"
import { useXHR } from "@sembark-travel/xhr"
import { useCallback, useEffect, useMemo, useReducer, useRef } from "react"
import useSWR from "swr"

type TTemporaryStorage = {
	id: string
	url: string | null
}

export function TemporaryStorage({
	children,
	initUrl,
	initParams,
}: {
	initUrl: string
	initParams: Record<string, unknown>
	children: (props: {
		message?: React.ReactNode
		generate: () => void
		download: () => void
		isGenerating: boolean
		url?: string
		reset: () => void
	}) => React.ReactNode
}) {
	const xhr = useXHR()
	const mounted = useMounted()

	type TState = {
		storageId?: string
		fileUrl?: string
		isGenerating: boolean
		snackbarId?: string
	}

	const [{ fileUrl, storageId, isGenerating, snackbarId }, dispatch] =
		useReducer(
			(state: TState, action: Partial<TState>) => ({ ...state, ...action }),
			{
				storageId: undefined,
				fileUrl: undefined,
				isGenerating: false,
				snackbarId: undefined,
			}
		)

	// remove the storageId and fileUrl after 10 seconds
	useEffect(() => {
		if (!fileUrl) return
		const h = setTimeout(() => {
			dispatch({
				fileUrl: undefined,
				storageId: undefined,
				isGenerating: false,
			})
		}, 10000)
		return () => clearTimeout(h)
	}, [fileUrl])

	const reset = useCallback(
		(timeout: number) => {
			// hide the snackbar immediately
			if (snackbarId) {
				hideSnackbar(snackbarId)
				dispatch({
					snackbarId: undefined,
				})
			}
			// set a timer for the ui to update
			setTimeout(() => {
				if (mounted()) {
					dispatch({
						fileUrl: undefined,
						storageId: undefined,
						isGenerating: false,
					})
				}
			}, timeout)
		},
		[mounted, snackbarId]
	)
	const download = useCallback(() => {
		const url = fileUrl
		if (!fileUrl) {
			return
		}
		window.open(url, "_blank")
		// reset the storage and file url
		setTimeout(() => {
			reset(2000)
		}, 1000)
	}, [fileUrl, reset])

	// reset everything when key changes
	const key = useMemo(() => {
		return initUrl + queryToSearch(initParams || {})
	}, [initUrl, initParams])
	const resetRef = useRef(reset)
	resetRef.current = reset
	useEffect(() => {
		resetRef.current(0)
	}, [key])

	const resetAfterSometime = useCallback(() => {
		resetRef.current(2000)
	}, [resetRef])

	useSWR(
		storageId,
		() =>
			xhr
				.get<{ data: TTemporaryStorage }>(`temporary-storage/${storageId}`)
				.then((resp) => resp.data.data)
				.then((d) => {
					if (mounted()) {
						if (d.url) {
							const url = d.url
							showSnackbar("File ready to download.", {
								id: url,
								duration: 5000,
								position: "bottom-right",
								actions: [
									<Button
										size="sm"
										level="primary"
										as="a"
										href={url}
										target="_blank"
										download
										onClick={resetAfterSometime}
									>
										Download
									</Button>,
								],
							})
							dispatch({
								fileUrl: url,
								snackbarId: url,
								isGenerating: false,
							})
						}
					}
					return d
				})
				.catch((e) => {
					const error: Error = e
					alert(error.message || "Something went wrong. Please true again.")
					resetAfterSometime()
				}),
		!fileUrl
			? {
					refreshInterval: 1000,
				}
			: undefined
	)
	function init() {
		return xhr.get<{ data: TTemporaryStorage }>(initUrl, {
			params: initParams,
		})
	}

	return (
		<>
			{children({
				message: isGenerating ? (
					<>
						<Icons.Document animation="pulse" /> Generating File...
					</>
				) : fileUrl ? (
					<>
						<Icons.OkCircleSolid /> Ready to Download
					</>
				) : null,
				generate: () => {
					if (isGenerating) return
					dispatch({
						isGenerating: true,
					})
					init()
						.then(({ data }) => {
							dispatch({
								storageId: data.data.id,
							})
						})
						.catch((e) => {
							const error: Error = e
							alert(error.message || "Something went wrong. Please true again.")
							dispatch({
								isGenerating: false,
								storageId: undefined,
								fileUrl: undefined,
							})
						})
				},
				isGenerating,
				url: fileUrl,
				download,
				reset: resetAfterSometime,
			})}
		</>
	)
}
