import type { TServiceWiseItinerary } from "../ServiceWiseItineraries"
import type { TMedia } from "../Media"
import type { IUser } from "./../Auth"
import type { TTripDestination } from "../TripDestinations"
import {
	XHRInstance,
	IListResponse,
	ICursorListResponse,
} from "@sembark-travel/xhr"

export type TTransportServiceLocationPoint = {
	id: number
	name: string
	short_name: string
	location_id?: number
}

export type TTransportServiceLocation = {
	id: number
	short_code?: string
	name: string
	short_name: string
	from: TTransportServiceLocationPoint
	to?: TTransportServiceLocationPoint
	services: Array<ITransportService>
	image_url?: string | null
	thumb_image_url?: string | null
	latest_media?: TMedia
	trip_destinations?: Array<TTripDestination>
}

export interface ITransportService {
	id: number
	name: string
	short_name: string
	locations: TTransportServiceLocation
	service: string
	service_short_name?: string
	service_display_name: string
	location_id: number
	from_to: string
	from_to_short: string
	distance?: number
	/** Default Start time of the service */
	start_time?: string
	start_time_formatted?: string
	duration?: number
	end_time?: string
	end_time_formatted?: string
	duration_string: string
	duration_string_short: string
	comments?: string
	service_wise_itineraries?: Array<TServiceWiseItinerary>
	service_wise_itinerary?: TServiceWiseItinerary
	image_url?: string | null
	thumb_image_url?: string | null
	latest_media?: TMedia
	created_at: string
	created_by?: IUser
	deleted_at?: null
	deleted_by?: IUser
}

export function transportServicesXHR(xhr: XHRInstance) {
	return {
		async getTransportServices(
			params?: unknown
		): Promise<IListResponse<ITransportService>> {
			return xhr
				.get("/transport-services", { params })
				.then((resp) => resp.data)
		},
		async getTransportServiceLocations(
			params?: unknown
		): Promise<ICursorListResponse<TTransportServiceLocation>> {
			return xhr
				.get("/transport-service-locations", { params })
				.then((resp) => resp.data)
		},
		async getTransportServiceLocationPoints(
			params?: unknown
		): Promise<IListResponse<TTransportServiceLocationPoint>> {
			return xhr
				.get("/transport-service-location-points", { params })
				.then((resp) => resp.data)
		},
	}
}
