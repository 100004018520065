import {
	AsyncSelect,
	Table,
	Stack,
	RelativeTime,
	Text,
	Box,
	Dropdown,
	Icons,
	Component,
	Alert,
	Spinner,
	Inline,
	Button,
	Divider,
} from "@sembark-travel/ui/base"
import { IListResponse, useXHR, XHRInstance } from "@sembark-travel/xhr"
import { Dialog } from "@sembark-travel/ui/dialog"
import { Omit } from "utility-types"
import { SelectTripDestination, TTripDestination } from "../TripDestinations"
import { ICabType } from "./store"
import {
	areAdvancedFiltersAppliedDefault,
	TSearchParams,
	Search,
	useSearch,
	ListView,
} from "@sembark-travel/ui/list"
import { useLocationQuery } from "@sembark-travel/ui/router"
import { useEffect, useId } from "react"
import {
	Form,
	SelectField,
	SubmissionError,
	SwitchInputField,
	withServerErrors,
} from "@sembark-travel/ui/form"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import useSWR from "swr"
import { PERMISSIONS, useCheckPermissions } from "../Auth"

function XHR(xhr: XHRInstance) {
	return {
		async get(params?: unknown): Promise<IListResponse<ICabType>> {
			return xhr.get("/cab-types", { params }).then(({ data }) => data)
		},
		async show(id: number | string) {
			return xhr
				.get<{ data: ICabType }>(`/cab-types/${id}`)
				.then(({ data }) => data)
		},
		async destroy(id: number | string) {
			return xhr
				.delete<{ message: string }>(`/cab-types/${id}`)
				.then((resp) => resp.data)
		},
		async restore(id: number | string) {
			return xhr
				.post<{ data: ICabType }>(`/cab-types/${id}/restore`)
				.then((resp) => resp.data.data)
		},
		async setTripDestinations(
			id: number | string,
			trip_destinations?: Array<TTripDestination>
		) {
			return xhr
				.patch<{ data: ICabType }>(`/cab-types/${id}/trip-destinations`, {
					trip_destinations: trip_destinations?.map((t) => t.id) || [],
				})
				.then((resp) => resp.data)
		},
	}
}

type TFilters = TSearchParams & {
	archived_only?: boolean
	trip_destinations?: Array<TTripDestination>
}

type TQueryFilters = TSearchParams & {
	archived_only?: 1
	trip_destinations?: Array<string>
}

function filtersToLocationQuery(filters: TFilters): TQueryFilters {
	const { q, archived_only, page, trip_destinations } = filters
	const query: TQueryFilters = {}
	if (q) query.q = q
	if (page) query.page = page
	if (archived_only) query.archived_only = 1
	if (trip_destinations?.length) {
		query.trip_destinations = trip_destinations.map((s) => `${s.id}_${s.name}`)
	}
	return query
}

function queryToFilters(query: TQueryFilters): TFilters {
	const { q, page, archived_only, trip_destinations } = query
	const filters: TFilters = {}
	if (q) filters.q = q
	if (page) filters.page = page
	if (archived_only) filters.archived_only = true
	if (trip_destinations?.length) {
		filters.trip_destinations = trip_destinations.map((s: string) => {
			const [id, ...name] = s.split("_")
			return {
				id: isNaN(parseInt(id)) ? id : parseInt(id),
				name: name.join("_"),
			}
		}) as unknown as TFilters["trip_destinations"]
	}
	return filters
}

export function CabTypesList() {
	const [query, setQuery] = useLocationQuery({
		toQuery: filtersToLocationQuery,
		fromQuery: queryToFilters,
	})
	const [params, setParams] = useSearch(query)
	useEffect(() => {
		setQuery(params)
	}, [setQuery, params])
	const { hasPermission } = useCheckPermissions()
	return (
		<Search
			initialParams={params}
			onSearch={(params) => setParams({ ...params, page: 1 })}
			title="Cab Types"
			resetParams={{
				q: "",
				page: 1,
			}}
			Filters={Filters}
			areAdvancedFiltersApplied={(params) => {
				const { archived_only, ...otherParams } = params
				return (
					areAdvancedFiltersAppliedDefault(otherParams) ||
					Boolean(archived_only)
				)
			}}
		>
			<ListView<ICabType, TFilters>
				pageKey="cab-types"
				params={params}
				fetch={(xhr, params) =>
					XHR(xhr).get({
						...params,
						trip_destinations: params.trip_destinations?.map((t) => t.id),
						include: "created_by,updated_by",
					})
				}
				onPageChange={(page) => setParams({ ...params, page })}
			>
				{({ items, refresh, xhr }) => (
					<Table
						headers={["Name", "Created By", "Last Updated"]
							.concat(params.archived_only ? ["Archived By"] : [])
							.concat([""])}
						bordered
						hover
						responsive
						alignCols={{ [params.archived_only ? 4 : 3]: "right" }}
						rows={items.map((item) =>
							[
								<Stack gap="px">
									<Text>{item.display_name}</Text>
									{item.short_name ? (
										<Text color="muted" fontSize="sm">
											[[{item.short_name}]]
										</Text>
									) : null}
								</Stack>,
								<Stack>
									<Text>{item.created_by?.name}</Text>
									{item.created_at ? (
										<Box>
											<RelativeTime
												timestamp={item.created_at}
												fontSize="sm"
												color="muted"
											/>
										</Box>
									) : null}
								</Stack>,
								item.updated_at !== item.created_at && item.updated_at ? (
									<Stack>
										<Text>{item.updated_by?.name}</Text>
										{item.updated_at ? (
											<Box>
												<RelativeTime
													timestamp={item.updated_at}
													fontSize="sm"
													color="muted"
												/>
											</Box>
										) : null}
									</Stack>
								) : null,
							]
								.concat(
									params.archived_only
										? [
												<Stack>
													<Text>{item.deleted_by?.name}</Text>
													{item.deleted_at ? (
														<Box>
															<RelativeTime
																timestamp={item.deleted_at}
																fontSize="sm"
																color="muted"
															/>
														</Box>
													) : null}
												</Stack>,
											]
										: []
								)
								.concat([
									!hasPermission(
										PERMISSIONS.UPLOAD_BULK_TRANSPORT_SERVICES
									) ? null : (
										<Dropdown alignRight key={item.id}>
											<Dropdown.ToggleButton size="sm" level="tertiary">
												<Icons.DotsVertical />
											</Dropdown.ToggleButton>
											<Dropdown.Menu>
												{!item.deleted_at ? (
													<>
														<Component initialState={false}>
															{({ state, setState }) => (
																<>
																	<Dropdown.MenuItem
																		onClick={async () => {
																			setState(true)
																		}}
																	>
																		Edit Trip Destinations
																	</Dropdown.MenuItem>
																	<Dialog
																		open={state}
																		onClose={() => setState(false)}
																		title="Edit Trip Destinations Mapping"
																		sm
																	>
																		<Dialog.Body>
																			<EditCabTypeTripDestinations
																				cabTypeId={item.id}
																				onSuccess={() => {
																					setState(false)
																					refresh()
																				}}
																				onCancel={() => setState(false)}
																			/>
																		</Dialog.Body>
																	</Dialog>
																</>
															)}
														</Component>
														<Dropdown.MenuItemDivider />
														<Component initialState={false}>
															{({ state, setState }) => (
																<Dropdown.MenuItem
																	color="warning"
																	onClick={async () => {
																		if (state) return
																		if (
																			window.confirm(
																				"Are you sure you want to archive this cab type. Existing data will NOT be affected."
																			)
																		) {
																			setState(true)
																			try {
																				const resp = await XHR(xhr).destroy(
																					item.id
																				)
																				showSnackbar(
																					resp.message ||
																						"Archived successfully"
																				)
																				refresh()
																			} catch (e) {
																				setState(false)
																				const error = e as never as Error
																				window.alert(
																					error.message ||
																						"Something event wrong. Please try after sometime."
																				)
																			}
																		}
																	}}
																>
																	{state ? (
																		"Please wait..."
																	) : (
																		<>
																			<Icons.Trash /> Archive
																		</>
																	)}
																</Dropdown.MenuItem>
															)}
														</Component>
													</>
												) : (
													<Component initialState={false}>
														{({ state, setState }) => (
															<Dropdown.MenuItem
																onClick={async () => {
																	if (state) return
																	if (
																		window.confirm(
																			"Are you sure you want to restore this cab type. Existing data will NOT be affected."
																		)
																	) {
																		setState(true)
																		try {
																			await XHR(xhr).restore(item.id)
																			showSnackbar("Restored successfully")
																			refresh()
																		} catch (e) {
																			setState(false)
																			const error = e as never as Error
																			window.alert(
																				error.message ||
																					"Something event wrong. Please try after sometime."
																			)
																		}
																	}
																}}
															>
																{state ? (
																	"Please wait..."
																) : (
																	<>
																		<Icons.Reply /> Restore
																	</>
																)}
															</Dropdown.MenuItem>
														)}
													</Component>
												)}
											</Dropdown.Menu>
										</Dropdown>
									),
								])
						)}
					/>
				)}
			</ListView>
		</Search>
	)
}

function Filters() {
	return (
		<Stack gap="4">
			<SelectField
				name="trip_destinations"
				select={SelectTripDestination}
				label="Trip Destinations"
				fetchOnMount
				multiple
				placeholder="Select destination(s)..."
			/>
			<SwitchInputField label="Archived only" name="archived_only" />
		</Stack>
	)
}

export function SelectCabTypes({
	tripDestinations,
	...props
}: Omit<React.ComponentProps<typeof AsyncSelect>, "fetch"> & {
	tripDestinations?: Array<TTripDestination>
}) {
	const xhr = useXHR()
	return (
		<>
			<AsyncSelect
				multiple
				cacheKey={`cab-types-${(tripDestinations || [])
					.map((t) => t.id)
					.join("-")}`}
				{...props}
				fetch={(q) =>
					XHR(xhr)
						.get({
							q,
							trip_destinations: tripDestinations?.length
								? tripDestinations.map((t) => t.id)
								: null,
						})
						.then((resp) => resp.data)
				}
			/>
			{props.value?.deleted_at ? (
				<Alert status="warning" inline>
					Cab Type is disabled!
				</Alert>
			) : null}
		</>
	)
}

function EditCabTypeTripDestinations({
	cabTypeId,
	onSuccess,
	onCancel,
}: {
	cabTypeId: number
	onSuccess: () => void
	onCancel: () => void
}) {
	const id = useId()
	const xhr = useXHR()
	const { data } = useSWR(`api/cab-types/${cabTypeId}?${id}`, () =>
		XHR(xhr).show(cabTypeId)
	)
	if (!data) {
		return <Spinner padding="4" alignCenter />
	}
	const { trip_destinations } = data.data
	return (
		<Form
			initialValues={{ trip_destinations: trip_destinations || [] }}
			onSubmit={withServerErrors(async (values) => {
				await XHR(xhr).setTripDestinations(cabTypeId, values.trip_destinations)
				showSnackbar("Trip Destinations updated for the cab type")
				onSuccess()
			})}
		>
			{({ submitting, handleSubmit }) => (
				<form onSubmit={handleSubmit} noValidate>
					<Stack gap="4">
						<SelectField
							select={SelectTripDestination}
							name="trip_destinations"
							label="Trip Destinations"
							multiple
						/>
						<Divider sm />
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Saving..." : "Save Details"}
							</Button>
							<Button
								level="tertiary"
								disabled={submitting}
								onClick={() => onCancel()}
							>
								Cancel
							</Button>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}
