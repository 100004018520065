import { Helmet } from "react-helmet-async"
import { HotelBookings } from "../../../../Hotels/Item"
import { useParams } from "../../../../router-utils"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../../Auth"

export default function HotelBookingsPage() {
	const { hotelId } = useParams("/hotels/:hotelId")
	return (
		<>
			<Helmet>
				<title>Bookings | Hotel</title>
			</Helmet>
			<ForbidUnlessAuthorized
				anyPermissions={[
					PERMISSIONS.VIEW_HOTEL_BOOKINGS,
					PERMISSIONS.MANAGE_ACCOUNTING,
				]}
			>
				<HotelBookings params={{ hotels: [Number(hotelId)] }} />
			</ForbidUnlessAuthorized>
		</>
	)
}
