import {
	Alert,
	Inline,
	Box,
	Container,
	Heading,
	Text,
	Spinner,
	Stack,
	Icons,
	Divider,
	Button,
	Dropdown,
	Component,
	RelativeTime,
} from "@sembark-travel/ui/base"
import { Breadcrumbs, ButtonLink } from "@sembark-travel/ui/router"
import { useXHR } from "@sembark-travel/xhr"
import { Markdown } from "@sembark-travel/ui/markdown"
import useSWR from "swr"
import { generatePath } from "../router-utils"
import { TTravelActivity } from "./store"
import { ImageMediaItem } from "./../Media"
import { Dialog } from "@sembark-travel/ui/dialog"
import { Form, withServerErrors } from "@sembark-travel/ui/form"
import { showSnackbar } from "@sembark-travel/ui/snackbar"

export function TravelActivityDetails({
	travelActivityId,
}: {
	travelActivityId: number | string
}) {
	const xhr = useXHR()
	const { data, error, mutate } = useSWR<TTravelActivity>(
		`/api/travel-activities/${travelActivityId}`,
		() =>
			xhr
				.get(`/travel-activities/${travelActivityId}`, {
					params: {
						include: "latest_media,deleted_ticket_types,trip_destinations",
					},
				})
				.then((resp) => resp.data.data)
	)
	if (!data && error) return <Alert status="error">{error.message}</Alert>
	if (!data) return <Spinner padding="4" alignCenter />
	const {
		id,
		name,
		ticket_types,
		ticket_tourist_configurations,
		complimentary_till_age,
		trip_destinations,
		description,
		latest_media,
		deleted_at,
		created_at,
		created_by,
		deleted_by,
	} = data
	return (
		<Box key={id}>
			<Breadcrumbs
				title="Travel Activity Details"
				items={[
					[generatePath("/travel-activities"), "Travel Activities"],
					["", name],
				]}
				actions={
					<Inline gap="4">
						{!deleted_at ? (
							<ButtonLink
								to={generatePath("/travel-activities/:travelActivityId/edit", {
									travelActivityId: String(id),
								})}
								size="sm"
							>
								<Icons.Pencil /> Edit
							</ButtonLink>
						) : null}
						<Component<"archive" | "restore" | undefined>
							initialState={undefined}
						>
							{({ state, setState }) => (
								<>
									<Dropdown alignRight>
										<Dropdown.ToggleButton size="sm" level="tertiary">
											<Icons.DotsVertical />
										</Dropdown.ToggleButton>
										<Dropdown.Menu>
											{!deleted_at ? (
												<Dropdown.MenuItem onClick={() => setState("archive")}>
													<Icons.Ban /> Disable
												</Dropdown.MenuItem>
											) : (
												<Dropdown.MenuItem onClick={() => setState("restore")}>
													<Icons.Refresh /> Restore
												</Dropdown.MenuItem>
											)}
										</Dropdown.Menu>
									</Dropdown>
									<Dialog
										open={state === "archive"}
										onClose={() => setState(undefined)}
										sm
									>
										<Dialog.Header>
											<Dialog.Title>Disable Travel Activity</Dialog.Title>
										</Dialog.Header>
										<Form<{ travel_activity_id: number }>
											initialValues={{ travel_activity_id: id }}
											onSubmit={withServerErrors(async (values) => {
												const resp = await xhr.delete<{
													data: { message: string }
												}>(`/travel-activities/${values.travel_activity_id}`)
												mutate()
												showSnackbar(resp.data.data.message)
												setState(undefined)
											})}
											subscription={{ submitting: true }}
										>
											{({ submitting, handleSubmit }) => (
												<form noValidate onSubmit={handleSubmit}>
													<Dialog.Body>
														<Text>
															This Travel Activity will be removed from all
															travel-activities listing and selection pages.
															Existing usage of this Travel Activity will NOT be
															affected.
														</Text>
														<Divider sm />
														<Text color="warning">
															Are you sure you want to disable this Travel
															Activity ?
														</Text>
													</Dialog.Body>
													<Dialog.Footer>
														<Button
															type="submit"
															disabled={submitting}
															status="warning"
														>
															{submitting
																? "Disabling..."
																: "I understand, disabled it."}
														</Button>
														<Button
															onClick={() => setState(undefined)}
															disabled={submitting}
														>
															Cancel
														</Button>
													</Dialog.Footer>
												</form>
											)}
										</Form>
									</Dialog>
									<Dialog
										open={state === "restore"}
										onClose={() => setState(undefined)}
										sm
									>
										<Dialog.Header>
											<Dialog.Title>
												Restore disabled Travel Activity
											</Dialog.Title>
										</Dialog.Header>
										<Form<{ travel_activity_id: number }>
											initialValues={{ travel_activity_id: id }}
											onSubmit={withServerErrors(async (values) => {
												const resp = await xhr.patch<{
													data: { message: string }
												}>(
													`/travel-activities/${values.travel_activity_id}/restore`
												)
												mutate()
												showSnackbar(resp.data.data.message)
												setState(undefined)
											})}
											subscription={{ submitting: true }}
										>
											{({ submitting, handleSubmit }) => (
												<form noValidate onSubmit={handleSubmit}>
													<Dialog.Body>
														<Text>
															Are you sure you want to restore this disabled
															Travel Activity ?
														</Text>
													</Dialog.Body>
													<Dialog.Footer>
														<Button type="submit" disabled={submitting}>
															{submitting ? "Restoring..." : "Restore"}
														</Button>
														<Button
															onClick={() => setState(undefined)}
															disabled={submitting}
														>
															Cancel
														</Button>
													</Dialog.Footer>
												</form>
											)}
										</Form>
									</Dialog>
								</>
							)}
						</Component>
					</Inline>
				}
			/>
			<Container paddingY="6" bgColor="default">
				<Stack gap="4">
					<Inline gap="4" justifyContent="between" collapseBelow="md">
						<Stack gap="4">
							<Stack as="header" gap="1">
								<Heading as="h2">{name}</Heading>
							</Stack>
							<Inline gap="4">
								<Stack gap="1">
									<Text color="muted" fontSize="sm">
										Trip Destinations
									</Text>
									<Text>
										{trip_destinations?.length
											? trip_destinations.map((t) => t.name).join(", ")
											: "All"}
									</Text>
								</Stack>
								<Stack gap="1">
									<Text color="muted" fontSize="sm">
										Tickets For
									</Text>
									<Text>
										{ticket_tourist_configurations
											.map((t) => t.name)
											.join(", ")}
									</Text>
								</Stack>
								<Stack gap="1">
									<Text color="muted" fontSize="sm">
										Complimentary Till
									</Text>
									{complimentary_till_age ? (
										<Text>{complimentary_till_age} yrs</Text>
									) : (
										<Text color="muted">N/A</Text>
									)}
								</Stack>
							</Inline>
							{description ? (
								<Box>
									<Markdown>{description}</Markdown>
								</Box>
							) : null}
							<Inline gap="4" flexWrap="wrap">
								<Stack gap="1">
									<Text color="muted" fontSize="sm">
										Created By
									</Text>
									<Text>{created_by?.name || "You"}</Text>
									<Text fontSize="sm" color="muted">
										<RelativeTime timestamp={created_at} />
									</Text>
								</Stack>
								{deleted_at ? (
									<Stack gap="1">
										<Text color="warning" fontSize="sm">
											Disabled By <Icons.Ban />
										</Text>
										<Text color="warning" fontWeight="semibold">
											{deleted_by?.name || "N/A"}
										</Text>
										<Text fontSize="sm" color="muted">
											<RelativeTime timestamp={deleted_at} />
										</Text>
									</Stack>
								) : null}
							</Inline>
						</Stack>
						<Stack gap="4" style={{ maxWidth: "400px" }}>
							<ImageMediaItem
								media={latest_media}
								help="Image must be 1200x600 JPEG/PNG file with less than 1 MB in size."
								crop={{
									aspect: 2 / 1,
									minHeight: 100,
								}}
								canRemove
								canEdit={!deleted_at}
								onSubmit={async (data) => {
									await xhr.patch(`/travel-activities/${id}/image`, data)
									await mutate()
								}}
							/>
							<Text color="muted" fontSize="sm">
								<Icons.Info /> Fallback image when individual tickets/packages
								image is not available.
							</Text>
						</Stack>
					</Inline>
					{ticket_types?.length ? (
						<>
							<Divider sm />
							<Stack gap="6">
								<Heading as="h4">Tickets/Packages</Heading>
								<Stack gap="2" as="ul">
									{ticket_types.map(
										({
											id,
											name,
											display_name,
											short_name,
											durations,
											description,
											latest_media,
											closed_on_days_or_dates,
										}) => (
											<Box key={id} as="li">
												<Inline>
													<Stack gap="2" flex="1">
														<Stack gap="1">
															<Heading as="h5" fontSize="md">
																{display_name || name}{" "}
																{short_name ? (
																	<Text as="span" color="muted">
																		[[{short_name}]]
																	</Text>
																) : null}
															</Heading>
															<Inline gap="4">
																{durations ? (
																	<Text fontSize="sm" color="muted">
																		{durations
																			.map(
																				({ name, slots }) =>
																					`${name}${
																						slots?.length
																							? ` (${slots.join(", ")})`
																							: ``
																					}`
																			)
																			.join(" | ")}
																	</Text>
																) : null}
															</Inline>
														</Stack>
														{closed_on_days_or_dates?.length ? (
															<Text
																color="warning"
																fontSize="sm"
																fontWeight="semibold"
																title="Closed on Days of Week or Dates"
															>
																<Icons.Ban /> :{" "}
																{closed_on_days_or_dates.join(", ")}
															</Text>
														) : null}
														{!description ? (
															<Text color="muted">Itinerary N/A</Text>
														) : (
															<Box>
																<Markdown>{description}</Markdown>
															</Box>
														)}
													</Stack>
													<Stack gap="4" style={{ maxWidth: "400px" }}>
														<ImageMediaItem
															media={latest_media}
															help="Image must be 1200x600 JPEG/PNG file with less than 1 MB in size."
															crop={{
																aspect: 2 / 1,
																minHeight: 100,
															}}
															canRemove
															onSubmit={async (data) => {
																await xhr.patch(
																	`/travel-activity-ticket-types/${id}/image`,
																	data
																)
																await mutate()
															}}
														/>
													</Stack>
												</Inline>
											</Box>
										)
									)}
								</Stack>
							</Stack>
						</>
					) : null}
				</Stack>
			</Container>
		</Box>
	)
}
