import { Inline, Dropdown, Icons } from "@sembark-travel/ui/base"
import { NavLink, ButtonLink } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { PERMISSIONS, useCheckPermissions } from "../../../Auth"
import { generatePath } from "../../../router-utils"
import { HotelsList } from "./../../../Hotels"

export default function Hotels() {
	const { hasPermission } = useCheckPermissions()
	return (
		<>
			<Helmet>
				<title>Hotels</title>
			</Helmet>
			<HotelsList
				actions={
					<Inline gap="2">
						<ButtonLink to={generatePath("/hotels/new")}>Add New</ButtonLink>
						<Dropdown alignRight="sm">
							<Dropdown.ToggleButton level="tertiary">
								<Icons.DotsVertical />
							</Dropdown.ToggleButton>
							<Dropdown.Menu>
								<NavLink to={generatePath("/hotel-groups")}>
									Hotel Groups
								</NavLink>
								{hasPermission(PERMISSIONS.UPLOAD_BULK_HOTELS) ? (
									<>
										<NavLink to={generatePath("/meal-plans")}>
											Meal Plans
										</NavLink>
										<NavLink to={generatePath("/room-types")}>
											Room Types
										</NavLink>
									</>
								) : null}
								{hasPermission(PERMISSIONS.VIEW_HOTEL_BOOKINGS) ? (
									<NavLink to={generatePath("/hotel-payment-preferences")}>
										Payment Preferences
									</NavLink>
								) : null}
								{hasPermission(PERMISSIONS.UPLOAD_BULK_HOTELS) ? (
									<>
										<Dropdown.MenuItemDivider />
										<NavLink to={generatePath("/hotels/merge")}>
											Merge Hotels
										</NavLink>
									</>
								) : null}
							</Dropdown.Menu>
						</Dropdown>
					</Inline>
				}
			/>
		</>
	)
}
