import { Link } from "@sembark-travel/ui/router"
import { IInstalment } from "./store"
import { generatePath } from "./../router-utils"
import {
	Badge,
	Box,
	Time,
	TimeDuration,
	joinAttributes,
	Ping,
} from "@sembark-travel/ui/base"
import {
	formatDate,
	localOrUtcTimestampToLocalDate,
} from "@sembark-travel/datetime-utils"
import { Email, PhoneNumber } from "../Contacts"
import { IQuoteRequest } from "../QuoteRequests"

export function InstalmentContact({ instalment }: { instalment: IInstalment }) {
	const { payment } = instalment
	let regarding: React.ReactNode = null
	let quoteRequest: IQuoteRequest
	if (payment.paymentable) {
		switch (payment.paymentable_type) {
			case "trips":
				regarding = (
					<Box>
						<Link
							to={`${generatePath("/trips/:tripId/accounting", {
								tripId: `${payment.paymentable_id}`,
							})}#${instalment.id}instalment`}
							color="accent"
							fontWeight="semibold"
						>
							{payment.paymentable.name}
						</Link>
						<Box fontSize="sm" color="muted">
							{joinAttributes(
								<Time
									value={localOrUtcTimestampToLocalDate(
										payment.paymentable.start_date_local,
										payment.paymentable.start_date
									)}
									format="DD MMM"
								/>,
								<TimeDuration days={payment.paymentable.days}>
									{formatDate(
										localOrUtcTimestampToLocalDate(
											payment.paymentable.end_date_local,
											payment.paymentable.end_date
										),
										"DD MMM"
									)}
								</TimeDuration>,
								payment.paymentable.contact?.phone_number ? (
									<PhoneNumber
										value={payment.paymentable.contact?.phone_number}
										iconOnly
									/>
								) : null,
								payment.paymentable.contact?.email ? (
									<Email value={payment.paymentable.contact?.email} iconOnly />
								) : null,
								payment.paymentable.is_live ? <Ping fontSize="xs" /> : null,
								!payment.paymentable.converted_at ? (
									<Badge warning>On-Hold Trip</Badge>
								) : null
							)}
						</Box>
					</Box>
				)
				break
			case "hotel_bookings":
				regarding = (
					<Box>
						<Link
							to={`${generatePath(
								"/trips/:tripId/services-bookings/:serviceType",
								{
									tripId: payment.paymentable?.trip_id,
									serviceType: "hotels",
								}
							)}#${payment.paymentable_id}booking`}
							color="accent"
							fontWeight="semibold"
						>
							{joinAttributes(
								payment.paymentable.hotel?.name || "Hotel Booking",
								"Trip " + payment.paymentable?.trip_id
							)}
						</Link>
						<Box fontSize="sm" color="muted">
							{joinAttributes(
								payment.paymentable.hotel?.location?.short_name,
								payment.paymentable?.trip?.contact?.name,
								payment.paymentable.booking_method,
								!payment.paymentable?.trip?.converted_at ? (
									<Badge warning>On-Hold Trip</Badge>
								) : null
							)}
						</Box>
					</Box>
				)
				break
			case "scheduled_cabs":
				regarding = (
					<Box>
						<Link
							to={`${generatePath(
								"/trips/:tripId/services-bookings/:serviceType",
								{
									tripId: payment.paymentable?.cab_schedule?.trip_id,
									serviceType: "cabs",
								}
							)}#${payment.paymentable_id}booking`}
							color="accent"
							fontWeight="semibold"
						>
							{joinAttributes(
								payment.paymentable?.transport_service_provider?.name,
								[
									"Trip " + payment.paymentable?.cab_schedule?.trip_id,
									payment.paymentable?.cab_schedule?.trip?.contact?.name,
								]
									.filter(Boolean)
									.join(" - ")
							)}
						</Link>
						<Box fontSize="sm" color="muted">
							{joinAttributes(
								payment.paymentable?.cab_schedule?.transport_service?.short_name
							)}
						</Box>
					</Box>
				)
				break
			case "travel_activity_bookings":
				regarding = (
					<Box>
						<Link
							to={`${generatePath(
								"/trips/:tripId/services-bookings/:serviceType",
								{
									tripId: payment.paymentable?.trip_id,
									serviceType: "operationals",
								}
							)}#${payment.paymentable_id}booking`}
							color="accent"
							fontWeight="semibold"
						>
							{joinAttributes(
								payment.paymentable?.supplier?.name,
								[
									"Trip " + payment.paymentable?.trip_id,
									payment.paymentable?.trip?.contact?.name,
								]
									.filter(Boolean)
									.join(" - ")
							)}
						</Link>
						<Box fontSize="sm" color="muted">
							{joinAttributes(
								[
									payment.paymentable?.activity?.name,
									payment.paymentable?.ticket_type?.name,
								]
									.filter(Boolean)
									.join(" - ")
							)}
						</Box>
					</Box>
				)
				break
			case "quote_requests":
				quoteRequest = payment.paymentable
				regarding = (
					<Box>
						<Box>
							{quoteRequest.is_incoming
								? quoteRequest.from.name
								: quoteRequest.to.name}
						</Box>
						<Link
							to={`${generatePath("/trips/:tripId/accounting/payments", {
								tripId:
									(quoteRequest.is_incoming
										? quoteRequest.created_trip_id?.toString()
										: quoteRequest.trip_id.toString()) || "",
							})}#${payment.paymentable_id}booking`}
							color="accent"
							fontWeight="semibold"
						>
							{joinAttributes(
								"B2B",
								`Trip #${
									quoteRequest.is_incoming
										? quoteRequest.created_trip_id
										: quoteRequest.trip_id
								}`
							)}
						</Link>
					</Box>
				)
				break
			default:
				regarding = null
		}
	}
	return <Box>{regarding}</Box>
}
