import {
	Alert,
	Inline,
	Box,
	Container,
	Heading,
	Text,
	joinAttributes,
	Spinner,
	Stack,
	Grid,
	Col,
	Icons,
	Time,
	CheckboxInput,
	Dropdown,
	Divider,
	Button,
} from "@sembark-travel/ui/base"
import { Dialog, useDialog } from "@sembark-travel/ui/dialog"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { Markdown } from "@sembark-travel/ui/markdown"
import { useXHR } from "@sembark-travel/xhr"
import useSWR from "swr"
import { generatePath } from "../router-utils"
import { ITransportService, TTransportServiceLocation } from "./store"
import { ImageMediaItem } from "./../Media"
import { useState, useEffect } from "react"
import {
	Form,
	SubmissionError,
	withServerErrors,
} from "@sembark-travel/ui/form"
import { PERMISSIONS, useCheckPermissions } from "../Auth"

export function ServiceLocationDetails({
	serviceLocationId,
}: {
	serviceLocationId: number | string
}) {
	const xhr = useXHR()
	const [areDeletedVisible, toggleDeletedVisibility] = useState(false)
	const { hasPermission } = useCheckPermissions()
	const { data, error, mutate } = useSWR<TTransportServiceLocation>(
		`/api/transport-service-locations/${serviceLocationId}`,
		() =>
			xhr
				.get(`/transport-service-locations/${serviceLocationId}`, {
					params: {
						include:
							"latest_media,services.service_wise_itineraries,services.latest_media,deleted_services,trip_destinations",
					},
				})
				.then((resp) => resp.data.data)
	)
	const [nonDeletedServices, deletedServices] = (data?.services || []).reduce<
		[Array<ITransportService>, Array<ITransportService>]
	>(
		([nonDeletedServices, deletedServices], s) => {
			if (s.deleted_at) {
				deletedServices.push(s)
			} else {
				nonDeletedServices.push(s)
			}
			return [nonDeletedServices, deletedServices]
		},
		[[], []]
	)
	const deletedServicesCount = deletedServices.length
	useEffect(() => {
		if (deletedServicesCount === 0 && areDeletedVisible) {
			toggleDeletedVisibility(false)
		}
	}, [deletedServicesCount, areDeletedVisible])
	if (!data && error) return <Alert status="error">{error.message}</Alert>
	if (!data) return <Spinner padding="4" alignCenter />
	const {
		id,
		short_code,
		name,
		short_name,
		services,
		latest_media,
		trip_destinations,
	} = data
	return (
		<Box key={id}>
			<Breadcrumbs
				title="Service Details"
				items={[
					[generatePath("/transport-services"), "Transport Services"],
					["", short_name],
				]}
			/>
			<Container paddingY="6" bgColor="default">
				<Inline gap="4" justifyContent="between" collapseBelow="md">
					<Stack gap="4">
						<Stack as="header" gap="1">
							<Heading as="h2">{name}</Heading>
							<Text color="muted">
								{joinAttributes(short_code, short_name)}
							</Text>
						</Stack>
						<Stack as="header" gap="1">
							<Text fontSize="sm">Trip Destinations</Text>
							<Text color="muted">
								{trip_destinations?.length
									? trip_destinations.map((t) => t.name).join(", ")
									: "All"}
							</Text>
						</Stack>
					</Stack>
					<Stack gap="4" style={{ maxWidth: "700px" }}>
						<ImageMediaItem
							media={latest_media}
							help="Image must be 1200x600 JPEG/PNG file with less than 1 MB in size."
							crop={{
								aspect: 2 / 1,
								minHeight: 100,
							}}
							canRemove
							onSubmit={async (data) => {
								await xhr.patch(
									`/transport-service-locations/${id}/image`,
									data
								)
								await mutate()
							}}
						/>
						<Text color="muted" fontSize="sm">
							<Icons.Info /> Fallback image when individual services image is
							not available.
						</Text>
					</Stack>
				</Inline>
			</Container>
			{services?.length ? (
				<Container>
					<Stack paddingY="8" gap="4">
						<Inline justifyContent="between" gap="2">
							<Heading as="h4">Services</Heading>
							{deletedServices.length ? (
								<Inline as="label" alignItems="center" gap="2">
									<CheckboxInput
										checked={areDeletedVisible}
										onChange={() => toggleDeletedVisibility(!areDeletedVisible)}
									/>
									<Text as="span">Show Disabled ({deletedServicesCount})</Text>
								</Inline>
							) : null}
						</Inline>
						<Stack as="ul" gap="4">
							{(areDeletedVisible ? deletedServices : nonDeletedServices).map(
								(s) => {
									const firstActivityService = s.service_wise_itineraries?.at(0)
									return (
										<Box
											as="li"
											key={s.id}
											borderWidth="1"
											bgColor={s.deleted_at ? "warning" : "default"}
											borderColor={s.deleted_at ? "warning" : "default"}
											padding="4"
											rounded="md"
										>
											<Grid gap="4">
												<Col>
													<Stack gap="4">
														<Stack maxWidth="4xl" gap="2">
															<Inline gap="4" justifyContent="between">
																<Stack gap="1">
																	<Text fontWeight="semibold" fontSize="md">
																		{firstActivityService
																			? firstActivityService.name
																			: s.name}
																	</Text>
																	{s.service_short_name ? (
																		<Text fontWeight="semibold">
																			[[{s.service_short_name}]]
																		</Text>
																	) : null}
																	<Box
																		fontSize="sm"
																		fontWeight="semibold"
																		color="muted"
																	>
																		{joinAttributes(
																			s.distance ? `${s.distance} kms` : null,
																			s.start_time_formatted
																				? `Starts: ${s.start_time_formatted}`
																				: null,
																			s.duration_string || null,
																			s.end_time_formatted
																				? `Ends: ${s.end_time_formatted}`
																				: null
																		)}
																	</Box>
																</Stack>
																{hasPermission(
																	PERMISSIONS.UPLOAD_BULK_TRANSPORT_SERVICES
																) ? (
																	<Box>
																		<DeleteInDialog
																			service={s}
																			onSuccess={mutate}
																		>
																			{({ openDeletionDialog }) => (
																				<Dropdown alignRight>
																					<Dropdown.ToggleButton
																						size="sm"
																						level="tertiary"
																					>
																						<Icons.DotsVertical />
																					</Dropdown.ToggleButton>
																					<Dropdown.Menu>
																						{!s.deleted_at ? (
																							<Dropdown.MenuItem
																								onClick={openDeletionDialog}
																							>
																								<Icons.Ban color="warning" />{" "}
																								Disable Service
																							</Dropdown.MenuItem>
																						) : (
																							<Dropdown.MenuItem
																								onClick={async () => {
																									if (
																										window.confirm(
																											"Are you sure you want to enable/restore this service ?"
																										)
																									) {
																										await xhr.patch(
																											`/transport-services/${s.id}/restore`
																										)
																										showSnackbar(
																											"Service restored successfully."
																										)
																										mutate()
																									}
																								}}
																							>
																								<Icons.Refresh color="success" />{" "}
																								Restore
																							</Dropdown.MenuItem>
																						)}
																					</Dropdown.Menu>
																				</Dropdown>
																			)}
																		</DeleteInDialog>
																	</Box>
																) : null}
															</Inline>
															{firstActivityService ? (
																<Box color="muted">
																	<Markdown>
																		{firstActivityService.description}
																	</Markdown>
																</Box>
															) : null}
														</Stack>
														<Inline
															gap={{ xs: "1", sm: "6" }}
															collapseBelow="sm"
														>
															<Inline gap="2" alignItems="baseline">
																<Box>
																	<Icons.User />
																</Box>
																<Box>
																	<Text fontSize="sm">
																		Created by {s.created_by?.name} on{" "}
																		<Time timestamp={s.created_at} />
																	</Text>
																</Box>
															</Inline>
															{s.deleted_at ? (
																<Inline gap="2" alignItems="baseline">
																	<Box>
																		<Icons.Ban />
																	</Box>
																	<Text fontSize="sm">
																		Disabled by {s.deleted_by?.name} on{" "}
																		<Time timestamp={s.deleted_at} />
																	</Text>
																</Inline>
															) : null}
														</Inline>
													</Stack>
												</Col>
												<Col xs={12} sm={4} lg={3}>
													<ImageMediaItem
														media={s.latest_media}
														help="Image must be 1200x600 JPEG/PNG file with less than 1 MB in size."
														crop={{
															aspect: 2 / 1,
															minHeight: 100,
														}}
														canRemove
														onSubmit={async (data) => {
															await xhr.patch(
																`/transport-services/${s.id}/image`,
																data
															)
															await mutate()
														}}
													/>
												</Col>
											</Grid>
										</Box>
									)
								}
							)}
						</Stack>
					</Stack>
				</Container>
			) : null}
		</Box>
	)
}

function DeleteInDialog({
	service,
	children,
	onSuccess,
}: {
	service: ITransportService
	children: (props: { openDeletionDialog: () => void }) => React.ReactNode
	onSuccess: () => void
}) {
	const xhr = useXHR()
	const [isOpen, openDialog, closeDialog] = useDialog()
	return (
		<>
			{children({ openDeletionDialog: openDialog })}
			<Dialog
				open={isOpen}
				onClose={closeDialog}
				title="Disable Transport Service"
			>
				<Form
					initialValues={{ service: service }}
					onSubmit={withServerErrors(async ({ service }) => {
						await xhr.delete(`/transport-services/${service.id}`)
						showSnackbar("Service successfully disabled.")
						onSuccess()
						closeDialog()
					})}
				>
					{({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Dialog.Body>
								<Stack gap="4">
									<Box>
										<Heading as="h4" fontSize="md">
											Preview Details
										</Heading>
										<Inline
											gap="4"
											padding="4"
											borderWidth="1"
											rounded="md"
											flexWrap="wrap"
										>
											<Box>
												{service.service_wise_itineraries
													?.slice(0, 1)
													.map((a) => (
														<Stack key={a.id} maxWidth="4xl" gap="2">
															<Inline gap="4" justifyContent="between">
																<Stack gap="1">
																	<Text fontWeight="semibold" fontSize="md">
																		{a.name}
																	</Text>
																	<Box
																		fontSize="sm"
																		fontWeight="semibold"
																		color="muted"
																	>
																		{joinAttributes(
																			service.distance
																				? `${service.distance} kms`
																				: null,
																			service.start_time_formatted
																				? `Starts: ${service.start_time_formatted}`
																				: null,
																			service.duration_string,
																			service.end_time_formatted
																				? `Ends: ${service.end_time_formatted}`
																				: null
																		)}
																	</Box>
																</Stack>
															</Inline>
															<Box color="muted">
																<Markdown>{a.description}</Markdown>
															</Box>
														</Stack>
													))}
											</Box>
										</Inline>
									</Box>
									<Divider sm />
									<Alert
										title="Are your sure want to disabled this service ? Disabling the service:"
										status="warning"
									>
										<Stack as="ul" listStyleType="disc" paddingLeft="6" gap="1">
											<Box as="li">Will disable this transport service</Box>
											<Box as="li">
												Will exclude the packages, containing this disabled
												service, from suggession on quote creation
											</Box>
											<Box as="li">
												Will hide the service from selection at the time of
												package creation
											</Box>
										</Stack>
									</Alert>
								</Stack>
							</Dialog.Body>
							<Dialog.Footer>
								<Stack gap="4">
									<SubmissionError />
									<Inline gap="4">
										<Button
											disabled={submitting}
											type="submit"
											status="warning"
										>
											{submitting
												? "Disabling..."
												: "I understand, disable it."}
										</Button>
										<Button disabled={submitting} onClick={closeDialog}>
											Cancel
										</Button>
									</Inline>
								</Stack>
							</Dialog.Footer>
						</form>
					)}
				</Form>
			</Dialog>
		</>
	)
}
