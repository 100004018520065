import {
	Box,
	Button,
	Inline,
	Icons,
	Text,
	Stack,
	Col,
	Grid,
	joinAttributes,
	Spinner,
	Divider,
	Heading,
	Time,
	Stars,
	Badge,
	Money,
	Alert,
	Dropdown,
} from "@sembark-travel/ui/base"
import {
	dateToUTCString,
	getDiff,
	utcTimestampToLocalDate,
	addUnit,
	localOrUtcTimestampToLocalDate,
	getDiffBetweenLocalAndUtcTimestamp,
	formatDate,
} from "@sembark-travel/datetime-utils"
import { ButtonLink, Link } from "@sembark-travel/ui/router"
import { useXHR } from "@sembark-travel/xhr"
import useSWR from "swr"
import { PERMISSIONS, useCheckPermissions } from "../Auth"
import { Email, PhoneNumber } from "../Contacts"
import { generatePath } from "../router-utils"
import { Dialog, useDialog } from "@sembark-travel/ui/dialog"
import { TTripOperationalBooking } from "./store"
import { withOrdinalSuffix } from "@sembark-travel/number-utils"
import { useCallback, useMemo, useState } from "react"
import { AddOperationalBookingsForTripInDialog } from "./AddOperationalBookings"
import { ShareTripOperationalBookings } from "./ShareOperationalBookings"
import {
	DeleteCabScheduleInDialog,
	EditScheduleInDialog,
	getEndDateTimeOfCabSchedule,
	getStartDateTimeOfCabSchedule,
} from "../CabScheduling"
import { Markdown } from "@sembark-travel/ui/markdown"
import {
	DeleteTravelActivityBookingInDialog,
	EditTravelActivityBookingItemInDialog,
	TTravelActiviytBooking,
} from "../TravelActivityBookings"
import {
	FieldArray,
	Form,
	GetFieldValue,
	SubmissionError,
	arrayMutators,
	isTruthy,
	withServerErrors,
} from "@sembark-travel/ui/form"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { Optional } from "utility-types"
import { collect } from "../utils"
import { BookingAmount } from "./Item"

type TOperationalBookingProps = {
	tripId: number | string
	tripStartDateUtc: string
	date: Date
	day: number
	onRefresh?: () => unknown
	showQuoteBookingDiffWarning?: boolean
}

export function TripOperationalBookingsItemForDateInDialog({
	children,
	...props
}: TOperationalBookingProps & {
	children: (props: { show: () => void }) => React.ReactNode
}) {
	const [isOpen, openDialog, closeDialog] = useDialog()
	return (
		<>
			{children({ show: openDialog })}
			<Dialog
				open={isOpen}
				onClose={closeDialog}
				title="Trip's Day Services"
				xl
			>
				<Dialog.Body>
					<TripOperationalBookingsItemForDate {...props} />
				</Dialog.Body>
			</Dialog>
		</>
	)
}

export function TripOperationalBookingsItemForDate(
	props: TOperationalBookingProps
) {
	const {
		tripId,
		date,
		day,
		tripStartDateUtc,
		onRefresh,
		showQuoteBookingDiffWarning,
	} = props
	const xhr = useXHR()
	const { hasPermission } = useCheckPermissions()
	const startDateLocal = addUnit(
		utcTimestampToLocalDate(tripStartDateUtc),
		day - 1,
		"day"
	)
	const {
		data: trip,
		mutate,
		isValidating,
	} = useSWR<TTripOperationalBooking>(
		`/trip-operational-bookings/${tripId}?${date.toISOString()}&quote-booking-diff=${showQuoteBookingDiffWarning ? 1 : 0}`,
		() =>
			xhr
				.get(`/trip-operational-bookings/${tripId}`, {
					params: {
						start_date_local: formatDate(date, "YYYY-MM-DD"),
						end_date_local: formatDate(date, "YYYY-MM-DD"),
						start_date: dateToUTCString(startDateLocal),
						end_date: dateToUTCString(
							addUnit(
								addUnit(addUnit(startDateLocal, 23, "hours"), 59, "minute"),
								59,
								"seconds"
							)
						),
						include: [
							"itinerary",
							"hotels",
							"arrival_departure",
							"due_payments_count",
							"operations_team",
							showQuoteBookingDiffWarning ? "quote_booking_diff" : "",
						]
							.filter(Boolean)
							.join(","),
					},
				})
				.then((resp) => resp.data.data)
	)
	const revalidate = useCallback(() => {
		mutate()
		onRefresh?.()
	}, [onRefresh, mutate])
	if (!trip) return <Spinner padding="4" alignCenter />
	const {
		daywise_services,
		is_shared,
		cancellation_reason,
		can_view_prices,
		start_date,
		start_date_local,
		quote_booking_diff,
	} = trip
	const hidePrice = !can_view_prices
	const selectedDay =
		getDiff(
			date,
			localOrUtcTimestampToLocalDate(start_date_local, start_date),
			"day"
		) + 1
	const timezoneDiff = getDiffBetweenLocalAndUtcTimestamp(
		trip.start_date_local,
		trip.start_date
	)
	return (
		<Stack gap="4" cursor={isValidating ? "wait" : undefined}>
			{showQuoteBookingDiffWarning &&
			(quote_booking_diff?.hotels_changed ||
				quote_booking_diff?.cabs_changed) ? (
				<Alert
					status="warning"
					title="Services details (Hotels/Cabs/Tickets) from Latest Quote and Latest Bookings are not up-to-date with each other."
				>
					<Stack gap="2">
						<Text>
							Some services provided in the latest quote have been updated in
							the booking process or vice-versa. Please check the services in
							quote and bookings, and update the details as per the
							requirements.
						</Text>
						<Box>
							<ButtonLink
								size="sm"
								status="warning"
								to={generatePath(
									"/trips/:tripId/services-bookings/:serviceType",
									{
										tripId: trip.id.toString(),
										serviceType: "operational",
									}
								)}
							>
								View Trip Details <Icons.ChevronDown rotate="270" />
							</ButtonLink>
						</Box>
					</Stack>
				</Alert>
			) : null}
			<Inline gap="8" flexWrap="wrap">
				<Stack>
					<Heading>{withOrdinalSuffix(selectedDay)} Day</Heading>
					<Text>
						<Time value={date} format="dddd DD MMM, YYYY" />
					</Text>
				</Stack>
				<Inline gap="2">
					<Box>
						<Icons.User color="muted" size="6" />
					</Box>
					<Stack gap="1">
						<Inline flexWrap="wrap" gap="2" alignItems="center">
							{trip.tourist ? (
								<Text fontWeight="semibold" fontSize="md">
									{trip.tourist.name}
								</Text>
							) : (
								<Text color="warning" fontSize="sm">
									<Icons.Attention /> Guest details missing!
								</Text>
							)}
							<Box color="muted" fontSize="sm">
								{joinAttributes(
									trip.tourist?.phone_numbers?.length ? (
										<PhoneNumber value={trip.contact.phone_numbers} iconOnly />
									) : null,
									trip.tourist?.email ? (
										<Email value={trip.contact.email} iconOnly />
									) : null,
									`${trip.no_of_adults}A${
										trip.children.length ? `, ${trip.children.length}C` : ``
									}`
								)}
							</Box>
						</Inline>
						<Box color="muted">
							{joinAttributes(
								<Link
									to={generatePath(
										"/trips/:tripId/services-bookings/:serviceType",
										{
											tripId: trip.id.toString(),
											serviceType: "operational",
										}
									)}
									color="accent"
								>
									Trip {trip.id}
								</Link>,
								trip.trip_source.short_name,
								trip.trip_source_contact ? (
									<PhoneNumber
										value={trip.trip_source_contact.phone_numbers}
										iconOnly
									/>
								) : null
							)}
						</Box>
					</Stack>
				</Inline>
				<Stack gap="1">
					<Text color="muted">Payment Status</Text>
					{trip.due_payments_count === undefined ||
					trip.due_payments_count === null ? (
						<Text>N/A</Text>
					) : trip.due_payments_count > 0 ? (
						<Box>
							<Badge danger>Payments Due</Badge>
						</Box>
					) : (
						<Box>
							<Badge success title="Payments Received">
								Cleared
							</Badge>
						</Box>
					)}
				</Stack>
				<Stack gap="1">
					<Text color="muted">Ops Team</Text>
					{trip.operations_team?.length ? (
						<Text fontSize="sm" textOverflow="truncate">
							{trip.operations_team
								.map((t, _, arr) =>
									arr.length > 1 ? t.name.slice(0, 4) : t.name
								)
								.join(", ")}
						</Text>
					) : (
						<Text fontSize="xs">N/A</Text>
					)}
				</Stack>
			</Inline>
			{timezoneDiff ? (
				<>
					<Divider marginY={"0"} />
					<Alert
						status="warning"
						title="Trip details in Different Timezone"
						rounded="none"
					>
						The Timezone of this trip differs from your local timezone by{" "}
						{timezoneDiff} mins. Please avoid modifying details of this trip.
					</Alert>
				</>
			) : null}
			<Divider marginY={"0"} />
			<Inline justifyContent="between">
				<Heading>Booking Services</Heading>
				{daywise_services.length &&
				Number(daywise_services[0].services?.length || 0) > 1 ? (
					<ReorderServicesInDialog
						tripId={trip.id}
						services={daywise_services[0].services}
						onSuccess={() => revalidate()}
						initialOrdering={daywise_services[0].services_ordered_by_start_time}
					>
						{({ onReorder: onAdd }) => (
							<Button
								size="sm"
								onClick={() => onAdd()}
								level={
									daywise_services[0].services_needs_reordering
										? "primary"
										: "secondary"
								}
								status={
									daywise_services[0].services_needs_reordering
										? "warning"
										: undefined
								}
							>
								<Icons.SwitchHorizontal rotate="90" /> Re-Order
							</Button>
						)}
					</ReorderServicesInDialog>
				) : null}
			</Inline>
			{!daywise_services?.length ? (
				<Text>No services for this date</Text>
			) : (
				daywise_services.map(
					({
						date,
						checkin_hotels,
						checkout_hotels,
						stay_hotels,
						arrival_details,
						departure_details,
						services,
					}) => (
						<Stack gap="6" key={date}>
							<Stack gap="6">
								{services.map(({ cab_schedule, travel_activity_booking }) => {
									if (cab_schedule) {
										return (
											<Box key={`cab-schedule-${cab_schedule.id}`}>
												<CabScheduleDetails
													schedule={cab_schedule}
													onRefresh={() => revalidate()}
												/>
											</Box>
										)
									} else if (travel_activity_booking) {
										return (
											<Box
												key={`travel-activity-booking-${travel_activity_booking.id}`}
											>
												<TravelActivityBooking
													activityBooking={travel_activity_booking}
													hidePrice={hidePrice}
													onRefresh={() => revalidate()}
												/>
											</Box>
										)
									}
									return null
								})}
							</Stack>
							<ArrivalDepartureAndHotels
								checkin_hotels={checkin_hotels}
								checkout_hotels={checkout_hotels}
								stay_hotels={stay_hotels}
								arrival_details={arrival_details}
								departure_details={departure_details}
							/>
						</Stack>
					)
				)
			)}
			<Divider sm />
			<Inline gap="4" collapseBelow="md" justifyContent="between">
				<Inline gap="4" justifyContent="end">
					{!is_shared && daywise_services?.length ? (
						<ShareTripOperationalBookings trip={trip} date={date}>
							{({ share }) => (
								<Button
									onClick={() => share()}
									level="secondary"
									status="primary"
								>
									<Icons.Share /> Share
								</Button>
							)}
						</ShareTripOperationalBookings>
					) : null}
					{hasPermission(PERMISSIONS.MODIFY_CAB_SCHEDULES) &&
					!cancellation_reason ? (
						<AddOperationalBookingsForTripInDialog
							trip={trip}
							forDate={date}
							onSuccess={() => {
								revalidate()
							}}
						>
							{({ onAdd }) => (
								<Button
									onClick={() => {
										onAdd()
									}}
								>
									<Icons.Plus /> Add More Services
								</Button>
							)}
						</AddOperationalBookingsForTripInDialog>
					) : null}
				</Inline>
			</Inline>
		</Stack>
	)
}

function HotelBookings({
	bookings,
	label,
	icon,
}: {
	bookings: TTripOperationalBooking["daywise_services"][number]["checkin_hotels"]
	label: string
	icon: keyof typeof Icons
}) {
	const Icon = Icons[icon]
	return (
		<Inline gap="2">
			<Box>
				<Icon size="6" color="muted" />
			</Box>
			<Stack flex="1">
				<Box fontWeight="semibold" color="muted" fontSize="sm">
					{label}
				</Box>
				{bookings.map((hotelBooking) => (
					<Stack key={hotelBooking.id} gap="1">
						<Text fontWeight="semibold">{hotelBooking.hotel.name}</Text>
						<Inline color="muted" fontSize="sm">
							{joinAttributes(
								hotelBooking.hotel.location.short_name,
								hotelBooking.hotel.stars ? (
									<Stars stars={hotelBooking.hotel.stars} />
								) : null,
								hotelBooking.hotel.address.phone_numbers?.length ? (
									<PhoneNumber
										value={hotelBooking.hotel.address.phone_numbers}
										iconOnly
									/>
								) : null
							)}
						</Inline>
					</Stack>
				))}
			</Stack>
		</Inline>
	)
}

function ArrivalDepartureAndHotels({
	checkout_hotels,
	checkin_hotels,
	stay_hotels,
	arrival_details,
	departure_details,
}: Pick<
	TTripOperationalBooking["daywise_services"][number],
	| "checkout_hotels"
	| "checkin_hotels"
	| "stay_hotels"
	| "arrival_details"
	| "departure_details"
>) {
	if (
		!(
			checkout_hotels.length ||
			checkin_hotels.length ||
			stay_hotels.length ||
			arrival_details?.length ||
			departure_details?.length
		)
	) {
		return null
	}

	return (
		<Box>
			<Divider sm />
			<Grid gap="6">
				{arrival_details?.length ? (
					<Col>
						<Stack gap="2">
							<Heading as="h4" fontSize="base" color="primary">
								Arrival Details
							</Heading>
							<Stack gap="1">
								{arrival_details.map((d) => (
									<Stack key={d.id} gap="px">
										<Box>
											<Time
												timestamp={d.date_time}
												dateFormat
												timeFormat="HH:mm [hrs]"
											/>
										</Box>
										<Text whiteSpace="preserveLine" color="muted" fontSize="sm">
											{d.description}
										</Text>
									</Stack>
								))}
							</Stack>
						</Stack>
					</Col>
				) : null}
				{checkout_hotels.length ||
				checkin_hotels.length ||
				stay_hotels.length ? (
					<Col xs={12} sm>
						<Stack gap="2">
							<Heading as="h4" fontSize="base" color="primary">
								Hotels
							</Heading>
							<Inline flexWrap="wrap" gap="4">
								{checkout_hotels?.length ? (
									<Box>
										<HotelBookings
											bookings={checkout_hotels}
											label="Check-Out"
											icon="Logout"
										/>
									</Box>
								) : null}
								{checkin_hotels?.length ? (
									<Box>
										<HotelBookings
											bookings={checkin_hotels}
											label="Check-In"
											icon="Login"
										/>
									</Box>
								) : null}
								{stay_hotels?.length ? (
									<Box>
										<HotelBookings
											bookings={stay_hotels}
											label="Stay"
											icon="Bed"
										/>
									</Box>
								) : null}
							</Inline>
						</Stack>
					</Col>
				) : null}
				{departure_details?.length ? (
					<Col>
						<Stack gap="2">
							<Heading as="h4" fontSize="base" color="primary">
								Departure Details
							</Heading>
							<Stack gap="1">
								{departure_details.map((d) => (
									<Stack key={d.id} gap="px">
										<Box>
											<Time
												timestamp={d.date_time}
												dateFormat
												timeFormat="HH:mm [hrs]"
											/>
										</Box>
										<Text whiteSpace="preserveLine" color="muted" fontSize="sm">
											{d.description}
										</Text>
									</Stack>
								))}
							</Stack>
						</Stack>
					</Col>
				) : null}
			</Grid>
		</Box>
	)
}

function CabScheduleDetails({
	schedule,
	disableEdit,
	onRefresh,
}: {
	schedule: Required<
		TTripOperationalBooking["daywise_services"][number]["services"][number]
	>["cab_schedule"]
	disableEdit?: boolean
	onRefresh?: () => void
}) {
	const {
		id,
		cabs,
		can_modify,
		can_view_prices,
		itinerary,
		remarks,
		comments,
		booked_cabs_count,
	} = schedule
	const [itineraryVisible, toggleItineraryVisibility] = useState(false)
	return (
		<Stack gap="1">
			<Inline>
				<Box
					borderBottomWidth="2"
					borderBottomColor="primary"
					paddingBottom="px"
				>
					<Text fontSize="sm" color="primary" fontWeight="semibold">
						<Icons.Taxi /> Cab Schedule
					</Text>
				</Box>
			</Inline>
			<Grid gap="4">
				<Col sm={12} md={6} lg={4}>
					<Stack gap="1">
						<Inline justifyContent={"between"} gap="2">
							<CabScheduleTitleInfo schedule={schedule} />
							{!disableEdit && can_modify ? (
								<Box>
									<DeleteCabScheduleInDialog id={id} onSuccess={onRefresh}>
										{({ onDelete, isDeleting }) => (
											<EditScheduleInDialog
												scheduleId={id}
												onSuccess={onRefresh}
											>
												{({ onEdit }) => (
													<Dropdown alignRight>
														<Dropdown.ToggleButton size="sm" level="tertiary">
															<Icons.DotsVertical />
														</Dropdown.ToggleButton>
														<Dropdown.Menu>
															<Dropdown.MenuItem
																onClick={(e: React.MouseEvent) => {
																	e.preventDefault()
																	if (isDeleting) return
																	onEdit()
																}}
															>
																<Icons.Pencil /> Edit Schedule
															</Dropdown.MenuItem>
															{!booked_cabs_count ? (
																<Dropdown.MenuItem
																	color="warning"
																	onClick={(e: React.MouseEvent) => {
																		e.preventDefault()
																		if (isDeleting) return
																		onDelete()
																	}}
																>
																	<Icons.Trash /> Delete
																</Dropdown.MenuItem>
															) : null}
														</Dropdown.Menu>
													</Dropdown>
												)}
											</EditScheduleInDialog>
										)}
									</DeleteCabScheduleInDialog>
								</Box>
							) : null}
						</Inline>
						{remarks ? (
							<Box as="blockquote" marginBottom="0">
								{remarks}
							</Box>
						) : null}
						{comments ? (
							<Text color="muted" fontSize="sm" title="Internal Comments">
								<Icons.Annotation /> {comments}
							</Text>
						) : null}
						{itinerary?.description ? (
							<Box>
								<Button
									title={itinerary.name}
									inline
									onClick={() => toggleItineraryVisibility(!itineraryVisible)}
								>
									<Text as="span" fontWeight="semibold" fontSize="sm">
										Itinerary
									</Text>
									<Text as="span">
										<Icons.ChevronDown
											rotate={itineraryVisible ? "180" : "270"}
											transition="1"
										/>
									</Text>
								</Button>
							</Box>
						) : null}
					</Stack>
				</Col>
				<Col>
					<Stack as="ul" gap="6">
						{cabs.map(({ confirmation_details, ...c }) => (
							<Stack
								key={c.id}
								as="li"
								borderWidth="1"
								gap="2"
								rounded="lg"
								paddingX="4"
								paddingY="2"
								position="relative"
								borderColor={c.booked ? "success" : "default"}
							>
								<Grid gap="2">
									<Col sm={12} lg={5}>
										<Stack>
											<Text fontWeight="semibold" fontSize="md">
												{c.cab?.name || c.cab_type.display_name}{" "}
												{c.booked ? (
													<Icons.OkCircleSolid color="success" />
												) : null}
												{!c.cab && c.cab_type.deleted_at ? (
													<Icons.Ban color="warning" title="Disabled" />
												) : null}
											</Text>
											{c.cab?.number_plate ? (
												<Text fontSize="sm" color="muted">
													{c.cab.number_plate.toUpperCase()}
												</Text>
											) : c.cab_type.short_name ? (
												<Text fontSize="sm" color="muted">
													[[{c.cab_type.short_name}]]
												</Text>
											) : null}
										</Stack>
									</Col>
									{c.driver ||
									c.transport_service_provider ||
									(c.booked_price && can_view_prices) ? (
										<Col>
											<Inline justifyContent="between" gap="4">
												<Grid gap="4">
													{c.transport_service_provider ? (
														<Col>
															<Link
																to={generatePath(
																	"/transport-service-providers/:transportServiceProviderId",
																	{
																		transportServiceProviderId:
																			c.transport_service_provider.id.toString(),
																	}
																)}
															>
																{c.transport_service_provider.name}
															</Link>
															{c.transport_service_provider.contacts &&
															c.transport_service_provider.contacts.length ? (
																<Box color="muted" fontSize="sm">
																	{joinAttributes(
																		...c.transport_service_provider.contacts.map(
																			(t) => (
																				<PhoneNumber value={t.phone_numbers} />
																			)
																		)
																	)}
																</Box>
															) : null}
														</Col>
													) : null}
													{c.driver ? (
														<Col>
															<Stack>
																<Box title="Driver">
																	<Icons.SteeringWheel /> {c.driver.name}
																</Box>
																<Box color="muted" fontSize="sm">
																	{joinAttributes(
																		c.driver.phone_number ? (
																			<PhoneNumber
																				value={c.driver.phone_numbers}
																			/>
																		) : null,
																		c.driver.email ? (
																			<Email value={c.driver.email} iconOnly />
																		) : null
																	)}
																</Box>
															</Stack>
														</Col>
													) : null}
												</Grid>
												{can_view_prices ? <BookingAmount booking={c} /> : null}
											</Inline>
										</Col>
									) : null}
								</Grid>
								{confirmation_details ? (
									<Inline>
										<Box
											whiteSpace="preserveAndWrap"
											borderWidth="1"
											paddingY="1"
											paddingX="2"
											rounded="md"
											fontSize="sm"
											bgColor="inset"
										>
											{confirmation_details}
										</Box>
									</Inline>
								) : null}
							</Stack>
						))}
					</Stack>
				</Col>
			</Grid>
			{itineraryVisible && itinerary ? (
				<Stack
					gap="1"
					padding="2"
					bgColor="subtle"
					rounded="md"
					borderWidth="1"
				>
					<Text fontWeight="semibold">{itinerary.name}</Text>
					<Markdown>{itinerary.description}</Markdown>
				</Stack>
			) : null}
		</Stack>
	)
}

function CabScheduleTitleInfo({
	schedule,
}: {
	schedule: Required<
		TTripOperationalBooking["daywise_services"][number]["services"][number]
	>["cab_schedule"]
}) {
	const { transport_service, duration_formatted } = schedule
	const startTime = useMemo(() => {
		return getStartDateTimeOfCabSchedule(schedule)
	}, [schedule])

	const endTime = useMemo(() => {
		return getEndDateTimeOfCabSchedule(schedule)
	}, [schedule])
	return (
		<Stack gap="1">
			<Text fontWeight="semibold" fontSize="md">
				{transport_service.name}
			</Text>
			{startTime || duration_formatted ? (
				<Inline gap="2" alignItems="center">
					<Icons.Clock size="3" />
					<Box flex="1" minWidth="0">
						<Text fontSize="sm">
							{joinAttributes(
								<>
									Pickup:{" "}
									{startTime ? (
										<Time value={startTime} format="HH:mm [hrs]" />
									) : (
										"N/A"
									)}
								</>,
								<>
									Duration: {duration_formatted || "N/A"}{" "}
									{startTime && duration_formatted ? (
										<>
											{" "}
											(Ends <Time value={endTime} format="HH:mm [hrs]" />)
										</>
									) : null}
								</>
							)}
						</Text>
					</Box>
				</Inline>
			) : null}
		</Stack>
	)
}

function TravelActivityBookingTitleInfo({
	activityBooking,
}: {
	activityBooking: TTravelActiviytBooking
}) {
	const {
		activity,
		ticket_type,
		start_time_formatted,
		duration_formatted,
		end_time_formatted,
	} = activityBooking
	return (
		<Stack gap="1">
			<Text fontWeight="semibold" fontSize="md">
				{activity.name}{" "}
				{activity.deleted_at ? (
					<Badge warning>
						<Icons.Ban />
					</Badge>
				) : null}
				{ticket_type ? (
					<Text as="span">
						- {ticket_type.display_name}{" "}
						{ticket_type.deleted_at ? (
							<Badge warning>
								<Icons.Ban />
							</Badge>
						) : null}
					</Text>
				) : null}
			</Text>
			{ticket_type?.short_name ? (
				<Text fontSize="sm" color="muted">
					[[{ticket_type.short_name}]]
				</Text>
			) : null}
			{start_time_formatted || duration_formatted ? (
				<Inline alignItems="center" gap="2">
					<Icons.Clock size="3" />
					<Box flex="1" minWidth="0">
						<Text fontSize="sm">
							{joinAttributes(
								`Starts: ${start_time_formatted || "N/A"}`,
								`Duration: ${duration_formatted || "N/A"}${end_time_formatted ? ` (Ends: ${end_time_formatted})` : ``}`
							)}
						</Text>
					</Box>
				</Inline>
			) : null}
		</Stack>
	)
}

function TravelActivityBooking({
	activityBooking,
	hidePrice,
	onRefresh,
	disableEdit,
}: {
	activityBooking: TTravelActiviytBooking
	hidePrice?: boolean
	onRefresh?: () => void
	disableEdit?: boolean
}) {
	const {
		id,
		ticket_tourist_configurations,
		itinerary,
		supplier,
		supplier_contact,
		is_booked,
		confirmation_details,
		remarks,
		comments,
		can_modify,
	} = activityBooking

	disableEdit = disableEdit || !can_modify

	const [itineraryVisible, toggleItineraryVisibility] = useState(false)
	return (
		<Stack gap="1">
			<Inline>
				<Box
					borderBottomWidth="2"
					borderBottomColor="primary"
					paddingBottom="px"
				>
					<Text fontSize="sm" color="primary" fontWeight="semibold">
						<Icons.Ticket /> Activity/Ticket Booking
					</Text>
				</Box>
			</Inline>
			<Grid gap="4">
				<Col sm={12} md={6} lg={4}>
					<Inline>
						<Stack flex="1" gap="1">
							<TravelActivityBookingTitleInfo
								activityBooking={activityBooking}
							/>
							{remarks ? (
								<Box as="blockquote" marginBottom="0">
									{remarks}
								</Box>
							) : null}
							{comments ? (
								<Text color="muted" fontSize="sm" title="Internal Comments">
									<Icons.Annotation /> {comments}
								</Text>
							) : null}
							{itinerary?.description ? (
								<Box>
									<Button
										title={itinerary.name}
										inline
										onClick={() => toggleItineraryVisibility(!itineraryVisible)}
									>
										<Text as="span" fontWeight="semibold" fontSize="sm">
											Itinerary
										</Text>
										<Text as="span">
											<Icons.ChevronDown
												rotate={itineraryVisible ? "180" : "270"}
												transition="1"
											/>
										</Text>
									</Button>
								</Box>
							) : null}
						</Stack>
						{!disableEdit ? (
							<Box>
								<DeleteTravelActivityBookingInDialog
									id={id}
									onSuccess={onRefresh}
								>
									{({ onDelete, isDeleting }) => (
										<EditTravelActivityBookingItemInDialog
											bookingId={id}
											onSuccess={onRefresh}
										>
											{({ onEdit }) => (
												<Dropdown alignRight>
													<Dropdown.ToggleButton size="sm" level="tertiary">
														<Icons.DotsVertical />
													</Dropdown.ToggleButton>
													<Dropdown.Menu>
														<Dropdown.MenuItem
															onClick={(e: React.MouseEvent) => {
																e.preventDefault()
																if (isDeleting) return
																onEdit()
															}}
														>
															<Icons.Pencil /> Edit Booking
														</Dropdown.MenuItem>
														{!is_booked ? (
															<Dropdown.MenuItem
																color="warning"
																onClick={(e: React.MouseEvent) => {
																	e.preventDefault()
																	if (isDeleting) return
																	onDelete()
																}}
															>
																<Icons.Trash /> Delete
															</Dropdown.MenuItem>
														) : null}
													</Dropdown.Menu>
												</Dropdown>
											)}
										</EditTravelActivityBookingItemInDialog>
									)}
								</DeleteTravelActivityBookingInDialog>
							</Box>
						) : null}
					</Inline>
				</Col>
				<Col>
					<Stack borderWidth="1" rounded="md" paddingX="4" paddingY="2" gap="2">
						<Grid gap="2">
							<Col xs={12} lg={5}>
								<Inline gap="1">
									{is_booked ? (
										<Box title="Booked">
											<Icons.OkCircleSolid color="success" />
										</Box>
									) : null}
									<Inline gap="4" flexWrap="wrap">
										{ticket_tourist_configurations.map(
											({
												id,
												configuration,
												quantity,
												per_quantity_booked_price,
												currency,
											}) => (
												<Stack key={id}>
													<Text fontWeight="semibold">
														{quantity} {configuration.name}
													</Text>
													{hidePrice ? null : (
														<Box fontSize="sm">
															{per_quantity_booked_price ? (
																<Text>
																	<Money
																		amount={per_quantity_booked_price}
																		currency={currency}
																	/>{" "}
																	* {quantity}
																</Text>
															) : (
																<Text color="warning">N/A</Text>
															)}
														</Box>
													)}
												</Stack>
											)
										)}
									</Inline>
								</Inline>
							</Col>
							{supplier || !hidePrice ? (
								<Col>
									<Inline justifyContent="between" gap="4">
										<Grid gap="4">
											{supplier ? (
												<Col>
													<Stack gap="px">
														<Box>
															<Link
																to={generatePath(
																	"/transport-service-providers/:transportServiceProviderId",
																	{
																		transportServiceProviderId:
																			supplier.id.toString(),
																	}
																)}
															>
																{supplier.name}
															</Link>
														</Box>
														<Box fontSize={"sm"}>
															{supplier.contacts?.length
																? joinAttributes(
																		supplier.contacts[0].phone_numbers
																			?.length ? (
																			<PhoneNumber
																				value={
																					supplier.contacts[0].phone_numbers
																				}
																			/>
																		) : null,
																		supplier.contacts[0].email ? (
																			<Email
																				value={supplier.contacts[0].email}
																				iconOnly
																			/>
																		) : null
																	)
																: null}
														</Box>
													</Stack>
												</Col>
											) : null}
											{supplier_contact ? (
												<Col>
													<Stack>
														<Box title="Supplier Contact">
															<Icons.User /> {supplier_contact.name}
														</Box>
														<Box color="muted" fontSize="sm">
															{joinAttributes(
																supplier_contact.phone_numbers?.length ? (
																	<PhoneNumber
																		value={supplier_contact.phone_numbers}
																	/>
																) : null,
																supplier_contact.email ? (
																	<Email
																		value={supplier_contact.email}
																		iconOnly
																	/>
																) : null
															)}
														</Box>
													</Stack>
												</Col>
											) : null}
										</Grid>
										{!hidePrice ? (
											<BookingAmount booking={activityBooking} />
										) : null}
									</Inline>
								</Col>
							) : null}
						</Grid>
						{confirmation_details || is_booked ? (
							<Inline>
								{confirmation_details ? (
									<Box
										whiteSpace="preserveAndWrap"
										borderWidth="1"
										paddingY="1"
										paddingX="2"
										rounded="md"
										fontSize="sm"
										bgColor="inset"
									>
										{confirmation_details}
									</Box>
								) : is_booked ? (
									<Alert inline status="warning">
										Missing confirmation details
									</Alert>
								) : null}
							</Inline>
						) : null}
					</Stack>
				</Col>
			</Grid>
			{itineraryVisible && itinerary ? (
				<Stack
					gap="1"
					padding="2"
					bgColor="subtle"
					rounded="md"
					borderWidth="1"
				>
					<Text fontWeight="semibold">{itinerary.name}</Text>
					<Markdown>{itinerary.description}</Markdown>
				</Stack>
			) : null}
		</Stack>
	)
}

export function ReorderServicesInDialog({
	children,
	tripId,
	onSuccess,
	...props
}: Optional<
	Omit<React.ComponentProps<typeof ReorderServicesForm>, "onSubmit">,
	"onCancel"
> & {
	tripId: number
	children: (props: { onReorder: () => void }) => React.ReactNode
	onSuccess?: () => void
}) {
	const [isOpen, openDialog, closeDialog] = useDialog()
	const xhr = useXHR()
	return (
		<>
			{children({ onReorder: openDialog })}
			<Dialog
				open={isOpen}
				onClose={() => closeDialog()}
				title="Re-Order Services"
				lg
			>
				<Dialog.Body>
					<ReorderServicesForm
						{...props}
						onCancel={() => {
							closeDialog()
							props.onCancel?.()
						}}
						onSubmit={async (services) => {
							await xhr.post(
								`/trip-operational-bookings/${tripId}/reorder-services`,
								{
									data: services.map((s, index) => ({
										id: s.cab_schedule?.id || s.travel_activity_booking?.id,
										type: s.cab_schedule
											? "cab_schedule"
											: "travel_activity_booking",
										sort_order: index + 1,
									})),
								}
							)
							closeDialog()
							showSnackbar("Services ordered successfully")
							onSuccess?.()
						}}
					/>
				</Dialog.Body>
			</Dialog>
		</>
	)
}

function getIdForService(s: {
	cab_schedule?: { id: number }
	travel_activity_booking?: { id: number }
}) {
	return s.cab_schedule
		? `cab_schedule_${s.cab_schedule.id}`
		: s.travel_activity_booking
			? `travel_activity_booking_${s.travel_activity_booking.id}`
			: ""
}

function ReorderServicesForm({
	services,
	onCancel,
	onSubmit,
	initialOrdering,
}: {
	services: TTripOperationalBooking["daywise_services"][number]["services"]
	onCancel: () => void
	initialOrdering?: Array<{
		cab_schedule_id?: number
		travel_activity_booking_id?: number
	}>
	onSubmit: (
		data: TTripOperationalBooking["daywise_services"][number]["services"]
	) => Promise<unknown>
}) {
	const [initialValues] = useState<{
		ordered_services?: typeof services
		ordered_services_by_id?: { [key: string]: boolean }
	}>(() => {
		const servicesById = collect(services).keyBy(getIdForService)
		const ordered_services = (initialOrdering || []).map((s) => {
			const id = getIdForService({
				cab_schedule: s.cab_schedule_id
					? {
							id: s.cab_schedule_id,
						}
					: undefined,
				travel_activity_booking: s.travel_activity_booking_id
					? {
							id: s.travel_activity_booking_id,
						}
					: undefined,
			})
			return servicesById[id]
		})
		return {
			ordered_services,
			ordered_services_by_id: ordered_services.reduce<{
				[key: string]: boolean
			}>((obj, s) => {
				obj[getIdForService(s)] = true
				return obj
			}, {}),
		}
	})
	return (
		<Form<typeof initialValues>
			initialValues={initialValues}
			onSubmit={withServerErrors(
				async ({ ordered_services, ordered_services_by_id }) => {
					const allOrderedServices = (ordered_services || []).concat(
						// append the services which were not ordered
						services.filter((s) => {
							const key = s.cab_schedule
								? `cab_schedule_${s.cab_schedule.id}`
								: s.travel_activity_booking
									? `travel_activity_booking_${s.travel_activity_booking.id}`
									: ""
							return (
								!ordered_services_by_id ||
								!isTruthy(ordered_services_by_id[key])
							)
						})
					)
					return onSubmit(allOrderedServices)
				}
			)}
			mutators={{ ...arrayMutators }}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit, form }) => (
				<form onSubmit={handleSubmit} noValidate>
					<FieldArray<(typeof services)[number]> name="ordered_services">
						{({ fields }) => (
							<Inline gap="4" collapseBelow="sm">
								<Box flex="1">
									<Stack gap="4">
										<Stack gap="1">
											<Heading fontSize="md">
												Select the services in desired order
											</Heading>
											<Text fontSize="sm" color="muted">
												Services will be ordered in the order of clicks.
											</Text>
										</Stack>
										<Stack gap="6">
											{services.map(
												({ cab_schedule, travel_activity_booking }) => {
													if (cab_schedule) {
														return (
															<Box
																key={`cab-schedule-${cab_schedule.id}`}
																paddingX={"4"}
																paddingY={"2"}
																borderWidth="1"
																rounded="md"
															>
																<GetFieldValue<"" | 1 | 0>
																	name={`ordered_services_by_id.cab_schedule_${cab_schedule.id}`}
																>
																	{({ value, onChange }) => {
																		const isSelected = isTruthy(value)
																		return (
																			<Inline
																				opacity={isSelected ? "20" : "100"}
																				transition="1"
																				gap="4"
																				justifyContent="between"
																			>
																				<CabScheduleTitleInfo
																					schedule={cab_schedule}
																				/>
																				<Button
																					disabled={isSelected}
																					onClick={() => {
																						if (isSelected) return
																						onChange(1)
																						fields.push({ cab_schedule })
																					}}
																					size="sm"
																					status="primary"
																				>
																					<Icons.ArrowLeft rotate="180" />
																				</Button>
																			</Inline>
																		)
																	}}
																</GetFieldValue>
															</Box>
														)
													} else if (travel_activity_booking) {
														return (
															<Box
																key={`travel-activity-booking-${travel_activity_booking.id}`}
																paddingX={"4"}
																paddingY={"2"}
																borderWidth="1"
																rounded="md"
															>
																<GetFieldValue<"" | 1 | 0>
																	name={`ordered_services_by_id.travel_activity_booking_${travel_activity_booking.id}`}
																>
																	{({ value, onChange }) => {
																		const isSelected = isTruthy(value)
																		return (
																			<Inline
																				opacity={isSelected ? "20" : "100"}
																				transition="1"
																				gap="4"
																				justifyContent="between"
																			>
																				<TravelActivityBookingTitleInfo
																					activityBooking={
																						travel_activity_booking
																					}
																				/>
																				<Button
																					disabled={isSelected}
																					onClick={() => {
																						if (isSelected) return
																						onChange(1)
																						fields.push({
																							travel_activity_booking,
																						})
																					}}
																					size="sm"
																					status="primary"
																				>
																					<Icons.ArrowLeft rotate="180" />
																				</Button>
																			</Inline>
																		)
																	}}
																</GetFieldValue>
															</Box>
														)
													}
													return null
												}
											)}
										</Stack>
									</Stack>
								</Box>
								<Box alignSelf={"stretch"} borderWidth="1"></Box>
								<Box flex="1">
									<Stack gap="4">
										<Stack gap="1">
											<Heading fontSize="md">Ordered Services</Heading>
											<Text fontSize="sm" color="muted">
												To change the order, click on remove and then re-add
											</Text>
										</Stack>
										<Stack gap="6">
											{fields.map((name, index) => (
												<Box key={name}>
													<Inline gap="2">
														<Stack
															size={"8"}
															alignItems="center"
															justifyContent="center"
															borderWidth="1"
															rounded="full"
															bgColor="success_emphasis"
															color="on_emphasis"
														>
															<Text fontWeight="semibold">{index + 1}</Text>
														</Stack>
														<Box flex="1" minWidth="0">
															<GetFieldValue<(typeof services)[0]> name={name}>
																{({ value }) => {
																	if (value.cab_schedule) {
																		const cab_schedule = value.cab_schedule
																		return (
																			<Box
																				key={`cab-schedule-${cab_schedule.id}`}
																				paddingX={"4"}
																				paddingY={"2"}
																				borderWidth="1"
																				rounded="md"
																			>
																				<Inline
																					gap="4"
																					justifyContent="between"
																				>
																					<CabScheduleTitleInfo
																						schedule={cab_schedule}
																					/>
																					<Button
																						onClick={() => {
																							form.change(
																								`ordered_services_by_id.cab_schedule_${cab_schedule.id}` as never,
																								0 as never
																							)
																							fields.remove(index)
																						}}
																						size="sm"
																					>
																						<Icons.Cancel />
																					</Button>
																				</Inline>
																			</Box>
																		)
																	} else if (value.travel_activity_booking) {
																		const travel_activity_booking =
																			value.travel_activity_booking
																		return (
																			<Box
																				key={`travel-activity-booking-${travel_activity_booking.id}`}
																				paddingX={"4"}
																				paddingY={"2"}
																				borderWidth="1"
																				rounded="md"
																			>
																				<Inline
																					gap="4"
																					justifyContent="between"
																				>
																					<TravelActivityBookingTitleInfo
																						activityBooking={
																							travel_activity_booking
																						}
																					/>
																					<Button
																						onClick={() => {
																							form.change(
																								`ordered_services_by_id.travel_activity_booking_${travel_activity_booking.id}` as never,
																								0 as never
																							)
																							fields.remove(index)
																						}}
																						size="sm"
																					>
																						<Icons.Cancel />
																					</Button>
																				</Inline>
																			</Box>
																		)
																	}
																	return null
																}}
															</GetFieldValue>
														</Box>
													</Inline>
												</Box>
											))}
										</Stack>
									</Stack>
								</Box>
							</Inline>
						)}
					</FieldArray>
					<Divider />
					<Stack gap="4">
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								Save Details
							</Button>
							<Button onClick={() => onCancel()}>Cancel</Button>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}
