import { Helmet } from "react-helmet-async"
import { OperationalBookingsCalendarWithFilters } from "./../../../TripOperationalBookings"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../Auth"

export default function SchedulesCalendarModule() {
	return (
		<>
			<Helmet>
				<title>Trip Operational Calendar</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.VIEW_CAB_SCHEDULES}>
				<OperationalBookingsCalendarWithFilters />
			</ForbidUnlessAuthorized>
		</>
	)
}
