import { useParams, generatePath } from "../../../../router-utils"
import { useXHR } from "@sembark-travel/xhr"
import useSWR from "swr"
import { cabPriceCalculationMetricsXHR } from "../../../../CabPriceCalculationMetrics"
import {
	Container,
	Heading,
	Spinner,
	Text,
	Stack,
	Time,
	Inline,
	Button,
} from "@sembark-travel/ui/base"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { numberToLocalString } from "@sembark-travel/number-utils"
import {
	ConfirmationDialog,
	ConfirmationDialogProvider,
} from "@sembark-travel/ui/dialog"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { Helmet } from "react-helmet-async"
import { isTruthy } from "@sembark-travel/ui/form"

export default function CabPriceCalculationMetricItemPage() {
	const { metricId } = useParams("/cab-price-calculation-metrics/:metricId")
	return (
		<>
			<Helmet>
				<title>Cab Price Calculation Metric Details</title>
			</Helmet>
			<Details metricId={metricId} />
		</>
	)
}

function Details({ metricId }: { metricId: number | string }) {
	const xhr = useXHR()
	const { data, mutate } = useSWR(
		`/api/cab-price-calculation-metrics/${metricId}`,
		() => cabPriceCalculationMetricsXHR(xhr).show(metricId)
	)
	if (!data) {
		return <Spinner alignCenter padding="4" />
	}
	const {
		id,
		name,
		system_quantity_metric,
		min_quantity_per_day,
		total_using_per_day_minimum,
		created_at,
		created_by,
		updated_at,
		updated_by,
		deleted_at,
		deleted_by,
	} = data.data
	return (
		<>
			<Breadcrumbs
				title="Metric Details"
				items={[
					[generatePath("/cab-price-calculation-metrics"), "Metrics"],
					["", name],
				]}
				actions={
					!deleted_at ? (
						<ConfirmationDialogProvider>
							{({ confirm }) => (
								<>
									<Button
										size="sm"
										level="secondary"
										status="warning"
										onClick={async () => {
											if (await confirm()) {
												const { message } =
													await cabPriceCalculationMetricsXHR(xhr).destroy(id)
												mutate()
												showSnackbar(message)
											}
										}}
									>
										Archive
									</Button>
									<ConfirmationDialog
										title="Archive Metric"
										destructiveAction
										acceptActionLabel="Archive It"
									>
										<Stack gap="6">
											<Stack gap="1">
												<Heading color="primary" fontSize="base">
													Metric Details
												</Heading>
												<Stack
													gap="2"
													paddingX="4"
													paddingY="2"
													rounded="md"
													borderWidth="1"
												>
													<Heading fontSize="md">{name}</Heading>
													<Inline gap="6" flexWrap="wrap">
														{system_quantity_metric ? (
															<Stack>
																<Text
																	fontSize="sm"
																	fontWeight="semibold"
																	color="muted"
																>
																	System Qnty.
																</Text>
																<Text>{system_quantity_metric}</Text>
															</Stack>
														) : null}
														{min_quantity_per_day ? (
															<>
																<Stack>
																	<Text
																		fontSize="sm"
																		fontWeight="semibold"
																		color="muted"
																	>
																		Min. /Day
																	</Text>
																	<Text>
																		{numberToLocalString(min_quantity_per_day)}
																	</Text>
																</Stack>
																<Stack>
																	<Text
																		fontSize="sm"
																		fontWeight="semibold"
																		color="muted"
																	>
																		Total Using
																	</Text>
																	<Text>
																		{isTruthy(total_using_per_day_minimum)
																			? "Per-Day Minimum"
																			: "Overall Minimum"}
																	</Text>
																</Stack>
															</>
														) : null}
													</Inline>
												</Stack>
											</Stack>
											<Text color="warning">
												Are you sure you want to archive this Metric ?
											</Text>
										</Stack>
									</ConfirmationDialog>
								</>
							)}
						</ConfirmationDialogProvider>
					) : (
						<ConfirmationDialogProvider>
							{({ confirm }) => (
								<>
									<Button
										size="sm"
										level="secondary"
										onClick={async () => {
											if (await confirm()) {
												await cabPriceCalculationMetricsXHR(xhr).restore(id)
												mutate()
												showSnackbar("Metric restored from archive.")
											}
										}}
									>
										Restore Archive
									</Button>
									<ConfirmationDialog
										title="Restore Archived Metric"
										acceptActionLabel="Restore It"
									>
										<Stack gap="6">
											<Stack gap="1">
												<Heading color="primary" fontSize="base">
													Metric Details
												</Heading>
												<Stack
													gap="2"
													paddingX="4"
													paddingY="2"
													rounded="md"
													borderWidth="1"
												>
													<Heading fontSize="md">{name}</Heading>
													<Inline gap="6" flexWrap="wrap">
														{system_quantity_metric ? (
															<Stack>
																<Text
																	fontSize="sm"
																	fontWeight="semibold"
																	color="muted"
																>
																	System Qnty.
																</Text>
																<Text>{system_quantity_metric}</Text>
															</Stack>
														) : null}
														{min_quantity_per_day ? (
															<>
																<Stack>
																	<Text
																		fontSize="sm"
																		fontWeight="semibold"
																		color="muted"
																	>
																		Min. /Day
																	</Text>
																	<Text>
																		{numberToLocalString(min_quantity_per_day)}
																	</Text>
																</Stack>
																<Stack>
																	<Text
																		fontSize="sm"
																		fontWeight="semibold"
																		color="muted"
																	>
																		Total Using
																	</Text>
																	<Text>
																		{isTruthy(total_using_per_day_minimum)
																			? "Per-Day Minimum"
																			: "Overall Minimum"}
																	</Text>
																</Stack>
															</>
														) : null}
													</Inline>
												</Stack>
											</Stack>
											<Text color="warning">
												Are you sure you want to restore this Metric ?
											</Text>
										</Stack>
									</ConfirmationDialog>
								</>
							)}
						</ConfirmationDialogProvider>
					)
				}
			/>
			<Container paddingY="6">
				<Stack gap="2">
					<Heading>{name}</Heading>
					<Inline gap="6" flexWrap="wrap">
						{system_quantity_metric ? (
							<Stack>
								<Text fontSize="sm" fontWeight="semibold" color="muted">
									System Qnty.
								</Text>
								<Text>{system_quantity_metric}</Text>
							</Stack>
						) : null}
						{min_quantity_per_day ? (
							<>
								<Stack>
									<Text fontSize="sm" fontWeight="semibold" color="muted">
										Min. /Day
									</Text>
									<Text>{numberToLocalString(min_quantity_per_day)}</Text>
								</Stack>
								<Stack>
									<Text fontSize="sm" fontWeight="semibold" color="muted">
										Total Using
									</Text>
									<Text>
										{isTruthy(total_using_per_day_minimum)
											? "Per-Day Minimum"
											: "Overall Minimum"}
									</Text>
								</Stack>
							</>
						) : null}
						{deleted_at ? (
							<Stack color="danger">
								<Text fontSize="sm" fontWeight="semibold">
									Archived
								</Text>
								<Text>
									{deleted_by ? `by ${deleted_by.name} ` : ``}on{" "}
									<Time timestamp={deleted_at} />
								</Text>
							</Stack>
						) : null}
						{updated_at && updated_by ? (
							<Stack>
								<Text fontSize="sm" fontWeight="semibold" color="muted">
									Updated
								</Text>
								<Text>
									by {updated_by.name} on <Time timestamp={updated_at} />
								</Text>
							</Stack>
						) : null}
						{created_by ? (
							<Stack>
								<Text fontSize="sm" fontWeight="semibold" color="muted">
									Created
								</Text>
								<Text>
									by {created_by.name} on <Time timestamp={created_at} />
								</Text>
							</Stack>
						) : null}
					</Inline>
				</Stack>
			</Container>
		</>
	)
}
