import { TripsList } from "./../../../Trips"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../Auth"
import { Helmet } from "react-helmet-async"

export default function Trips() {
	return (
		<>
			<Helmet>
				<title>Trips List</title>
			</Helmet>
			<ForbidUnlessAuthorized
				anyPermissions={[
					PERMISSIONS.VIEW_QUERIES,
					PERMISSIONS.VIEW_HOTEL_BOOKINGS,
					PERMISSIONS.VIEW_CAB_SCHEDULES,
					PERMISSIONS.VIEW_TRIP_SALES_REPORTS,
				]}
			>
				<TripsList />
			</ForbidUnlessAuthorized>
		</>
	)
}
