import {
	Box,
	Button,
	Inline,
	Table,
	Stack,
	Container,
	Heading,
	Text,
	Icons,
} from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import {
	Form,
	FileInputField,
	withServerErrors,
	SubmissionError,
	validateFormValues,
	SelectField,
	GetFieldValue,
} from "@sembark-travel/ui/form"
import * as Validator from "yup"
import config from "../config"
import { SelectTripDestination, TTripDestination } from "../TripDestinations"
import { useHasFeatureFlag } from "../Auth"
import {
	SelectTenantCurrencyInputField,
	useFunctionalCurrencyOfTenant,
} from "../Currencies"
import { useRef } from "react"
import {
	CopyToClipboard,
	CopyToClipboardButton,
} from "@sembark-travel/ui/copy-to-clipboard"

type TFormData = {
	timezone_offset: number
	files: FileList | null
	trip_destinations?: Array<TTripDestination>
	currency: string
}

const THIS_YEAR = new Date().getFullYear()

export function UploadTravelActivityPrices({
	onSuccess,
	onCancel,
}: {
	onSuccess: () => void
	onCancel?: () => void
}) {
	const xhr = useXHR()
	const can_attach_services_to_destinations = useHasFeatureFlag(
		"attach_services_to_destinations"
	)
	const validationSchema = validateFormValues(
		Validator.object().shape({
			timezone_offset: Validator.string().required(),
			files: Validator.mixed().required("Please selected some csv files"),
			trip_destinations: can_attach_services_to_destinations
				? Validator.array()
						.required("Please select a Destinations")
						.min(1, "Please select atleast one Destinations")
				: Validator.array().nullable(),
			currency: Validator.string().required(
				"Please select a currency for price input."
			),
		})
	)
	const functionalCurrency = useFunctionalCurrencyOfTenant()
	const initialValues: TFormData = useRef({
		timezone_offset: config.timezoneOffset,
		files: null,
		trip_destinations: [],
		currency: functionalCurrency,
	}).current
	return (
		<>
			<Form<typeof initialValues>
				initialValues={initialValues}
				validate={validationSchema}
				onSubmit={withServerErrors(async (values) => {
					const data = new FormData()
					const trip_destinations = values.trip_destinations || []
					const files = values.files
					if (!files || !files.length) {
						throw Error("Please select a file to upload!")
					}
					data.set("timezone_offset", values.timezone_offset.toString())
					for (let i = 0; i < files.length; i++) {
						data.set(`files[${i}]`, files[i])
					}
					data.set("currency", values.currency)
					trip_destinations.forEach((d, i) =>
						data.set(`trip_destinations[${i}]`, d.id.toString())
					)
					await xhr.post("/travel-activity-prices", data, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					onSuccess()
				})}
				subscription={{ submitting: true, submitErrors: true }}
			>
				{({ submitting, handleSubmit, submitErrors, form }) => (
					<form
						noValidate
						encType="multipart/form-data"
						onSubmit={handleSubmit}
					>
						<Container paddingY="8" bgColor="default">
							<Stack gap="4">
								<SubmissionError />
								{submitErrors ? (
									<Stack gap="4">
										{submitErrors ? (
											<Box as="ul" listStyleType="disc">
												{Object.keys(submitErrors).map((field) => (
													<Box as="li" key={field}>
														<Text>
															{
																(submitErrors as { [field: string]: string })[
																	field
																]
															}
														</Text>
													</Box>
												))}
											</Box>
										) : null}
										<Text>
											Please verify your CSV File format with the format given
											below. Or contact our support if you need any assistant.
										</Text>
									</Stack>
								) : null}
								{can_attach_services_to_destinations ? (
									<SelectField
										select={SelectTripDestination}
										name="trip_destinations"
										label="Trip Destinations"
										multiple
										fetchOnMount
										closeOnSelect
										onChange={(
											destinations: Array<TTripDestination> | undefined
										) => {
											form.change("trip_destinations", destinations)
											if (destinations?.length) {
												form.change("currency", destinations[0].currency)
											}
										}}
									/>
								) : null}
								<Inline gap="4" collapseBelow="sm">
									<SelectTenantCurrencyInputField
										name="currency"
										label="Currency"
										style={{ maxWidth: "150px" }}
									/>
									<Box flex="1">
										<FileInputField
											label="Select a csv file"
											name="files"
											accept=".csv"
											multiple
											help={
												<GetFieldValue<string> name="currency">
													{({ value: currency }) => (
														<Text>
															All prices will be saved in "{currency}" currency.
														</Text>
													)}
												</GetFieldValue>
											}
										/>
									</Box>
								</Inline>
								<Inline gap="4" flexWrap="wrap">
									<Button type="submit" disabled={submitting} size="lg">
										{submitting
											? "Uploading..."
											: "Upload Travel Activity Prices CSV"}
									</Button>
									{onCancel ? (
										<Button onClick={onCancel} level="tertiary" size="lg">
											Cancel
										</Button>
									) : null}
								</Inline>
							</Stack>
						</Container>
					</form>
				)}
			</Form>
			<Container paddingY="8">
				<CopyToClipboard<HTMLDivElement>>
					{({ copy, nodeToCopy }) => (
						<Stack gap="4">
							<Heading as="h4">CSV File Format</Heading>
							<Text maxWidth="4xl">
								When creating your CSV file for travel activity's prices, please
								follow this format only. You can find more information about
								each column by hovering over the words with{" "}
								<b>
									<abbr title="You will get information like this. Please read each information carefully for best and accurate results.">
										dotted underlines
									</abbr>
								</b>
								. If you are unsure about what/why a column is, please feel free
								to contact our support team.
							</Text>
							<Box ref={nodeToCopy}>
								<Table
									aria-label="CSV file format for Travel Activities"
									responsive
									className="excel-style-table"
								>
									<Box as="thead" whiteSpace="preserve">
										<tr>
											<th rowSpan={4}>
												<abbr title="Name of the activity">Name</abbr>
											</th>
											<th rowSpan={4}>
												<abbr title="Service/Ticket Type/Package for the Activity">
													Service
												</abbr>
											</th>
											<th rowSpan={4}>
												<abbr title="Details for the Activity-Service. This will show up in the Itinerary/Daywise-Schedules.">
													Description
												</abbr>
											</th>
											<th rowSpan={4}>
												<abbr title="Activities Open Time (HH:mm)">
													Open Time
												</abbr>
											</th>
											<th rowSpan={4}>
												<abbr title="Activities Close Time (HH:mm)">
													Close Time
												</abbr>
											</th>
											<th rowSpan={4}>
												<abbr title="Durations of the activity in minutes">
													Duration(Mins)
												</abbr>
											</th>
											<th rowSpan={4}>
												<abbr title="Time slot's starting point for the activity for mentioned duration">
													Slots
												</abbr>
											</th>
											<th colSpan={2}>
												Season 1{" "}
												<abbr title="(Optional) Add rates for Bookings">
													(Ops)
												</abbr>
											</th>
											<th colSpan={2}>
												<abbr title="Same season sales rates, used during quotation">
													Season 1
												</abbr>
											</th>
											<th colSpan={2}>
												<abbr title="Not adding any suffix (e.g. (Ops)) will use these rates for sales as well as for Bookings">
													Season 2
												</abbr>
											</th>
										</tr>
										<tr>
											<th colSpan={2}>
												<abbr title="These date range should follow the format of 'D MMM YYYY - D MMM YYYY' only otherwise you may loose some data">
													1 Jul {THIS_YEAR} - 30 Sep {THIS_YEAR}
												</abbr>
											</th>
											<th colSpan={2}>
												1 Jul {THIS_YEAR} - 30 Sep {THIS_YEAR}
											</th>
											<th colSpan={2}>
												13 Oct {THIS_YEAR} - 24 Oct {THIS_YEAR}
											</th>
										</tr>
										<tr>
											<th colSpan={2}>
												24 Dec {THIS_YEAR} - 4 Jan {THIS_YEAR + 1}
											</th>
											<th colSpan={2}>
												24 Dec {THIS_YEAR} - 4 Jan {THIS_YEAR + 1}
											</th>
											<th colSpan={2}>
												5 Jan {THIS_YEAR + 1} - 31 Mar {THIS_YEAR + 1}{" "}
												<abbr title="You can also add week-day rates by specifying the week day names as shown.">
													(Sat,Sun)
												</abbr>
											</th>
										</tr>
										<tr>
											<td>
												<abbr title="The Tourist Type for Tickets">Adult</abbr>
											</td>
											<td>
												<abbr title="Provide age range for the children">
													Child (6-12)
												</abbr>
											</td>
											<td>Adult</td>
											<td>Child (6-12)</td>
											<td>Adult</td>
											<td>Child (6-12)</td>
										</tr>
									</Box>
									<tbody>
										<tr>
											<td rowSpan={2}>Port Blair To Havelock</td>
											<td>Private Catamaran Ferry : Premium</td>
											<td>
												<abbr>Details for this Activity Service</abbr>
											</td>
											<td>
												<abbr title="Opening Time in HH:mm">08:00</abbr>
											</td>
											<td>
												<abbr title="Closing Time in HH:mm">16:00</abbr>
											</td>
											<td>90</td>
											<td>
												<abbr title="Time slots with Start Time in HH:mm, This will help during Trip operations.">
													08:00,10:45
												</abbr>
											</td>
											<td>
												<abbr title="Purchase/Buying/Booking Price per Adult during mentioned Season">
													2200
												</abbr>
											</td>
											<td>
												<abbr title="Purchase/Buying/Booking Price per Child of Age 6-12 (including) during the above season">
													3000
												</abbr>
											</td>
											<td>
												<abbr title="Selling/Quotation Price per Adult during mentioned Season">
													2500
												</abbr>
											</td>
											<td>
												<abbr title="Selling/Quotation Price per Child of Age 6-12 (including) during the above season">
													3500
												</abbr>
											</td>
											<td>3000</td>
											<td>4000</td>
										</tr>
										<tr>
											<td>Private Catamaran Ferry : Deluxe</td>
											<td>
												<abbr title="Leaving empty will set values from previous row with SAME Activity otherwise will be NOT be set">
													&nbsp;&nbsp;
												</abbr>
											</td>
											<td>
												<abbr title="Leave the timing if not applicable">
													-
												</abbr>
											</td>
											<td>
												<abbr title="Closing Days of Week if applicable">
													(Mon)
												</abbr>
											</td>
											<td>90</td>
											<td>
												<abbr title="Leave the slots if not applicable">-</abbr>
											</td>
											<td>3000</td>
											<td>4000</td>
											<td>3500</td>
											<td>4500</td>
											<td>5000</td>
											<td>6000</td>
										</tr>
										<tr>
											<td rowSpan={3}>Havelock To Neil Island</td>
											<td rowSpan={2}>Private Catamaran Ferry</td>
											<td rowSpan={2}>
												<abbr title="This Activity-Service doesn't have a description.">
													&nbsp;&nbsp;
												</abbr>
											</td>
											<td rowSpan={2}>
												<abbr title="Leave the timing if not applicable">
													-
												</abbr>
											</td>
											<td rowSpan={2}>-</td>
											<td>120</td>
											<td>-</td>
											<td>2100</td>
											<td>3200</td>
											<td>2500</td>
											<td>3500</td>
											<td>3000</td>
											<td>4000</td>
										</tr>
										<tr>
											<td>
												<abbr title="Add multiple durations for different prices of tickets">
													60
												</abbr>
											</td>
											<td>-</td>
											<td>2200</td>
											<td>3000</td>
											<td>2500</td>
											<td>3500</td>
											<td>3000</td>
											<td>4000</td>
										</tr>
										<tr>
											<td>
												Private Catamaran Ferry{" "}
												<abbr title="Any special internal code for this service, not to be shared with clients.">
													[[Code-Supplier-Info]]
												</abbr>
											</td>
											<td>
												<abbr title="'-' means Description not applicable for this service.">
													-&nbsp;&nbsp;
												</abbr>
											</td>
											<td>08:00</td>
											<td>
												16:00{" "}
												<abbr title="Closing Days of Week if applicable">
													(Mon,Tue,Wed)
												</abbr>
												<abbr title="Closing Dates / Intervals if applicable">
													(1 Apr {THIS_YEAR} - 10 Apr {THIS_YEAR}, 15 Aug{" "}
													{THIS_YEAR})
												</abbr>
											</td>
											<td>
												<abbr title="Leave the duration if not applicable">
													-
												</abbr>
											</td>
											<td>-</td>
											<td>3500</td>
											<td>4200</td>
											<td>3500</td>
											<td>4500</td>
											<td>5000</td>
											<td>6000</td>
										</tr>
									</tbody>
								</Table>
							</Box>
							<Box>
								<CopyToClipboardButton onClick={() => copy()} level="primary">
									<Icons.ClipboardCopy />
									Copy Template Format
								</CopyToClipboardButton>
							</Box>
						</Stack>
					)}
				</CopyToClipboard>
			</Container>
		</>
	)
}
