import { Button, Inline, Divider, Stack } from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import {
	Form,
	TextInputField,
	withServerErrors,
	validateFormValues,
	SubmissionError,
	TextAreaInputField,
} from "@sembark-travel/ui/form"
import * as Validator from "yup"

const validationSchema = Validator.object().shape({
	name: Validator.string().required("Name field is required"),
	short_name: Validator.string()
		.nullable()
		.max(191, "Please use 191 or fewer chars."),
	description: Validator.string().nullable(),
})
const initialValues = {
	name: "",
	short_name: "",
	description: "",
}

type NewItemCredentials = typeof initialValues

export function NewItemForm({
	onSuccess,
	onCancel,
}: {
	onSuccess: () => void
	onCancel?: () => void
}) {
	const xhr = useXHR()
	return (
		<Form<NewItemCredentials>
			initialValues={initialValues}
			validate={validateFormValues(validationSchema)}
			onSubmit={withServerErrors(async (values) => {
				await xhr.post("/room-types", {
					...values,
					description: values.description || values.name,
				})
				onSuccess()
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Stack gap="4">
						<TextInputField
							label="Name"
							name="name"
							placeholder="e.g. Delux"
							type="text"
							required
						/>
						<TextInputField
							label="Code/Short Name"
							name="short_name"
							type="text"
							secondaryLabel="optional"
							placeholder="e.g. DLX-30D-Coupon"
							help="Any sort code/name for internal usage. This will not be shared with customers."
						/>
						<TextAreaInputField
							label="Description"
							name="description"
							placeholder="Luxury room"
							type="text"
							secondaryLabel="optional"
						/>
						<Divider sm />
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Saving..." : "Save Room Type"}
							</Button>
							{onCancel ? (
								<Button onClick={onCancel} disabled={submitting}>
									Cancel
								</Button>
							) : null}
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}
