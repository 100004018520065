import { Helmet } from "react-helmet-async"
import { useParams } from "../../../../router-utils"
import { TripOperationalBookingsForDate } from "../../../../TripOperationalBookings"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../../Auth"

export default function TripOperationalBookingsForDatePage(
	props: Omit<
		React.ComponentProps<typeof TripOperationalBookingsForDate>,
		"date"
	>
) {
	const { date } = useParams("/operational-bookings/date/:date")
	return (
		<>
			<Helmet>
				<title>Trip Operations for Date</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.VIEW_CAB_SCHEDULES}>
				<TripOperationalBookingsForDate date={date} {...props} />
			</ForbidUnlessAuthorized>
		</>
	)
}
