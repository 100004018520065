import {
	Box,
	Text,
	Button,
	Stack,
	Icons,
	Table,
	Tooltip,
	Container,
	Inline,
	Heading,
	Divider,
	Dropdown,
	Badge,
	Col,
	Component,
	Grid,
	joinAttributes,
	Spinner,
	Stars,
	TabContent,
	TabItem,
	Tabs,
	TabsList,
	Time,
	Money,
	Alert,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { Breadcrumbs, Link } from "@sembark-travel/ui/router"
import {
	dateToQuery,
	dateToUTCString,
	parseDateFromQuery,
	startOf,
	formatDate,
	localOrUtcTimeToLocalDate,
} from "@sembark-travel/datetime-utils"
import {
	isNumeric,
	numberToLocalString,
	withOrdinalSuffix,
} from "@sembark-travel/number-utils"
import {
	NavLink,
	ButtonLink,
	useLocationQuery,
	queryToSearch,
	XHRLink,
} from "@sembark-travel/ui/router"
import {
	Search,
	ListView,
	useSearch,
	TSearchParams,
	areAdvancedFiltersAppliedDefault,
} from "@sembark-travel/ui/list"
import { IListResponse, useXHR, XHRInstance } from "@sembark-travel/xhr"
import pluralize from "pluralize"
import React, { useEffect, useMemo } from "react"
import useSWR from "swr"
import { AccountsList } from "../Accounting"
import config from "../config"
import { ContactsList, PhoneNumber, Email, IContact } from "../Contacts"
import { SelectHotelBookingStages } from "../HotelBookingStages"
import { PaymentsList } from "../Payments"
import { generatePath } from "../router-utils"
import { getDisplayNameForStage, IBooking } from "../HotelBookings"
import { ITrip } from "../Trips"
import { UpdateHotel } from "./Edit"
import { IHotel, useHotel } from "./store"
import { useCheckPermissions, PERMISSIONS } from "../Auth"
import { ImageMediaItem } from "./../Media"
import {
	SelectField,
	DatePickerField,
	SwitchInputField,
	Form,
	withServerErrors,
} from "@sembark-travel/ui/form"

function XHR(xhr: XHRInstance) {
	return {
		async createContact(
			id: string | number,
			contactData: Record<string, unknown>
		): Promise<IContact> {
			return xhr
				.post(`/hotel-contacts`, {
					hotel_id: id,
					...contactData,
				})
				.then((resp) => resp.data.data)
		},
		async getBookings(
			params: unknown
		): Promise<IListResponse<IBooking & { trip: ITrip }>> {
			return xhr.get("/hotel-bookings", { params }).then((resp) => resp.data)
		},
	}
}

export function HotelDetails({
	hotelId,
	children,
}: {
	hotelId: string
	children?: (props: { hotel: IHotel; onChange: () => void }) => React.ReactNode
}) {
	const { hotel, isFetching, fetchHotel: getHotel } = useHotel(hotelId)
	const { hasPermission, hasAnyPermission } = useCheckPermissions()
	const xhr = useXHR()
	if (isFetching && !hotel) return <Spinner alignCenter padding="4" />
	if (!hotel) return <Alert status="error">Hotel does not exist.</Alert>
	const {
		name,
		group,
		stars,
		location,
		address,
		latest_media,
		deleted_at,
		url,
	} = hotel
	return (
		<Box>
			<Breadcrumbs
				title="Hotel Details"
				items={[
					[generatePath("/hotels"), "Hotels"],
					["", hotel.name],
				]}
				actions={
					<Inline gap="4">
						<Component initialState={false}>
							{({ state, setState }) => (
								<>
									<Button onClick={() => setState(true)} size="sm">
										<Icons.Pencil /> Edit
									</Button>
									<Dialog open={state} onClose={() => setState(false)} xl>
										<Dialog.Header>
											<Dialog.Title>Edit Hotel Details</Dialog.Title>
										</Dialog.Header>
										<Dialog.Body>
											<UpdateHotel
												hotel={hotel}
												onSuccess={() => {
													getHotel()
													setState(false)
												}}
												onCancel={() => setState(false)}
											/>
										</Dialog.Body>
									</Dialog>
								</>
							)}
						</Component>
						<Component<"archive" | "restore" | undefined>
							initialState={undefined}
						>
							{({ state, setState }) => (
								<>
									<Dropdown alignRight>
										<Dropdown.ToggleButton size="sm" level="tertiary">
											<Icons.DotsVertical />
										</Dropdown.ToggleButton>
										<Dropdown.Menu>
											{!deleted_at ? (
												<Dropdown.MenuItem onClick={() => setState("archive")}>
													<Icons.Ban /> Disable
												</Dropdown.MenuItem>
											) : (
												<Dropdown.MenuItem onClick={() => setState("restore")}>
													<Icons.Refresh /> Restore
												</Dropdown.MenuItem>
											)}
										</Dropdown.Menu>
									</Dropdown>
									<Dialog
										open={state === "archive"}
										onClose={() => setState(undefined)}
										sm
									>
										<Dialog.Header>
											<Dialog.Title>Disable Hotel</Dialog.Title>
										</Dialog.Header>
										<Form<{ hotel_id: number }>
											initialValues={{ hotel_id: hotel.id }}
											onSubmit={withServerErrors(async (values) => {
												const resp = await xhr.delete<{ message: string }>(
													`/hotels/${values.hotel_id}`
												)
												getHotel()
												showSnackbar(resp.data.message)
												setState(undefined)
											})}
											subscription={{ submitting: true }}
										>
											{({ submitting, handleSubmit }) => (
												<form noValidate onSubmit={handleSubmit}>
													<Dialog.Body>
														<Text>
															This hotel will be removed from all hotels listing
															and selection pages. Existing usage of this hotel
															will not be affected.
														</Text>
														<Divider sm />
														<Text color="warning">
															Are you sure you want to disable this hotel ?
														</Text>
													</Dialog.Body>
													<Dialog.Footer>
														<Button
															type="submit"
															disabled={submitting}
															status="warning"
														>
															{submitting
																? "Disabling..."
																: "I understand, disabled it."}
														</Button>
														<Button
															onClick={() => setState(undefined)}
															disabled={submitting}
														>
															Cancel
														</Button>
													</Dialog.Footer>
												</form>
											)}
										</Form>
									</Dialog>
									<Dialog
										open={state === "restore"}
										onClose={() => setState(undefined)}
										sm
									>
										<Dialog.Header>
											<Dialog.Title>Restore disabled Hotel</Dialog.Title>
										</Dialog.Header>
										<Form<{ hotel_id: number }>
											initialValues={{ hotel_id: hotel.id }}
											onSubmit={withServerErrors(async (values) => {
												const resp = await xhr.patch<{ message: string }>(
													`/hotels/${values.hotel_id}/restore`
												)
												getHotel()
												showSnackbar(resp.data.message)
												setState(undefined)
											})}
											subscription={{ submitting: true }}
										>
											{({ submitting, handleSubmit }) => (
												<form noValidate onSubmit={handleSubmit}>
													<Dialog.Body>
														<Text>
															Are you sure you want to restore this disabled
															hotel ?
														</Text>
													</Dialog.Body>
													<Dialog.Footer>
														<Button type="submit" disabled={submitting}>
															{submitting ? "Restoring..." : "Restore"}
														</Button>
														<Button
															onClick={() => setState(undefined)}
															disabled={submitting}
														>
															Cancel
														</Button>
													</Dialog.Footer>
												</form>
											)}
										</Form>
									</Dialog>
								</>
							)}
						</Component>
					</Inline>
				}
			/>
			<Container paddingY="8" bgColor="default">
				<Inline gap="6" collapseBelow="md" justifyContent="between">
					<Stack gap="4">
						<Stack gap="1">
							<Heading as="h3">{name}</Heading>
							<table>
								<tbody>
									<tr>
										<Box as="td" color="muted" paddingRight="2">
											Location:
										</Box>
										<td>{location.name}</td>
									</tr>
									{stars ? (
										<tr>
											<Box as="td" color="muted" paddingRight="2">
												Category:
											</Box>
											<td>
												<Stars stars={stars} />
											</td>
										</tr>
									) : null}
									{group ? (
										<tr>
											<Box as="td" color="muted" paddingRight="2">
												Hotel Group:
											</Box>
											<td>
												<Link
													color="accent"
													to={generatePath("/hotel-groups/:groupId", {
														groupId: group.id.toString(),
													})}
												>
													{group.name}
												</Link>
											</td>
										</tr>
									) : null}
								</tbody>
							</table>
						</Stack>
						{url ? (
							<Stack>
								<Text fontSize="sm" color="muted" fontWeight="semibold">
									Photos / Details URL
								</Text>
								<Box>
									<Box
										as="a"
										href={url}
										target="_blank"
										rel="noopener noreferrer"
										color="accent"
										maxWidth="sm"
										textOverflow="truncate"
										display="block"
									>
										{url.replace(/https?:\/\/(www\.)?/, "")}
									</Box>
								</Box>
							</Stack>
						) : null}
						{deleted_at ? (
							<Box>
								<Badge warning>Disabled</Badge>
							</Box>
						) : null}
					</Stack>
					<Stack gap="1">
						<Text fontWeight="semibold">Address</Text>
						<Address address={address} />
					</Stack>
					<Stack style={{ maxWidth: "300px" }}>
						<ImageMediaItem
							media={latest_media}
							help="Image should be 750x500 JPEG/PNG file with less than 1 MB in size."
							crop={{
								aspect: 1.5 / 1,
								minHeight: 100,
								minWidth: 150,
							}}
							canRemove
							onSubmit={async (data) => {
								await xhr.patch(`/hotels/${hotel.id}/image`, data)
								await getHotel()
							}}
						/>
					</Stack>
				</Inline>
			</Container>
			<Box borderTopWidth="1">
				<Tabs layout="col">
					<TabsList>
						<NavLink
							to={generatePath("/hotels/:hotelId", {
								hotelId: String(hotel.id),
							})}
							end
						>
							Details
						</NavLink>
						{hasAnyPermission(
							PERMISSIONS.VIEW_HOTEL_BOOKINGS,
							PERMISSIONS.MANAGE_ACCOUNTING
						) ? (
							<NavLink
								to={generatePath("/hotels/:hotelId/bookings", {
									hotelId: String(hotel.id),
								})}
							>
								Bookings
							</NavLink>
						) : null}
						{hasAnyPermission(
							PERMISSIONS.VIEW_HOTEL_BOOKINGS,
							PERMISSIONS.MANAGE_ACCOUNTING
						) ? (
							<NavLink
								to={generatePath("/hotels/:hotelId/payments", {
									hotelId: String(hotel.id),
								})}
							>
								Payments
							</NavLink>
						) : null}
						{hasPermission(PERMISSIONS.MANAGE_ACCOUNTING) ? (
							<NavLink
								to={generatePath("/hotels/:hotelId/accounting", {
									hotelId: String(hotel.id),
								})}
							>
								Accounting
							</NavLink>
						) : null}
					</TabsList>
					<TabContent>
						{children
							? children({
									hotel,
									onChange: getHotel,
								})
							: null}
					</TabContent>
				</Tabs>
			</Box>
		</Box>
	)
}

export function Address({ address }: { address: IHotel["address"] }) {
	const location = address.location
	const city_state_names = [location.city, location.state]
		.map((d) => d?.name)
		.filter((x): x is string => Boolean(x))
	const city_state_line = city_state_names.join(", ")
	let line_1 = address.line_1?.replace(city_state_line, "").trim() || ""
	let line_2 = address.line_2?.replace(city_state_line, "").trim() || ""
	let landmark = address.landmark?.replace(city_state_line, "").trim() || ""
	if (city_state_names.indexOf(line_1) !== -1) {
		line_1 = ""
	}
	if (city_state_names.indexOf(line_2) !== -1) {
		line_2 = ""
	}
	if (city_state_names.indexOf(landmark) !== -1) {
		landmark = ""
	}
	return (
		<Stack as="address">
			{line_1 || line_2 || landmark ? (
				<Text>{[line_1, line_2, landmark].filter(Boolean).join(", ")}</Text>
			) : null}
			{city_state_line ? <Text>{city_state_line}</Text> : location.name}
			<Text>
				{[address.location?.country?.name, address.postcode]
					.filter(Boolean)
					.join(", ")}
			</Text>
			{address.phone_numbers?.length ? (
				<Box>
					<PhoneNumber value={address.phone_numbers} />
				</Box>
			) : null}
			{address.email ? (
				<Box>
					<Email value={address.email} />
				</Box>
			) : null}
		</Stack>
	)
}

export function HotelBasicDetails({
	hotel,
	onChange,
}: {
	hotel: IHotel
	onChange: () => void
}) {
	const {
		extra_bed_child_age_start,
		extra_bed_child_age_end,
		meal_plans,
		room_types,
		payment_preference,
		trip_destinations,
		checkin_at_local,
		checkin_at,
		checkout_at_local,
		checkout_at,
		created_at,
		created_by,
		updated_at,
		updated_by,
		deleted_at,
		deleted_by,
	} = hotel
	const { hasPermission } = useCheckPermissions()

	const xhr = useXHR()
	return (
		<Container paddingY="8" bgColor="default">
			<Grid gap="4">
				<Col>
					<Grid gap="4">
						<Col>
							<Box
								fontSize="sm"
								marginBottom="1"
								fontWeight="semibold"
								color="muted"
							>
								Available Meal Plans
							</Box>
							<Box>
								{meal_plans.map((mealPlan) => mealPlan.name).join(" • ")}
							</Box>
						</Col>
						<Col>
							<Inline gap="4">
								<Stack gap="1">
									<Box fontSize="sm" fontWeight="semibold" color="muted">
										Check-In
									</Box>
									<Box>
										{formatDate(
											localOrUtcTimeToLocalDate(checkin_at_local, checkin_at),
											"HH:mm"
										)}
									</Box>
								</Stack>
								<Stack gap="1">
									<Box fontSize="sm" fontWeight="semibold" color="muted">
										Check-Out
									</Box>
									<Box>
										{formatDate(
											localOrUtcTimeToLocalDate(checkout_at_local, checkout_at),
											"HH:mm"
										)}
									</Box>
								</Stack>
							</Inline>
						</Col>
						<Col xs={12} sm>
							<Box
								fontSize="sm"
								marginBottom="1"
								fontWeight="semibold"
								color="muted"
							>
								Extra bed child ages
							</Box>
							<Box>
								From {extra_bed_child_age_start} to {extra_bed_child_age_end}{" "}
								years
							</Box>
						</Col>
						<Col xs={12}>
							<Box
								fontSize="sm"
								marginBottom="1"
								fontWeight="semibold"
								color="muted"
							>
								Available Room Types
							</Box>
							<Box>
								{joinAttributes(
									...room_types.map((roomType) => (
										<Box as="span">
											{roomType.name}
											<Box fontSize="xs" as="span">
												{" ("}
												{joinAttributes(
													<Box
														as="span"
														title={`${pluralize(
															"extra bed",
															roomType.allowed_extra_beds,
															true
														)} allowed`}
													>
														{roomType.allowed_extra_beds} EBs
													</Box>,
													<Box
														as="span"
														title={`${pluralize(
															"Adult extra bed",
															roomType.allowed_adults_with_extra_bed,
															true
														)} allowed`}
													>
														{roomType.allowed_adults_with_extra_bed} AWEBs
													</Box>,
													<Box
														as="span"
														title={`${pluralize(
															"Child extra bed",
															roomType.allowed_children_with_extra_bed,
															true
														)} allowed`}
													>
														{roomType.allowed_children_with_extra_bed} CWEBs
													</Box>,
													isNumeric(
														roomType.allowed_children_without_extra_bed
													) ? (
														<Box
															as="span"
															title={`${pluralize(
																"Child no-extra bed",
																roomType.allowed_children_without_extra_bed,
																true
															)} allowed`}
														>
															{roomType.allowed_children_without_extra_bed} CNBs
														</Box>
													) : null,
													roomType.no_of_rooms ? (
														<Box as="span">
															{pluralize("Room", roomType.no_of_rooms, true)}
														</Box>
													) : null
												)}
												)
											</Box>
										</Box>
									))
								)}
							</Box>
						</Col>
					</Grid>
				</Col>
				<Col xs={12} md={4} lg={3}>
					<Stack gap="4">
						<Stack gap="1">
							<Box fontSize="sm" fontWeight="semibold" color="muted">
								Payment Preference
							</Box>
							<Box>
								{payment_preference ? (
									payment_preference.name
								) : (
									<Box color="muted">Not Set</Box>
								)}
							</Box>
						</Stack>
						<Stack gap="1">
							<Box fontSize="sm" fontWeight="semibold" color="muted">
								Trip Destinations
							</Box>
							<Box>
								{trip_destinations?.length ? (
									trip_destinations.map((t) => t.name).join(", ")
								) : (
									<Box color="muted">All</Box>
								)}
							</Box>
						</Stack>
					</Stack>
				</Col>
			</Grid>
			<Divider sm />
			{joinAttributes(
				<Text as="span">
					Created on <Time timestamp={created_at} />
					{created_by ? ` by ${created_by.name}` : null}
				</Text>,
				deleted_at ? (
					<Text as="span" color="danger">
						Disabled on <Time timestamp={deleted_at} />
						{deleted_by ? ` by ${deleted_by.name}` : null}
					</Text>
				) : null,
				updated_at && updated_by ? (
					<Text as="span">
						Updated on <Time timestamp={updated_at} /> by {updated_by.name}
					</Text>
				) : null
			)}
			<Divider sm />
			<Inline gap="8" collapseBelow="md">
				{hasPermission(PERMISSIONS.MANAGE_ACCOUNTING) ? (
					<Stack width={{ sm: "full", md: "1/2" }} gap="4">
						<Heading as="h4" fontSize="md">
							<Icons.BankNotes color="muted" opacity="50" size="6" /> Accounting
						</Heading>
						{hotel.accounting_accounts?.length ? (
							<Box>
								{hotel.accounting_accounts.map((a) => (
									<Box key={a.id}>
										<Box
											fontWeight="semibold"
											textTransform="uppercase"
											letterSpacing="wider"
											color="muted"
											marginBottom="1"
											fontSize="sm"
										>
											{a.name}
										</Box>
										{a.assets?.length ? (
											<Box>
												{a.assets.map((asset) => (
													<Box key={asset.type}>
														<Box
															as="span"
															fontSize="xl"
															fontWeight="semibold"
															color={asset.amount > 0 ? "success" : "danger"}
														>
															{asset.amount}
														</Box>
														<Box as="span" fontSize="sm" marginLeft="1">
															{asset.type}
														</Box>
													</Box>
												))}
											</Box>
										) : (
											<Box>
												<Box color="muted">n/a</Box>
											</Box>
										)}
									</Box>
								))}
							</Box>
						) : (
							<Stack gap="2">
								<Text fontSize="md">
									No accounting activities for this hotel
								</Text>
								<Text fontSize="sm" color="muted">
									Log payments regarding bookings or services for this hotel to
									manage the accounting.
								</Text>
								<Box>
									<ButtonLink
										to={generatePath("/hotels/:hotelId/payments", {
											hotelId: String(hotel.id),
										})}
										size="sm"
									>
										View Due Payments <Icons.ChevronDown rotate="270" />
									</ButtonLink>
								</Box>
							</Stack>
						)}
					</Stack>
				) : null}
				<Box flex="1">
					<ContactsList
						contacts={hotel.contacts}
						phoneNumberRequired
						onCreate={async (data) => {
							const resp = await XHR(xhr).createContact(hotel.id, data)
							onChange()
							return resp
						}}
						onChange={() => onChange()}
					/>
				</Box>
			</Inline>
		</Container>
	)
}

type TFilters = TSearchParams & {
	booking_method?: string
	has_due_payments?: boolean
	booking_stages?: Array<{ id: string; name: string }>
	stay_after?: Date
	stay_before?: Date
}

type TFiltersInQuery = TSearchParams & {
	booking_method?: string
	hdp?: 1
	bs?: Array<string>
	stay_after?: string
	stay_before?: string
}

function filtersToLocationQuery(filters: TFilters): TFiltersInQuery {
	const {
		booking_method,
		q,
		page,
		has_due_payments,
		booking_stages,
		stay_after,
		stay_before,
	} = filters
	const query: TFiltersInQuery = { q, page }
	if (booking_method) {
		query.booking_method = booking_method
	}
	if (q) query.q = q
	if (page) query.page = page
	if (has_due_payments) query.hdp = 1
	if (booking_stages?.length) {
		query.bs = booking_stages.map((b) => `${b.id}#${b.name}`)
	}
	if (stay_after) {
		query.stay_after = dateToQuery(stay_after)
	}
	if (stay_before) {
		query.stay_before = dateToQuery(stay_before)
	}
	return query
}

function locationQueryToFilters(query: TFiltersInQuery): TFilters {
	const { booking_method, q, page, hdp, bs, stay_after, stay_before } = query
	const filters: TFilters = { q }
	if (booking_method) {
		filters.booking_method = booking_method
	}
	if (page) filters.page = page
	if (hdp) filters.has_due_payments = true
	if (bs?.length) {
		filters.booking_stages = bs.map((b) => {
			const [id, ...name] = b.split("#")
			return {
				id,
				name: name.join("#"),
			}
		})
	}
	if (stay_after) {
		filters.stay_after = parseDateFromQuery(stay_after)
	}
	if (stay_before) {
		filters.stay_before = parseDateFromQuery(stay_before)
	}
	return filters
}

function filtersToRequestParams(filters: TFilters) {
	const {
		booking_method,
		q,
		page,
		has_due_payments,
		booking_stages,
		stay_after,
		stay_before,
	} = filters
	return {
		page: page || 1,
		q,
		booking_method: booking_method || "all",
		has_due_payments: has_due_payments ? 1 : null,
		booking_stages: booking_stages?.map((b) => b.id),
		stay_after: stay_after ? dateToUTCString(startOf(stay_after, "day")) : null,
		stay_before: stay_before
			? dateToUTCString(startOf(stay_before, "day"))
			: null,
	}
}

export function HotelBookings({
	params: propParams,
}: {
	params?: { hotels?: Array<number>; hotel_groups?: Array<number> }
}) {
	const xhr = useXHR()
	const { data: bookingMethods } = useSWR<Array<{ id: string; name: string }>>(
		`/api/hotel-booking-methods${queryToSearch(propParams)}`,
		() =>
			xhr
				.get("/hotel-booking-methods", {
					params: {
						limit: 100,
						hotels: propParams?.hotels,
						hotel_groups: propParams?.hotel_groups,
					},
				})
				.then((resp) => resp.data.data.concat([{ id: "all", name: "All" }])),
		{ revalidateOnFocus: false, refreshInterval: 300000 }
	)
	const [query, setQuery] = useLocationQuery({
		toQuery: filtersToLocationQuery,
		fromQuery: locationQueryToFilters,
	})
	const [params, setParams] = useSearch<TFilters>({
		...query,
		booking_method: query.booking_method || "all",
	})
	const showProperty = propParams?.hotels?.length !== 1
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	return (
		<Box paddingTop="8">
			<Search
				placeholder="Search by Guest, Services etc..."
				Filters={Filters}
				resetParams={(params) => ({
					q: "",
					booking_method: params.booking_method || "all",
					page: 1,
				})}
				initialParams={params}
				onSearch={(newParams) => {
					setParams({ ...newParams, page: 1 })
				}}
				title="Bookings"
				areAdvancedFiltersApplied={(params) => {
					const { booking_method, has_due_payments, ...otherParams } = params
					return (
						has_due_payments || areAdvancedFiltersAppliedDefault(otherParams)
					)
				}}
				actions={
					<>
						<Button
							as={XHRLink}
							href="/hotel-bookings/export"
							title="Download Report as Excel/CSV File"
							query={{
								...filtersToRequestParams(params),
								...propParams,
								timezone_offset: config.timezoneOffset,
							}}
							download
						>
							<Icons.DocumentDownload /> Excel/CSV
						</Button>
					</>
				}
			>
				{({ searchParams, setSearchParams }) => (
					<Tabs>
						<TabsList>
							{bookingMethods?.map((method) => {
								return (
									<TabItem
										key={method.id}
										active={searchParams.booking_method === method.id}
										onClick={() =>
											setSearchParams({
												...searchParams,
												booking_method: method.id,
											})
										}
									>
										{method.name}
									</TabItem>
								)
							})}
						</TabsList>
						<TabContent>
							<ListView<IBooking & { trip: ITrip }, TFilters>
								pageKey={`hotel-bookings/${queryToSearch(propParams)}`}
								fetch={(xhr, filters) =>
									XHR(xhr).getBookings({
										...filtersToRequestParams(filters),
										...propParams,
										limit: 50,
									})
								}
								onPageChange={(page) => setParams({ ...params, page })}
								params={params}
							>
								{({ items }) => (
									<Table
										bordered
										hover
										responsive
										headers={["Trip Id" as React.ReactNode]
											.concat(
												showProperty ? ["Property" as React.ReactNode] : []
											)
											.concat([
												"Guest",
												"Checkin",
												"Rooms/Services",
												"Meal",
												"Stage",
												<Tooltip content="Booking Confirmation Number">
													BCN
												</Tooltip>,
												"Amount",
											])}
										alignCols={{ [showProperty ? 8 : 7]: "right" }}
										rows={items.map(
											({
												id,
												checkin,
												checkin_local,
												no_of_nights,
												no_of_children,
												no_of_adults,
												pax_string,
												trip,
												booked,
												booking_stage,
												currency,
												booked_price,
												booking_confirmation_id,
												booking_method,
												room_nights,
												payments_amount,
												cancellation_charges,
												paid_payments_amount,
												refunding_payments_amount,
												paid_refunding_payments_amount,
												extras,
												details,
												hotel,
											}) =>
												[trip.id as React.ReactNode]
													.concat(
														showProperty
															? [
																	(
																		<Box>
																			<Box>
																				<Link
																					to={generatePath(
																						"/hotels/:hotelId/bookings",
																						{
																							hotelId: hotel.id.toString(),
																						}
																					)}
																					color="accent"
																				>
																					<Box
																						title={hotel.name}
																						style={{ maxWidth: "150px" }}
																						textOverflow="truncate"
																						fontWeight="semibold"
																					>
																						{hotel.name}
																					</Box>
																				</Link>
																				<Box
																					display="flex"
																					alignItems="center"
																					fontSize="sm"
																					color="muted"
																				>
																					{joinAttributes(
																						<Box
																							display="inlineBlock"
																							style={{ maxWidth: "150px" }}
																							textOverflow="truncate"
																						>
																							{hotel?.address.location.name}
																						</Box>,
																						hotel?.stars ? (
																							<Stars stars={hotel?.stars} />
																						) : null
																					)}
																				</Box>
																			</Box>
																		</Box>
																	) as React.ReactNode,
																]
															: []
													)
													.concat([
														<Box
															display={showProperty ? "block" : "flex"}
															flexWrap="nowrap"
															alignItems="center"
															justifyContent="between"
														>
															<Box marginRight="2">
																<Link
																	to={generatePath("/trips/:tripId", {
																		tripId: trip.id.toString(),
																	})}
																	fontWeight="semibold"
																	color="accent"
																>
																	{trip.tourist?.name || (
																		<>
																			<Icons.AttentionSolid color="warning" />{" "}
																			N/A
																		</>
																	)}
																</Link>
															</Box>
															<Tooltip content={pax_string}>
																<Box
																	whiteSpace="preserve"
																	color="muted"
																	fontSize="sm"
																>
																	{no_of_adults}A
																	{no_of_children
																		? `, ${no_of_children}C`
																		: null}
																</Box>
															</Tooltip>
														</Box>,
														<Box>
															<Time
																timestamp={checkin}
																localTimestamp={checkin_local}
																format="D MMM"
															/>{" "}
															- {no_of_nights}N
														</Box>,
														<Box>
															{(room_nights || []).map(function (
																{ room_type, no_of_rooms, nights },
																index
															) {
																return (
																	<Tooltip
																		key={index}
																		content={
																			nights
																				.map((n) => withOrdinalSuffix(n))
																				.join(", ") +
																			" " +
																			pluralize("Night", nights.length)
																		}
																	>
																		<Box key={room_type.id} display="inline">
																			{no_of_rooms} {room_type.name}
																		</Box>
																	</Tooltip>
																)
															})}
															{extras?.length ? (
																<Box fontSize="sm">
																	{extras.map((extra) => (
																		<Box key={extra.id} maxWidth="xs">
																			{extra.service.name}
																		</Box>
																	))}
																</Box>
															) : null}
														</Box>,
														<Box>
															{details
																?.reduce<
																	Array<string>
																>((plans, d) => (plans.indexOf(d.meal_plan.name) === -1 ? plans.concat([d.meal_plan.name]) : plans), [])
																.join(", ")}
														</Box>,
														<Box>
															{getDisplayNameForStage(booking_stage.state)}
															{booked && booking_method ? (
																<Box
																	display="inline"
																	marginLeft="1"
																	fontSize="xs"
																	color="muted"
																	fontWeight="semibold"
																>
																	{booking_method}
																</Box>
															) : null}
														</Box>,
														booking_confirmation_id,
														<Box>
															<Box display="inlineFlex" alignItems="center">
																<Link
																	to={
																		generatePath(
																			"/trips/:tripId/accounting/payments",
																			{
																				tripId: trip.id.toString(),
																			}
																		) + `#hb_${id}`
																	}
																>
																	{booking_stage.is_same_or_after_booked ? (
																		<Money
																			amount={cancellation_charges || 0}
																			currency={currency}
																		/>
																	) : isNumeric(booked_price) ? (
																		<Money
																			amount={booked_price}
																			currency={currency}
																		/>
																	) : null}
																</Link>
																{isNumeric(payments_amount) &&
																paid_payments_amount === payments_amount ? (
																	<Box display="inlineBlock" marginLeft="1">
																		<Icons.OkCircleSolid
																			color="success"
																			title={`${numberToLocalString(
																				paid_payments_amount
																			)} Paid`}
																		/>
																	</Box>
																) : null}
															</Box>
															{isNumeric(payments_amount) &&
															isNumeric(paid_payments_amount) ? (
																<Box
																	fontSize="sm"
																	color="success"
																	title={`Partial: ${numberToLocalString(
																		paid_payments_amount
																	)} Paid`}
																>
																	<Icons.Ok />
																	<Box display="inlineBlock" marginLeft="px">
																		<Money
																			amount={paid_payments_amount}
																			currency={currency}
																		/>
																	</Box>
																</Box>
															) : null}
															{isNumeric(refunding_payments_amount) &&
															refunding_payments_amount > 0 ? (
																refunding_payments_amount ===
																paid_refunding_payments_amount ? (
																	<Box
																		fontSize="sm"
																		color="success"
																		display="flex"
																		title="Full Refunded"
																	>
																		<Icons.Refresh />
																		<Box display="inlineBlock" marginLeft="px">
																			<Money
																				amount={paid_refunding_payments_amount}
																				currency={currency}
																			/>
																		</Box>
																	</Box>
																) : (
																	<Box
																		fontSize="sm"
																		color="warning"
																		display="flex"
																		title="Refund Pending"
																	>
																		<Icons.Refresh />
																		<Box display="inlineBlock" marginLeft="px">
																			<Money
																				amount={
																					paid_refunding_payments_amount || 0
																				}
																				currency={currency}
																			/>
																		</Box>
																		/
																		<Money
																			amount={refunding_payments_amount}
																			currency={currency}
																		/>
																	</Box>
																)
															) : null}
														</Box>,
													])
										)}
									/>
								)}
							</ListView>
						</TabContent>
					</Tabs>
				)}
			</Search>
		</Box>
	)
}

function Filters() {
	return (
		<Stack gap="4">
			<Stack gap="1">
				<label htmlFor="stay_after">Stay During</label>
				<Box display="flex" alignItems="center" marginTop="1">
					<DatePickerField name="stay_after" />
					<Box>
						<Icons.SwitchHorizontal />
					</Box>
					<DatePickerField name="stay_before" rightAlign />
				</Box>
			</Stack>
			<SwitchInputField label="Has Due Payments" name="has_due_payments" />
			<SelectField
				select={SelectHotelBookingStages}
				name="booking_stages"
				label="Booking Stages"
				multiple
			/>
		</Stack>
	)
}

export function HotelPayments({ hotel }: { hotel: IHotel }) {
	const { id } = hotel
	const hotels = useMemo(() => {
		return [id]
	}, [id])
	return (
		<Box paddingTop="8">
			<PaymentsList hotels={hotels} debit hideContact />
		</Box>
	)
}

export function HotelAccountings({ hotel }: { hotel: IHotel }) {
	return (
		<Container paddingY="8">
			{hotel.accounting_accounts?.length ? (
				<Box>
					<Box textAlign="right" marginBottom="2">
						<ButtonLink
							to={generatePath("/hotels/:hotelId/payments", {
								hotelId: String(hotel.id),
							})}
							size="sm"
						>
							View Due Payments <Icons.ChevronDown rotate="270" />
						</ButtonLink>
					</Box>
					<AccountsList accounts={hotel.accounting_accounts} />
				</Box>
			) : (
				<Stack gap="2">
					<Text fontSize="md" fontWeight="semibold">
						No accounting activities for this hotel
					</Text>
					<Text fontSize="sm" color="muted">
						Log payments regarding bookings or services for this hotel to manage
						the accounting.
					</Text>
					<Box>
						<ButtonLink to="../payments" size="sm">
							View Due Payments <Icons.ChevronDown rotate="270" />
						</ButtonLink>
					</Box>
				</Stack>
			)}
		</Container>
	)
}
